import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-general-error-page',
  templateUrl: './general-error-page.component.html',
})
export class GeneralErrorPageComponent implements OnInit {

  message = 'Oeps! Er heeft zich een fout voorgedaan.';

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
        if (params['msg']) {
          this.message = params['msg'];
        }
      });
  }

}
