<div class="modal-header">
    <h5 class="modal-title pull-left">{{title}}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
      <edu-icon-delete-fat type="filled"></edu-icon-delete-fat>
    </button>
  </div>
  <div class="modal-body">
    <div class="d-flex flex-column">
        <div class="mb-2">ui version : v{{version}}</div>
        <div class="mb-2">query server : v{{query_server_version}}</div>
        <div class="mb-2">cmd server : v{{cmd_server_version}}</div>
        <div class="mb-2">img server : v{{img_server_version}}</div>
        <div class="ml-2" *ngIf="user">
            <div>id:</div>
            <div class='ml-3'>{{user.id}}</div>
            <div>naam:</div>
            <div class='ml-3'>{{user.first_name}}&nbsp;{{user.last_name}}</div>
            <div class="mt-2">publicaties: </div>
            <div>
                <div class='ml-3' *ngFor="let publication of user.publications;">{{publication.id}}:{{publication.slug}}</div>
            </div>
            <div class="mt-2">rollen:</div>
            <div>
                <div class='ml-3' *ngFor="let role of user.roles;">{{role}}</div>
            </div>
            <div *ngIf="user.isTeacher">
              <div>used quota:</div>
              <div class='ml-3'>{{(quota / 1024).toFixed(2)}}&nbsp;MB&nbsp;/&nbsp;512MB</div>
              <div>available :</div>
              <div class='ml-3'>{{(512 - (quota / 1024)).toFixed(2)}}&nbsp;MB</div>
            </div>
        </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex flex-row justify-content-end">
      <button type="button" class="btn btn-danger ml-2" (click)="onClose()">annuleren</button>
    </div>
  </div>
