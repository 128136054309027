export class KeyCodes {
  static readonly KEY_CANCEL: number = 3;
  static readonly KEY_HELP: number = 6;
  static readonly KEY_BACK_SPACE: number = 8;
  static readonly KEY_TAB: number = 9;
  static readonly KEY_CLEAR: number = 12;
  static readonly KEY_RETURN: number = 13;
  static readonly KEY_ENTER: number = 14;
  static readonly KEY_SHIFT: number = 16;
  static readonly KEY_CONTROL: number = 17;
  static readonly KEY_ALT: number = 18;
  static readonly KEY_PAUSE: number = 19;
  static readonly KEY_CAPS_LOCK: number = 20;
  static readonly KEY_ESCAPE: number = 27;
  static readonly KEY_SPACE: number = 32;
  static readonly KEY_PAGE_UP: number = 33;
  static readonly KEY_PAGE_DOWN: number = 34;
  static readonly KEY_END: number = 35;
  static readonly KEY_HOME: number = 36;
  static readonly KEY_LEFT: number = 37;
  static readonly KEY_UP: number = 38;
  static readonly KEY_RIGHT: number = 39;
  static readonly KEY_DOWN: number = 40;
  static readonly KEY_PRINTSCREEN: number = 44;
  static readonly KEY_INSERT: number = 45;
  static readonly KEY_DELETE: number = 46;
  static readonly KEY_0: number = 48;
  static readonly KEY_1: number = 49;
  static readonly KEY_2: number = 50;
  static readonly KEY_3: number = 51;
  static readonly KEY_4: number = 52;
  static readonly KEY_5: number = 53;
  static readonly KEY_6: number = 54;
  static readonly KEY_7: number = 55;
  static readonly KEY_8: number = 56;
  static readonly KEY_9: number = 57;
  static readonly KEY_SEMICOLON: number = 59;
  static readonly KEY_EQUALS: number = 61;
  static readonly KEY_A: number = 65;
  static readonly KEY_B: number = 66;
  static readonly KEY_C: number = 67;
  static readonly KEY_D: number = 68;
  static readonly KEY_E: number = 69;
  static readonly KEY_F: number = 70;
  static readonly KEY_G: number = 71;
  static readonly KEY_H: number = 72;
  static readonly KEY_I: number = 73;
  static readonly KEY_J: number = 74;
  static readonly KEY_K: number = 75;
  static readonly KEY_L: number = 76;
  static readonly KEY_M: number = 77;
  static readonly KEY_N: number = 78;
  static readonly KEY_O: number = 79;
  static readonly KEY_P: number = 80;
  static readonly KEY_Q: number = 81;
  static readonly KEY_R: number = 82;
  static readonly KEY_S: number = 83;
  static readonly KEY_T: number = 84;
  static readonly KEY_U: number = 85;
  static readonly KEY_V: number = 86;
  static readonly KEY_W: number = 87;
  static readonly KEY_X: number = 88;
  static readonly KEY_Y: number = 89;
  static readonly KEY_Z: number = 90;
  static readonly KEY_COMMAND: number = 91;
  static readonly KEY_CONTEXT_MENU: number = 93;
  static readonly KEY_NUMPAD0: number = 96;
  static readonly KEY_NUMPAD1: number = 97;
  static readonly KEY_NUMPAD2: number = 98;
  static readonly KEY_NUMPAD3: number = 99;
  static readonly KEY_NUMPAD4: number = 100;
  static readonly KEY_NUMPAD5: number = 101;
  static readonly KEY_NUMPAD6: number = 102;
  static readonly KEY_NUMPAD7: number = 103;
  static readonly KEY_NUMPAD8: number = 104;
  static readonly KEY_NUMPAD9: number = 105;
  static readonly KEY_MULTIPLY: number = 106;
  static readonly KEY_ADD: number = 107;
  static readonly KEY_SEPARATOR: number = 108;
  static readonly KEY_SUBTRACT: number = 109;
  static readonly KEY_DECIMAL: number = 110;
  static readonly KEY_DIVIDE: number = 111;
  static readonly KEY_F1: number = 112;
  static readonly KEY_F2: number = 113;
  static readonly KEY_F3: number = 114;
  static readonly KEY_F4: number = 115;
  static readonly KEY_F5: number = 116;
  static readonly KEY_F6: number = 117;
  static readonly KEY_F7: number = 118;
  static readonly KEY_F8: number = 119;
  static readonly KEY_F9: number = 120;
  static readonly KEY_F10: number = 121;
  static readonly KEY_F11: number = 122;
  static readonly KEY_F12: number = 123;
  static readonly KEY_F13: number = 124;
  static readonly KEY_F14: number = 125;
  static readonly KEY_F15: number = 126;
  static readonly KEY_F16: number = 127;
  static readonly KEY_F17: number = 128;
  static readonly KEY_F18: number = 129;
  static readonly KEY_F19: number = 130;
  static readonly KEY_F20: number = 131;
  static readonly KEY_F21: number = 132;
  static readonly KEY_F22: number = 133;
  static readonly KEY_F23: number = 134;
  static readonly KEY_F24: number = 135;
  static readonly KEY_NUM_LOCK: number = 144;
  static readonly KEY_SCROLL_LOCK: number = 145;
  static readonly KEY_COMMA: number = 188;
  static readonly KEY_PERIOD: number = 190;
  static readonly KEY_SLASH: number = 191;
  static readonly KEY_BACK_QUOTE: number = 192;
  static readonly KEY_OPEN_BRACKET: number = 219;
  static readonly KEY_BACK_SLASH: number = 220;
  static readonly KEY_CLOSE_BRACKET: number = 221;
  static readonly KEY_QUOTE: number = 222;
  static readonly KEY_META: number = 22;

  static readonly CODE_3: string = 'cancel';
  static readonly CODE_6: string = 'help';
  static readonly CODE_8: string = 'back_space';
  static readonly CODE_9: string = 'tab';
  static readonly CODE_12: string = 'clear';
  static readonly CODE_13: string = 'return';
  static readonly CODE_14: string = 'enter';
  static readonly CODE_16: string = 'shift';
  static readonly CODE_17: string = 'control';
  static readonly CODE_18: string = 'alt';
  static readonly CODE_19: string = 'pause';
  static readonly CODE_20: string = 'caps_lock';
  static readonly CODE_27: string = 'escape';
  static readonly CODE_32: string = 'space';
  static readonly CODE_33: string = 'page_up';
  static readonly CODE_34: string = 'page_down';
  static readonly CODE_35: string = 'end';
  static readonly CODE_36: string = 'home';
  static readonly CODE_37: string = 'left';
  static readonly CODE_38: string = 'up';
  static readonly CODE_39: string = 'right';
  static readonly CODE_40: string = 'down';
  static readonly CODE_44: string = 'printscreen';
  static readonly CODE_45: string = 'insert';
  static readonly CODE_46: string = 'delete';
  static readonly CODE_48: string = '0';
  static readonly CODE_49: string = '1';
  static readonly CODE_50: string = '2';
  static readonly CODE_51: string = '3';
  static readonly CODE_52: string = '4';
  static readonly CODE_53: string = '5';
  static readonly CODE_54: string = '6';
  static readonly CODE_55: string = '7';
  static readonly CODE_56: string = '8';
  static readonly CODE_57: string = '9';
  static readonly CODE_59: string = 'semicolon';
  static readonly CODE_61: string = 'equals';
  static readonly CODE_65: string = 'a';
  static readonly CODE_66: string = 'b';
  static readonly CODE_67: string = 'c';
  static readonly CODE_68: string = 'd';
  static readonly CODE_69: string = 'e';
  static readonly CODE_70: string = 'f';
  static readonly CODE_71: string = 'g';
  static readonly CODE_72: string = 'h';
  static readonly CODE_73: string = 'i';
  static readonly CODE_74: string = 'j';
  static readonly CODE_75: string = 'k';
  static readonly CODE_76: string = 'l';
  static readonly CODE_77: string = 'm';
  static readonly CODE_78: string = 'n';
  static readonly CODE_79: string = 'o';
  static readonly CODE_80: string = 'p';
  static readonly CODE_81: string = 'q';
  static readonly CODE_82: string = 'r';
  static readonly CODE_83: string = 's';
  static readonly CODE_84: string = 't';
  static readonly CODE_85: string = 'u';
  static readonly CODE_86: string = 'v';
  static readonly CODE_87: string = 'w';
  static readonly CODE_88: string = 'x';
  static readonly CODE_89: string = 'y';
  static readonly CODE_90: string = 'z';
  static readonly CODE_93: string = 'context_menu';
  static readonly CODE_96: string = 'numpad0';
  static readonly CODE_97: string = 'numpad1';
  static readonly CODE_98: string = 'numpad2';
  static readonly CODE_99: string = 'numpad3';
  static readonly CODE_100: string = 'numpad4';
  static readonly CODE_101: string = 'numpad5';
  static readonly CODE_102: string = 'numpad6';
  static readonly CODE_103: string = 'numpad7';
  static readonly CODE_104: string = 'numpad8';
  static readonly CODE_105: string = 'numpad9';
  static readonly CODE_106: string = 'multiply';
  static readonly CODE_107: string = 'add';
  static readonly CODE_108: string = 'separator';
  static readonly CODE_109: string = 'subtract';
  static readonly CODE_110: string = 'decimal';
  static readonly CODE_111: string = 'divide';
  static readonly CODE_112: string = 'f1';
  static readonly CODE_113: string = 'f2';
  static readonly CODE_114: string = 'f3';
  static readonly CODE_115: string = 'f4';
  static readonly CODE_116: string = 'f5';
  static readonly CODE_117: string = 'f6';
  static readonly CODE_118: string = 'f7';
  static readonly CODE_119: string = 'f8';
  static readonly CODE_120: string = 'f9';
  static readonly CODE_121: string = 'f10';
  static readonly CODE_122: string = 'f11';
  static readonly CODE_123: string = 'f12';
  static readonly CODE_124: string = 'f13';
  static readonly CODE_125: string = 'f14';
  static readonly CODE_126: string = 'f15';
  static readonly CODE_127: string = 'f16';
  static readonly CODE_128: string = 'f17';
  static readonly CODE_129: string = 'f18';
  static readonly CODE_130: string = 'f19';
  static readonly CODE_131: string = 'f20';
  static readonly CODE_132: string = 'f21';
  static readonly CODE_133: string = 'f22';
  static readonly CODE_134: string = 'f23';
  static readonly CODE_135: string = 'f24';
  static readonly CODE_144: string = 'num_lock';
  static readonly CODE_145: string = 'scroll_lock';
  static readonly CODE_188: string = 'comma';
  static readonly CODE_190: string = 'period';
  static readonly CODE_191: string = 'slash';
  static readonly CODE_192: string = 'back_quote';
  static readonly CODE_219: string = 'open_bracket';
  static readonly CODE_220: string = 'back_slash';
  static readonly CODE_221: string = 'close_bracket';
  static readonly CODE_222: string = 'quote';
  static readonly CODE_22: string = 'meta';
}
