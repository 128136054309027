<div style="height: 30px;">
    <span *ngFor="let part of parts; let i = index">
          <a *ngIf="i !== parts.length - 1"
             [routerLink]="baseUrl"
             [queryParams]="routeQueryParams[i]">&nbsp;/&nbsp;{{part}}</a>
          <span *ngIf="i === parts.length - 1" class="currentBreadCrumb">
            &nbsp;/&nbsp;{{part }}&nbsp;/&nbsp;</span>
    </span>
    <span>
      <select #dropdown [(ngModel)]="selectedSubFolder" (ngModelChange)="onSubFolderChange($event)">
        <option *ngFor="let f of subFolders" [ngValue]="f">{{f?.isTeachersRootFolder ? MY_FILES : f.name}}</option>
      </select>
    </span>
</div>

