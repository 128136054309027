
const PARAMS = location.origin.includes("e-ducate.me") ? {
  "API":"https://redwood.e-ducate.me/api",
  "ASSUME_ROLE_LAMBDA":"https://g3py3imj52hg5zqjntef6biobm0njwvc.lambda-url.eu-central-1.on.aws",
  "ENVIRONMENT":"prod",
  "MAPLE":"https://maple.e-ducate.me/",
  "MAPLE_SERVER":"https://maple-server.e-ducate.me/",
  "PRUNUS-IMG-SERVER": "https://prunus-img-server.e-ducate.me",
  "PRUNUS-QUERY-SERVER": "https://prunus-query-server.e-ducate.me",
  "PRUNUS-CMD-SERVER": "https://prunus-cmd-server.e-ducate.me",
  "PRUNUS_UI":"https://prunus.e-ducate.me",
  "Q":"https://sqs.eu-central-1.amazonaws.com/443296531642/prunus-commands.fifo",
  "REDWOOD":"https://redwood.e-ducate.me",
  "REDWOOD-LAMBDA":"https://redwood-lambda.e-ducate.me",
  "REGION":"eu-central-1",
  "ROOT_FOLDER":"prunus",
  "S3_UPLOAD_BUCKET":"prunus-orig-master",
  "SEND_CMD_BATCHES":false,
  "SEND_CMD_OVER":"HTTP",
  "SHOW_TECHINFO_IN_UI":false,
  "WEBSOCKET":"wss://tkzau7r4jf.execute-api.eu-central-1.amazonaws.com/production",
  "WEBSOCKET_CHANNEL":"PRUNUS-CHANNEL"
} : {
  "ENVIRONMENT": "test",
  "API": "https://redwood.sharklazers.be/api",
  "REDWOOD": "https://redwood.sharklazers.be",
  "REDWOOD-LAMBDA": "https://redwood-lambda.sharklazers.be",
  "MAPLE": "https://maple.sharklazers.be/",
  "MAPLE_SERVER": "https://maple-server.sharklazers.be/",
  "PRUNUS-IMG-SERVER": "https://prunus-img-server.sharklazers.be",
  "PRUNUS-QUERY-SERVER": "https://prunus-query-server.sharklazers.be",
  "PRUNUS-CMD-SERVER": "https://prunus-cmd-server.sharklazers.be",
  "PRUNUS_UI": "http://localhost:4444",
  "SHOW_TECHINFO_IN_UI": true,
  "S3_UPLOAD_BUCKET": "prunus-test-orig-master",
  "ASSUME_ROLE_LAMBDA": "https://yreqfci2zthgpiuwps3anavp3i0dpryx.lambda-url.eu-central-1.on.aws",
  "REGION": "eu-central-1",
  "Q": "https://sqs.eu-central-1.amazonaws.com/443296531642/prunus-test-commands.fifo",
  "WEBSOCKET": "wss://tkzau7r4jf.execute-api.eu-central-1.amazonaws.com/test",
  "ROOT_FOLDER": "prunus",
  "SEND_CMD_OVER": "HTTP",
  "WEBSOCKET_CHANNEL": "PRUNUS-CHANNEL-TEST",
  "SEND_CMD_BATCHES": false,
  "POOLS": [
    {
        USER_POOL_ID: "eu-central-1_yYfllEYZf", 
        CLIENT_ID: "22sos7eqpojmjs08tajhctrokm",
        NAME: "e-ducate-me" 
    },
    {
        USER_POOL_ID: 'eu-central-1_McDOdq3of',
        CLIENT_ID: '4dh0upt2o3847a0cfpemijtgno',
        NAME: "leerid"
    }
  ],
  "SSO_LAMBDA": "https://jvggea5lvnmczay44ne4djd6oy0knoow.lambda-url.eu-central-1.on.aws"
}

export { PARAMS };
