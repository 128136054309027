<div class="">
  <label (fileOver)="fileOverBase($event)"
         (folder)="onNewFolder($event)"
         *ngIf="canUpload"
         [allowedExtensions]="allowedResourcesExtensions"
         [ngClass]="{'dropZoneHighlight': hasBaseDropZoneOver}"
         appFileDirDrop
         class="dropZone thumb"
  >
    <span class="upload-btn-wrapper">
        <edu-icon-cloud-upload-alt></edu-icon-cloud-upload-alt>
        <input [accept]="accept" ng2FileSelect type="file"  mozdirectory msdirectory odirectory directory multiple="multiple" />
        <div class="caption">bestand opladen</div>
    </span>
  </label>
</div>
<div (click)="showDirPicker()" *ngIf="isLinux" >folder opladen</div>
