import { Inject } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { LoggingsService } from './LoggingsService';
import { OnEventSubscriptionInfo } from './OnEventSubscriptionInfo';

/*
* Central place to register resources that needs to be freed.
* For subscriptions to Observables use addSubscription
* For wiring FabricJs event handlers use registerEventHandler
*/

export class CleanupService {
  private static readonly GLOBAL: string = 'global';
  private static readonly registeredEventHandlers: { [ scope: string ]: OnEventSubscriptionInfo[] } =
    { global: [] };

  private static readonly subscriptions: { [ scope: string ]: Subscription[] } = { global: [] };

  @Inject(LoggingsService) private logger: LoggingsService;
  private _scope: string;

  constructor (private scope: string = CleanupService.GLOBAL) {
    this._scope = scope;
    this.ensureScope(scope);
  }

  addSubscription (subscription: Subscription, scope: string = this._scope) {
    this.ensureScope(scope);
    CleanupService.subscriptions[ scope ].push(subscription);
  }

  cleanupSubscriptions (scope: string = this._scope) {
    if (! (scope in CleanupService.subscriptions) || ! CleanupService.subscriptions[ scope ]) {
      return;
    }
    for (const subscription of CleanupService.subscriptions[ scope ]) {
      subscription.unsubscribe();
    }
    CleanupService.subscriptions[ scope ] = [];
    delete CleanupService.subscriptions[ scope ];
  }

  registerEventHandler (
    target: any,
    event: string,
    callback: Function,
    doWireHandler: boolean = true,
    scope: string = this._scope,
    overwriteExistingHandlers: boolean = true,
  ): void {
    this.ensureScope(scope);
    const existingHandler: OnEventSubscriptionInfo =
      this.lookForExistingEventHandler(target, event, scope);
    if (existingHandler && overwriteExistingHandlers) {
      this.detachEventHandler(existingHandler);

      return;
    }
    if (doWireHandler) {
      this.attachEventHandler(target, event, callback);
    }
    const eventSubscription: OnEventSubscriptionInfo = new OnEventSubscriptionInfo();
    eventSubscription.target = target;
    eventSubscription.event = event;
    eventSubscription.callBack = callback;
    CleanupService.registeredEventHandlers[ scope ].push(eventSubscription);
  }

  cleanupEventHandlers (scope: string = this._scope): void {
    if (! (scope in CleanupService.registeredEventHandlers) ||
      ! CleanupService.registeredEventHandlers[ scope ]) {
      return;
    }
    const handlers = [ ... CleanupService.registeredEventHandlers[ scope ] ];
    handlers.forEach((on: OnEventSubscriptionInfo) => {
      this.detachEventHandler(on);
      this.unregisterHandler(on);
    });
    CleanupService.registeredEventHandlers[ scope ] = [];
    delete CleanupService.registeredEventHandlers[ scope ];
  }

  registerGlobalEventHandler (
    target: any,
    event: string,
    callback: Function,
    doWireHandler: boolean = true,
    overwriteExisting: boolean = true,
  ): void {
    this.registerEventHandler(
      target,
      event,
      callback,
      doWireHandler,
      CleanupService.GLOBAL,
      overwriteExisting,
    );
  }

  cleanupGlobalEventHandlers (): void {
    this.cleanupEventHandlers(CleanupService.GLOBAL);
  }

  cleanAllScopes (excludeScopes?: string[]) {
    Object.keys(CleanupService.registeredEventHandlers).forEach((scope: string) => {
      if (! excludeScopes || excludeScopes.indexOf(scope) === 1) {
        this.cleanupEventHandlers(scope);
      }
    });
    Object.keys(CleanupService.subscriptions).forEach((scope: string) => {
      if (! excludeScopes || excludeScopes.indexOf(scope) === 1) {
        this.cleanupSubscriptions(scope);
      }
    });
  }

  private attachEventHandler (target: any, event: string, callback: Function) {
    if (target.on) {
      target.on(event, callback);
    } else {
      target.addEventListener(event, callback);
    }
  }

  private lookForExistingEventHandler (
    target: any,
    event: string,
    scope: string = this._scope,
  ): OnEventSubscriptionInfo {
    let existingHandler: OnEventSubscriptionInfo = null;
    if (! (scope in CleanupService.registeredEventHandlers) || ! CleanupService.registeredEventHandlers[ scope ]) {
      return existingHandler;
    }
    CleanupService.registeredEventHandlers[ scope ].forEach((on: OnEventSubscriptionInfo) => {
      if (on.target === target && on.event === event) {
        existingHandler = on;

        this.logger.debug(`CleanupService handler already exists ${target} ${event}`);

        return false;
      }

      return undefined;
    });

    return existingHandler;
  }

  private detachEventHandler (on: OnEventSubscriptionInfo) {
    if (on.target.off) {
      on.target.off(on.event, on.callBack);
    } else {
      on.target.removeEventListener(on.event, on.callBack);
    }
  }

  private unregisterHandler (on: OnEventSubscriptionInfo, scope: string = this._scope) {
    if (! (scope in CleanupService.registeredEventHandlers) || ! CleanupService.registeredEventHandlers[ scope ]) {
      return;
    }
    CleanupService.registeredEventHandlers[ scope ].forEach((info: OnEventSubscriptionInfo, index) => {
      if (info.event === on.event && info.target === on.target) {
        CleanupService.registeredEventHandlers[ scope ].splice(index, 1);
      }
    });
  }

  private ensureScope (scope: string) {
    if (! CleanupService.registeredEventHandlers[ scope ]) {
      CleanupService.registeredEventHandlers[ scope ] = [];
    }
    if (! CleanupService.subscriptions[ scope ]) {
      CleanupService.subscriptions[ scope ] = [];
    }
  }

}
