import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
@Injectable({
  providedIn: 'root'
})
export class AppService {
  emailChangedEvent$ = new Subject<string>();

  constructor() { }
}
