import { IPublication } from "redwood-model/dist/IPublication"
import { PARAMS } from "./params"

let PUBLICATIONS: IPublication[]

const fetchPublications = async (token: string): Promise<IPublication[]> => {
    try {
        let url: string
        let body = {
          queryType: "UserPublicationsQuery",
        }
  
        url = `${PARAMS["REDWOOD-LAMBDA"]}`
        const response = await fetch(url,
        {
          headers: {"authorization" : `Bearer ${token}`},
          method: "POST",
          body: JSON.stringify(body)
        })
        const data = await response.json()
  
        PUBLICATIONS = data.sort((a, b) => a.slug.localeCompare(b.slug))
        return PUBLICATIONS
    } catch(e) {
      console.error(e)
  
      return Promise.reject(e)
    }
}

export { PUBLICATIONS, fetchPublications }
