import { USER } from "src/user/USER";

export const MY_FILES = 'mijn bestanden';

export const email2MyFilesPath = (path: string): string => {
    if (!USER.isTeacher) return path
    return path.replace(USER.email, MY_FILES)
}

export const myFiles2EmailPath = (path: string): string => {
    if (!USER.isTeacher) return path
    return path.replace(MY_FILES, USER.email)
}