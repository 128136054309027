import { Injectable } from '@angular/core';
import { PARAMS } from '../params';

@Injectable({
  providedIn: 'root'
})
export class LoggingsService {
  private env: string = PARAMS['ENVIRONMENT'];

  constructor () {
  }

  public debug (... args: any[]): void {
    // tslint:disable-next-line:no-console
    console.log.apply(null, [this.now(), ...args]);
  }

  public error (... args: any[]): void {
    // tslint:disable-next-line:no-console
    console.error.apply(null, [this.now(), ...args]);
  }

  public info (... args: any[]): void {
    // tslint:disable-next-line:no-console
    console.info.apply(null, [this.now(), ...args]);
  }

  public warn (... args: any[]): void {
    // tslint:disable-next-line:no-console
    console.warn.apply(null, [this.now(), ...args]);
  }

  public log (... args: any[]): void {
    // tslint:disable-next-line:no-console
    console.error.apply(null, [this.now(), ...args]);
  }

  now(): string {
    const d = new Date()
    return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`
  }

}
