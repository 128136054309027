import { DragDropModule } from '@angular/cdk/drag-drop'
import { OverlayModule } from '@angular/cdk/overlay'
import { PortalModule } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import {CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule} from '@angular/core'
import { FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller'
import { NgSelectModule } from '@ng-select/ng-select'
import { AngularSplitModule } from 'angular-split'
import { ModalModule } from 'ngx-bootstrap/modal'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { ToastrModule } from 'ngx-toastr'
import { PrunusCanActivate } from '../PrunusCanActivate'
import { CONTEXT, Context } from '../infrastructure/Context'
import { ErrorHandlingService } from '../infrastructure/ErrorHandlingService'
import { LoggingsService } from '../infrastructure/LoggingsService'
import { NewlinePipe } from '../infrastructure/NewLinePipe'
import { SafePipe } from '../infrastructure/SafePipe'
import { SocketService } from '../infrastructure/SocketService'
import { ConfirmDialogComponent } from '../infrastructure/confirm-dialog/confirm-dialog.component'
import { UniversalCommandService } from '../infrastructure/cqrs/universal-command.service'
import { EnvironmentService } from '../infrastructure/keyboard/EnvironmentService'
import { ShortcutService } from '../infrastructure/keyboard/shortcut.service'
import { FileSystemService } from '../infrastructure/services/FileSystemService'
import { UniversalQueryService } from '../infrastructure/services/universal-query.service'
import { AWSCredentialService } from '../infrastructure/services/AWSCredentialService'
import { AdminCanActivate } from './AdminCanActivate'
import { AdvancedSearchModalComponent } from './advanced-search-modal/advanced-search-modal.component'
import { PrunusAppComponent } from './app.component'
import { APP_ROUTES } from './app.routing'
import { AssetThumbComponent } from './asset-thumb/asset-thumb.component'
import { DetailModalComponent } from './detail-modal/detail-modal.component'
import { DetailViewComponent } from './detail-view/detail-view.component'
import { ErrorModalComponent } from './error-modal/error-modal.component'
import { ExplorerDetailComponent } from './explorer-view/explorer-detail/explorer-detail.component'
import { ExplorerComponent } from './explorer-view/explorer.component'
import { ExportModalComponent } from './export-modal/export-modal.component'
import { FileDirDropDirective } from './file-dir-drop/file-dir-drop.directive'
import { FileFolderDropZoneComponent } from './file-folder-drop-zone/file-folder-drop-zone.component'
import { GeneralErrorPageComponent } from './general-error-page/general-error-page.component'
import { LinkPublicationModalComponent } from './link-publication-modal/link-publication-modal.component'
import { LocatorService } from '../infrastructure/services/locator.service'
import { MainComponent } from './main/main.component'
import { MoveModalComponent } from './move-modal-component/move-modal.component'
import { NavComponent } from './nav/nav.component'
import { Page404Component } from './page404/page404.component'
import { ProgressModalComponent } from './progress-modal/progress-modal.component'
import { PromptNameModalComponent } from './prompt-name-modal/prompt-name-modal.component'
import { TilesDataSourceService } from './tiles-view/tiles-data-source.service'
import { TilesViewComponent } from './tiles-view/tiles-view.component'
import { TokenManagerService } from '../infrastructure/services/token-manager.service'
import { TreeFoldersComponent } from './tree-folders/tree-folders.component'
import { UploaderService } from './upload-queue/UploaderService'
import { UploadQueueComponent } from './upload-queue/upload-queue.component'
import { UserDetailComponent } from './user-detail/user-detail.component';
import { NotificationsModalComponent } from './notifications-modal.component/notifications-modal.component';
import { ExportOptionsModalComponent } from './export-options-modal/export-options-modal.component';
import { BatchUploadCompleteModalComponent } from './batch-upload-complete-modal/batch-upload-complete-modal.component'
import {TreeItemComponent} from "./tree-folders/tree-item/tree-item.component";
import {TreeBaseComponent} from "./tree-folders/tree-base/tree-base.component";
import { SelectUploadFolderComponent } from './select-upload-folder/select-upload-folder.component'

@NgModule({
    declarations: [
      PrunusAppComponent,
      MainComponent,
      DetailViewComponent,
      ConfirmDialogComponent,
      NewlinePipe,
      SafePipe,
      UploadQueueComponent,
      FileDirDropDirective,
      AssetThumbComponent,
      PromptNameModalComponent,
      TilesViewComponent,
      Page404Component,
      MoveModalComponent,
      LinkPublicationModalComponent,
      FileFolderDropZoneComponent,
      GeneralErrorPageComponent,
      AdvancedSearchModalComponent,
      UserDetailComponent,
      NavComponent,
      ExplorerComponent,
      ExplorerDetailComponent,
      DetailModalComponent,
      TreeFoldersComponent,
      ExportModalComponent,
      ErrorModalComponent,
      ProgressModalComponent,
      NotificationsModalComponent,
      ExportOptionsModalComponent,
      TreeItemComponent,
      TreeBaseComponent,
      BatchUploadCompleteModalComponent,
      SelectUploadFolderComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        // forRoot used by modules that expose providers that need to be injected in the importing module
        ToastrModule.forRoot({
            preventDuplicates: true,
            maxOpened: 0,
            newestOnTop: true,
            onActivateTick: true
        }),
        ModalModule.forRoot(),
        RouterModule.forRoot(APP_ROUTES, {
            scrollPositionRestoration: 'enabled',
            enableTracing: false
        }),
        NgSelectModule,
        OverlayModule,
        PortalModule,
        DragDropModule,
        VirtualScrollerModule,
        InfiniteScrollModule,
        AngularSplitModule
    ],
    exports: [
        RouterModule,
    ],
    providers: [
        UniversalQueryService,
        PrunusCanActivate,
        { provide: Window, useValue: window },
        { provide: sessionStorage, useValue: sessionStorage },
        { provide: localStorage, useValue: localStorage },
        { provide: Navigator, useValue: navigator },
        { provide: location, useValue: location },
        { provide: Document, useValue: document },
        { provide: Context, useValue: CONTEXT },
        LoggingsService,
        ErrorHandlingService,
        SocketService,
        FileSystemService,
        TilesDataSourceService,
        AdminCanActivate,
        UniversalCommandService,
        ShortcutService,
        EnvironmentService,
        TokenManagerService,
        UploaderService,
        AWSCredentialService
    ],
    bootstrap: [PrunusAppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(private injector: Injector){
    LocatorService.injector = this.injector
  }
}
