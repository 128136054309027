import { Injectable, Injector } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { IEvent } from 'prunus-common/dist'
import { ReplaySubject } from 'rxjs/internal/ReplaySubject'
import { NotificationService } from 'src/infrastructure/services/notification-service'
import { TokenManagerService } from 'src/infrastructure/services/token-manager.service'
import { TOAST_LEVELS, showToast } from 'src/toast'
import { MESSAGES } from '../MESSAGES'
import { SESSION_ID } from '../SESSION_ID'
import { PARAMS } from '../params'
import { DIALOG_OPEN_OPTIONS } from './DialogOptions'
import { ErrorHandlingService } from './ErrorHandlingService'
import { ErrorInfo } from './ErrorInfo'
import { LoggingsService } from './LoggingsService'
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component'
import { ServerSocketService } from './services/server-socket.service'
import { UploaderService } from 'src/app/upload-queue/UploaderService'

const ERR_MAX_RETRIES = "Max retries of socket reconnect reached !"
function exponentialBackoff(retries: number, delay: number) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
      const originalMethod = descriptor.value;

      descriptor.value = async function (...args: any[]) {
          let attempt = 0
          while (attempt < retries) {
              try {
                  return await originalMethod.apply(this, args)
              } catch (error) {
                  attempt++
                  if (attempt >= retries) {
                    console.error(ERR_MAX_RETRIES, error)
                    throw new Error(ERR_MAX_RETRIES)
                  }
                  const backoffDelay = delay * Math.pow(2, attempt);
                  console.log(`Retrying ${propertyKey} in ${backoffDelay} ms (attempt ${attempt})`)
                  await new Promise(resolve => setTimeout(resolve, backoffDelay))
              }
          }
      };

      return descriptor
  }
}

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  socket_id: string
  ready$ = new ReplaySubject<void>()
  socket: WebSocket
  url: string = PARAMS['WEBSOCKET']
  evtToFuncMap = {}
  shouldClose = false
  worker: Worker
  old_socket_id = ''
  reconnectInterval: any
  uploaderService: UploaderService

  constructor(
    private errorHandlingService: ErrorHandlingService,
    private log: LoggingsService,
    private notificationService: NotificationService,
    private serverSocketService: ServerSocketService,
    private modalService: BsModalService,
    private tokenManagerService: TokenManagerService,
    private injector: Injector

  ) {
    window.onunload = () => {
      this.worker.postMessage("stop")
      this.worker.terminate()
      this.shouldClose = true
      this.close()
    }

    this.evtToFuncMap["killsocket"] = () => {
      this.shouldClose = true
      this.close()
      this.worker.postMessage("stop")
      this.worker.terminate()
      showToast(MESSAGES.SOCKET_CLOSE_BY_FORCE, TOAST_LEVELS.ERROR, "Fout opgetreden")
    }

    this.evtToFuncMap["alert"] = (data: any) => {
      showToast(data.msg, TOAST_LEVELS.INFO, "Info")
    }

    if (!this.socket || this.socket?.readyState !== WebSocket.OPEN) {
        this.connect()
    }

  }

  query_string(): string {
    const params = new URLSearchParams({
      token: this.tokenManagerService.access_token,
      channel: PARAMS.WEBSOCKET_CHANNEL,
      session_id: SESSION_ID,
    })

    const queryString = params.toString()
    console.log(`SocketService: queryString ${queryString}`)

    return encodeURI(queryString)
  }

  _inErrorState = false
  onerror (e: any) {
    this.log.error(`(SocketService) connection error ${e}. is already in error state ${this._inErrorState}`)
    if (this._inErrorState) return
    this._inErrorState = true

    if (e) {
      if ((e instanceof String) && e.toLowerCase() === 'not authorized') {
        this.log.error(e.toString())

        return
      }
      if (e['message'] === 'xhr poll error') {
        this.notificationService.error(MESSAGES.SRV_SOCKET_CON_LOST)

        return
      }
      this.errorHandlingService.handleError(e)
    }

    try {
      this.socket?.close()
    } catch {
    }
    this.socket = undefined
    setTimeout(() => { this.connect() }, 5000)
  }

  async onopen (s: any) {
    this.log.debug(`(SocketService) socket opened.`)
    await this.emit("getConnectionId", {
      "id-token": this.tokenManagerService.id_token
    })

    if (typeof Worker !== 'undefined') {
      if (this.worker) {
        this.worker.terminate()
      }
      this.worker = new Worker(new URL('./socket.worker', import.meta.url))
      this.worker.onmessage = async (event) => {
        this.log.debug("event", event)
        switch (event.data) {
          case "keep alive":
            if (this.socket.readyState == WebSocket.OPEN) {
              await this.emit("getConnectionId", {})
            }
            break
          case "sleep":
            if (!this.uploaderService) {
              this.uploaderService = this.injector.get(UploaderService)
            }
            if (this.uploaderService.isBatchUploading) return // don't stop if we are batchuploading
            this.shouldClose = true
            this.worker.terminate()
            this.socket.close()
            const ref: BsModalRef = this.modalService.show(ConfirmDialogComponent, DIALOG_OPEN_OPTIONS)
            const confirmDialog: ConfirmDialogComponent = ref.content
            confirmDialog.message = MESSAGES.NON_ACTIVITY
            confirmDialog.okOnly = true
            const nextAction = () => { window.location.reload() }
            confirmDialog.onNoAction = confirmDialog.onYesAction = nextAction
            break
        }

      }
      this.worker.postMessage({event: 'wakeup'})
    } else {
      // Web workers are not supported in this environment, fallback to timer.
      this.log.warn("webworkers not supported ... will not work when tab goes to background")
      //setInterval(async () =>  { await this.emit("getConnectionId", {}) }, 30 * 1000)
    }
    this.ready$.next()
  }

  async onmessage(event) {
    this.log.info(`SocketService received message event.data: ${JSON.stringify(event ? event.data : "")}`)
    if (event) {
      const data = JSON.parse(event.data)
      if (data.connectionId) {
        this.socket_id = data.connectionId

        if (this.old_socket_id && this.old_socket_id !== data.connectionId) {
          await this.emit("updateConnectionId", { old: this.old_socket_id, new: data.connectionId})
          this.log.debug(`received connectionId : {data.connectionId}`)
          this.old_socket_id = data.connectionId
        }
      }
      if (data.action) {
        if (this.evtToFuncMap[data.action]) {
          this.evtToFuncMap[data.action](data)
        }
      }
      if (data.eventType) {
        this.serverSocketService.serverEventsSubject$.next(data as IEvent)
      }
      if (data.error) {
        let msg = ""
        if (typeof data.error === "string") {
          msg = data.error
        } else if(data.error.msg) {
          msg = data.error.msg
        }
        const errorInfo = new ErrorInfo(msg)
        Object.assign(errorInfo, data.error) // copy code, resource and so on
        this.errorHandlingService.handleError(errorInfo)
      }
    }
  }

  onclose = () => {
    this.old_socket_id = this.socket_id
    if (!this.shouldClose) { // auto reconnect on connection loss
        this.reconnectInterval = setTimeout(() => {
            if (this.worker) {
              this.worker.terminate()
            }
            this.log.warn(`socket connection lost ${this.socket_id} reconnecting ...`)
            const newWebSocket = new WebSocket(this.socket.url)
            this.wireEvtHandlers(newWebSocket)
            this.socket = newWebSocket
            this.log.warn(`socket connection lost, reconnected now, ${this.socket_id}.`)
        }, 1000)
    }
  }

  wireEvtHandlers(socket: WebSocket) {
    socket.onerror = e => { this.onerror(e) }
    socket.onopen = s => { this.onopen(s) }
    socket.onmessage = event => { this.onmessage(event) }
    socket.onclose = () => { this.onclose() }
  }

  @exponentialBackoff(12, 1000)
  connect() {
    try {
      if (this.socket && this.socket.readyState !== WebSocket.CLOSED) return
      const fullUrl = this.url + "?" + this.query_string()
      this.socket = new WebSocket(fullUrl)
      this.wireEvtHandlers(this.socket)
      this._inErrorState = false
    } catch(e: any) {
      if (this.socket) {
        this.socket.close()
        this.socket = undefined
      }
      if (e?.message ===  ERR_MAX_RETRIES) {
        this.notificationService.error(MESSAGES.ERR_MAX_SOCKET_CONNECT_RETRIES)
      } else {
        throw e
      }
    }
  }

  async waitForConnection(interval = 250) : Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.socket && this.socket.readyState === 1) {
        resolve()
      } else {
        setTimeout(async() => {
            await this.waitForConnection(interval)
            resolve()
        }, interval)
      }
    })
  }

  async emit(eventName: string, obj: any) {
    await this.waitForConnection()
    obj.action = eventName
    obj.connectionId = this.socket_id
    this.socket.send(JSON.stringify(obj))
  }

  on(eventName: string, f: Function) {
    this.evtToFuncMap[eventName] = f
  }

  off(eventName: string, f: Function) {
    if(this.evtToFuncMap[eventName]) {
      delete this.evtToFuncMap[eventName]
    }
  }

  close(): void {
    this.log.debug(`closing socket ${this.socket_id}`)
    this.socket.close()
  }

}
