<div class="modal-header">
  <h5 class="modal-title pull-left">{{title}}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
    <edu-icon-delete-fat type="filled"></edu-icon-delete-fat>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex flex-column">
    <p>kies uit de publicaties gelinkt aan uw gebruiker</p>
      <ng-select
      id="selectTags" aria-describedby="tagsHelp"
      [items]="publications"
      [multiple]="true"
      placeholder="zoek een publicatie"
      bindLabel="slug"
      [loading]="suggestionsLoading"
      [ngModel]="selectedPublications"
      [ngModelOptions]="{standalone: true}"
      [typeahead]="suggestionsInput$"
      (open)="onOpen()"
      (change)="changeSelectedPublications($event)"
      class="margin-small"
      loadingText="bezig met laden ..."
      [closeOnSelect]="true"
      [maxSelectedItems]="MAX_SELECTED_ITEMS"
    >
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div [ngOptionHighlight]="search">{{ item.slug }}</div>
      </ng-template>
    </ng-select>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex flex-row justify-content-end">
    <button type="button"
            class="btn btn-primary ml-2" (click)="onSave()">bevestigen</button>
    <button type="button" class="btn btn-danger ml-2" (click)="onClose()">annuleren</button>
  </div>
</div>
