import { Injectable } from '@angular/core'
import SelectionArea from "@viselect/vanilla"
import { CONSTRAINTS } from 'prunus-common/dist'
import { Subject } from 'rxjs/internal/Subject'
import { LoggingsService } from '../../infrastructure/LoggingsService'
import { MouseButtons } from '../../infrastructure/MouseButton'
import { formatMsg, MESSAGES } from '../../MESSAGES'
import { IResource } from '../../resource/interface/IResource'
import { ExplorerDataSourceService } from './explorer-data-source-service'
import { NotificationService } from '../../infrastructure/services/notification-service'

@Injectable({
  providedIn: 'root'
})
export class ExplorerMultiSelectService {
  $deselect: Subject<IResource> = new Subject<IResource>();
  $deselectAll: Subject<IResource[]> = new Subject<IResource[]>();
  $select: Subject<IResource> = new Subject<IResource>();
  selectionArea: SelectionArea;
  dataUri = 'data-uri';
  selectedClass = 'selected';

  constructor(private log: LoggingsService,
              private document: Document,
              private notificationService: NotificationService,
              private explorerDataSourceService: ExplorerDataSourceService
    ) {
  }

  init() {
    const selectionArea = new SelectionArea({

      // Class for the selection-area itself (the element).
      selectionAreaClass: 'selection-area',

      // Class for the selection-area container.
      selectionContainerClass: 'selection-area-container',

      // Query selector or dom-node to set up container for the selection-area element.
      container: '.search-results',

      // document object - if you want to use it within an embed document (or iframe).
      //document: window.document,

      // Query selectors for elements which can be selected.
      selectables: [ 'app-asset-thumb'],

      // Query selectors for elements from where a selection can be started from.
      startAreas: ['.search-results'],

      // Query selectors for elements which will be used as boundaries for the selection.
      boundaries: ['.search-results'],

      // Behaviour related options.
      behaviour: {

          // Specifies what should be done if already selected elements get selected again.
          //   invert: Invert selection for elements which were already selected
          //   keep: Keep selected elements (use clearSelection() to remove those)
          //   drop: Remove stored elements after they have been touched
          overlap: 'invert',

          // On which point an element should be selected.
          // Available modes are cover (cover the entire element), center (touch the center) or
          // the default mode is touch (just touching it).
          intersect: 'touch',

          // px, how many pixels the point should move before starting the selection (combined distance).
          // Or specifiy the threshold for each axis by passing an object like {x: <number>, y: <number>}.
          startThreshold: 10,

          // Scroll configuration.
          scrolling: {

              // On scrollable areas the number on px per frame is devided by this amount.
              // Default is 10 to provide a enjoyable scroll experience.
              speedDivider: 10,

              // Browsers handle mouse-wheel events differently, this number will be used as
              // numerator to calculate the mount of px while scrolling manually: manualScrollSpeed / scrollSpeedDivider.
              manualSpeed: 750,

              // This property defines the virtual inset margins from the borders of the container
              // component that, when crossed by the mouse/touch, trigger the scrolling. Useful for
              // fullscreen containers.
              startScrollMargins: {x: 0, y: 0}
          }
      },

      // Features.
      features: {

          // Enable / disable touch support.
          touch: false,

          // Range selection.
          range: true,

          // Configuration in case a selectable gets just clicked.
          singleTap: {

              // Enable single-click selection (Also disables range-selection via shift + ctrl).
              allow: true,

              // 'native' (element was mouse-event target) or 'touch' (element visually touched).
              intersect: 'native'
          }
      }
    });

    this.explorerDataSourceService.moreDataFetched$.subscribe(() => {
      if (selectionArea) {
        setTimeout(() => {
          selectionArea.resolveSelectables();
        }, 0);
      }
    })

    selectionArea.on('beforestart', evt => {
        return this.onBeforeStart(evt);
      }).on('beforedrag', evt => {
      }).on('start', evt => {
          this.onStart(evt);
      }).on('move', evt => {
        this.onMove(evt);
      }).on('stop', evt => {
        this.onStop(evt);
      });
  }

  select(iResource: IResource) {
    if (iResource) {
      if (this.selectedResources.length >= CONSTRAINTS.MAX_RESOURCES_SELECTED) {
        this.notificationService.error(
          formatMsg(MESSAGES.MAX_SELECTED_ITEMS_EXCEEDED, {MAX_RESOURCES_SELECTED: CONSTRAINTS.MAX_RESOURCES_SELECTED} ) )
        this.deselectAll();
        return;
      }
      iResource.isSelected = true;
      this.$select.next(iResource);
    }
    const el = this.document.querySelector('app-explorer-detail app-asset-thumb[data-uri="' + iResource.uri + '"]')
    if (el) {
      (el as any).blur()
      this.setFocus()

      const result = el.querySelector('.folder, .file')

      const classes = el.classList;
      if (classes && !classes.contains(this.selectedClass)) {
        classes.add(this.selectedClass)

      }

    }
  }

  deselect(iResource: IResource) {
    if (!iResource) {
      return
    }

    iResource.isSelected = false;

    const el = this.document.querySelector('app-explorer-detail app-asset-thumb[data-uri="' + iResource.uri + '"]')
    if (el) {
      (el as any).blur();
      this.setFocus();
      el.classList.remove(this.selectedClass);
    }

    this.$deselect.next(iResource)
  }

  deselectAll() {
    this.log.debug(new Date() + ' deselectall ...')

    for(const r of this.selectedResources) {
      this.deselect(r);
    }

  }

  setFocus(): void {
    (<any>this.document.querySelector('app-explorer-detail')).focus()
  }

  get selectedResources(): IResource[] {

    return this.explorerDataSourceService.resources.filter(r => r.isSelected && (!r.errorInfo))
  }

  onMove(evt) {
    //this.log.debug('move', evt);
  }

  onStop(evt) {
    // Do something with the selected elements.

    for(let selected of evt.store.selected) {
      const match = this.explorerDataSourceService.resources.find(r => {
          if (!selected.hasAttribute("data-uri")) return false
          return r.uri === selected.attributes['data-uri'].value
      })
      if (match) {
        this.select(match)
      }

    }

    this.log.debug('stop', evt.store.selected);
  }

  onBeforeStart(evt) {
    if (evt.event['button'] === MouseButtons.SecondaryOrRight) {
      return false
    }
      // Use this event to decide whether a selection should take place or not.
      // For example if the user should be able to normally interact with input-elements you
      // may want to prevent a selection if the user clicks such a element:
      // selection.on('beforestart', ({event}) => {
      //   return event.target.tagName !== 'INPUT'; // Returning false prevents a selection
      // });

      console.log('beforestart', evt);

      return true;
  }

  onStart(evt) {
    // A selection got initiated, you could now clear the previous selection or
    // keep it if in case of multi-selection.
    this.log.debug('start', evt);
  }

}
