import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { COMMANDS, ERROR_CODES, EVENTS, IBulkDeleteResourcesCommand, IBulkMoveResourceCommand, ICommand, IGenericProgressEvent } from 'prunus-common/dist';
import { CleanupService } from '../../infrastructure/CleanupService';
import { ErrorHandlingService } from '../../infrastructure/ErrorHandlingService';
import { ServerSocketService } from '../../infrastructure/services/server-socket.service';

@Component({
  selector: 'app-progress-modal',
  templateUrl: './progress-modal.component.html',
  styleUrls: ['./progress-modal.component.scss']
})
export class ProgressModalComponent implements OnInit, OnDestroy {
  title: string;
  progressMessage: string;
  cleanupService = new CleanupService(ProgressModalComponent.name);
  cmd : ICommand;

  constructor(public bsModalRef: BsModalRef,
              private serverSocketService: ServerSocketService,
              private errorHandlingService: ErrorHandlingService,
    ) { }

  ngOnInit(): void {
    this.cleanupService.addSubscription(
      this.serverSocketService.subscribe2ServerEvents().subscribe(
        (evt) => this.onServerEvent(evt)
      )
    );

    this.cleanupService.addSubscription(
      this.errorHandlingService.errors$.subscribe(e => {
        /*
        const uri = e.uri || (e['resource'] ? e['resource'].uri : undefined);
        if (!e) return; 
        if (this.cmd && uri) {
          switch (this.cmd.commandType) {
            case COMMANDS.BulkDeleteResourcesCommand:
              if ((this.cmd as IBulkDeleteResourcesCommand).uris.includes(uri)) {
                this.onClose();
              }
              break;
            case COMMANDS.BulkMoveResourceCommand:  
              if ((this.cmd as IBulkMoveResourceCommand).sourceUris.includes(uri)) {
                this.onClose();
              }
              break;  
          }
        } else if ([ERROR_CODES.RESOURCE_LOCKED, ERROR_CODES.CONCURRENCY_VIOLATION].includes(e.code)) {
          // it is not really possible to match by uri as locking can be because of a parent folder that is locked
          // but errors are only sent to the socket that started the action, so it's more or less safe to assume that is a lock for us
          this.onClose();
        }*/
        this.onClose();
      })
    );
  }

  ngOnDestroy(): void {
    this.cleanupService.cleanupSubscriptions();
  }

  onClose() {
    this.bsModalRef.hide();
  }

  onServerEvent(evt) {
    if (evt.uid === this.cmd.uid) {
      this.onClose();
    }
    switch (evt.eventType) {
      case EVENTS.GenericProgressEvent:
        const genericProgressEvent: IGenericProgressEvent = evt as IGenericProgressEvent;
        if (genericProgressEvent.commandUid === this.cmd.uid) {
          this.progressMessage = genericProgressEvent.content;
        }
        break;
    }
  }

}
