<div class="modal-header">
    <h5 class="modal-title pull-left">{{title}}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
      <edu-icon-delete-fat type="filled"></edu-icon-delete-fat>
    </button>
  </div>
  <div class="modal-body">
    <div class="d-flex flex-column">
      <p style="max-width: 500px; overflow-x: hidden;text-overflow: ellipsis;">{{message}}</p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex flex-row justify-content-end">
      <button type="button" class="btn btn-danger ml-2" (click)="onClose()"> sluiten</button>
    </div>
  </div>

