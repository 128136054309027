<script src="../../main.ts"></script>
<div class="assetManagerContainer">
    <div>
      <div class="header" [hidden]="showHeader">
        <div class="mainContainer" [ngClass] = "{'tagsOpen': searchTerms.length > 0}">
          <div class="branding">
            <span>Afbeeldingsbeheer</span>
          </div>
          <div class="navigation">
            <nav>
              <a
                [routerLink]="['/main/explorer']"
                [routerLinkActive]="['routerLinkActive']"
                [routerLinkActiveOptions]="{ exact: false }"
                [queryParams]="{path: fileSystemService.path}"
                [hidden]="isFileExplorer"
                >
                  <edu-icon-explorer  class="explorerButton" type="filled"></edu-icon-explorer>
              </a>
            </nav>

          </div>
          <div class="searchContainer">
            <div id="autocomplete" class="autocomplete search-control" style="height: 47px">
              <input class="autocomplete-input" (keyup.enter)="handleEnterKeySearch()" />
              <ul class="autocomplete-result-list"></ul>
            </div>
            <div class="horizontal-scroll">
              <ul class="horizontal-list">
                <li *ngFor="let searchTerm of searchTerms">
                    <span class="badge badge-secondary">
                      <span (click)="removeTerm(searchTerm)" aria-hidden="true" class="ng-value-icon left">
                        <edu-icon-delete-fat size="0.6rem" color="white" type="filled"></edu-icon-delete-fat>
                      </span>
                      &nbsp;{{searchTerm}}
                    </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="slider">
            <div class="form-check checkbox-slider-lg checkbox-slider&#45;&#45;a and-or">
              <label>
                <input (change)="doSearch($event)" [(ngModel)]="or"
                       type="checkbox"><span></span>
              </label>
            </div>
            <div *ngIf="showAdvancedSearch"><button class="advancedSearch" (click)="openAdvancedSearch($event)">...</button></div>
          </div>

        </div>
        <div class="subHeader">
          <div class="courseSelect">
            <div [ngStyle]="{ 'top': isFileExplorer ? '30px' : '0px'  }" style="position: relative;">
              <div [hidden]="!isTiles">
                <span>vak</span>
                <ng-select style="min-width: 400px; width: fit-content; display: inline-block; margin-left: 10px;"
                [items]="user.publications"
                [multiple]="true"
                [addTag]="true"
                bindLabel="slug"
                bindValue="id"
                placeholder="zoek op een publicatie"
                [loading]="suggestionsPublicationsLoading"
                [(ngModel)]="publication_ids"
                [typeahead]="suggestionsInputPublications$"
                loadingText="bezig met laden ..."
                [closeOnSelect]="true"
                (open)="onOpenPublications()"
                (change)="onPublicationsChanged($event)"
              >
                <ng-template ng-option-tmp let-item="item" let-search="searchPublications">
                  <div [ngOptionHighlight]="search">{{ item.slug }}</div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div>
          <app-nav [hidden]="isFileExplorer || isTiles" class="ml-2" style="display: block"></app-nav>
          <div class="welcome" *ngIf="user">
            <img style="border-radius: 25px; display: inline-block;" class="mr-3 mt-2 mb-1" [src]="sizedAvatar" (click)="openUserDetail()">
            <span><a (click)="openUserDetail()">welkom</a>&nbsp;<a (click)="openSSO()">{{user?.first_name}}</a></span>
            <div style="display: inline-block;" class="mr-2" [ngClass]="{'triangle-up': uploadErrorsModalComponent, 'triangle-down': !uploadErrorsModalComponent}" (click)="toggleTriangle()"></div>
            <a class="ml-1" style="text-decoration: underline; cursor: pointer" *ngIf="!context.isExternal" (click)="logout($event)">uitloggen</a>
          </div>
        </div>
      </div>
      </div>
      <div id="detailContainer">
        <router-outlet>
        </router-outlet>
      </div>
      <app-upload-queue [hidden]="hideUploadQueue"></app-upload-queue>
    </div>
</div>
