export class AssetOverviewPageGuide {
  get data (): any[] {
    return [
      {
        title: 'Prunus Help',
        content: '<p>Welkom bij Prunus.</p>',
      },
      {
        title: 'Zoek balk',
        content: 'Type hier uw tags om beelden met ingegeven tags te zoeken',
        element: '.search-container .select2.select2-container',
        position: 'bottom',
      },
      {
        title: 'Opladen knop',
        content: 'Klik op deze knop om foto\'s van op uw computer op te laden',
        element: '.dropZone.thumb',
        position: 'right',
      },
      {
        title: 'thumbnail info',
        content: 'Hoover over thumbnail en klik op het vergrootglas om meer info over deze afbeelding te krijgen',
        element: 'app-asset-thumb',
        position: 'right',
      },
    ];
  }
}
