import { Injectable } from '@angular/core'
import { Subject } from 'rxjs/internal/Subject'
import { Observable  } from 'rxjs/internal/Observable'

@Injectable({
  providedIn: 'root'
})
export class ServerSocketService {
  serverEventsSubject$: Subject<any> = new Subject<any>()

  subscribe2ServerEvents(): Observable<any> {
    return this.serverEventsSubject$.asObservable()
  }

}
