import { IPublication, ROLES } from "redwood-model/dist";

export class User {

  id: number = 0;
  firstName: string = '';
  lastName: string = '';
  userName: string = '';
  avatar: string = '';
  email: string= '';
  roles: string[] = [];
  publications: IPublication[] = [];

  get isAdmin (): boolean {
    return this.hasRole( ROLES.USER_ADMIN);
  }

  get isTeacher (): boolean {
    return this.hasRoles([ ROLES.SCHOOL_TEACHER,  ROLES.USER_TEACHER]) ;
  }

  get isStudent (): boolean {
    return this.hasRole( ROLES.SCHOOL_STUDENT);
  }

  get isPublisher (): boolean {
    return this.hasRoles( ROLES.ALL_PUBLISHERS);
  }

  get isInternalUser(): boolean {
    return this.hasRoles( ROLES.ALL_INTERNAL_USERS);
  } 

  get sizedAvatar (): string {
    if (! this.avatar) {
      return this.avatar;
    }

    return this.avatar.replace('%size%', '32');
  }  

  hasRole (role: string): boolean {
    return this.roles.indexOf(role) !== - 1;
  }

  hasRoles (roles: string[]): boolean {
    return !! this.roles.filter(value => roles.includes(value)).length;
  }
}
