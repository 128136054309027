<div class="modal-header">
    <h5 class="modal-title pull-left"></h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
      <edu-icon-delete-fat type="filled"></edu-icon-delete-fat>
    </button>
  </div>
  <div class="modal-body">
    <div class="mt-3 mb-3" style="display: flex; justify-content: center;">
        <!--<i class="fa fa-refresh fa-spin fa-2x "></i>-->
        <br />
        <div class="ml-3">{{progressMessage}}</div>
        <br />
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex flex-row justify-content-end">
      <button type="button" class="btn btn-danger ml-2" (click)="onClose()"> annuleren</button>
    </div>
  </div>
