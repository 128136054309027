 <div contextmenu="return false;" style="height:100%">
   <div *ngIf="resource['isNew'] && !resource.errorInfo" class="newBadgeContainer">
        <span class="newBadgeLabel">nieuw</span>
    </div>
    <div *ngIf="resource.errorInfo" class="errorBadgeContainer">
        <span class="errorBadgeLabel">fout</span>
    </div>
    <div class="img image thumbnailWrapper" [style.opacity]="resource.isNotYetProcessedByServer ? '0.1' : '1'">
      <ng-container *ngIf="resource.kind !== 'FOLDER'" [ngSwitch]="resourceType">
        <edu-icon-video-icon *ngSwitchCase="'video'" class="icon"></edu-icon-video-icon>
        <edu-icon-file-pdf *ngSwitchCase="'pdf'" class="icon"></edu-icon-file-pdf>
        <edu-icon-file-image *ngSwitchCase="'image'" class="icon"></edu-icon-file-image>
        <edu-icon-svg *ngSwitchCase="'svg'" class="icon"></edu-icon-svg>
        <edu-icon-file-powerpoint *ngSwitchCase="'ppt'" class="icon"></edu-icon-file-powerpoint>
        <edu-icon-file-word *ngSwitchCase="'doc'" class="icon"></edu-icon-file-word>
        <edu-icon-file-excel *ngSwitchCase="'xls'" class="icon"></edu-icon-file-excel>
      </ng-container>


        <div class="file" *ngIf="resource.kind === 'FILE'">
            <img *ngIf="isImage && !isVideo" #thumbImg [alt]="altText" [src]="thumbUrl">
            <img *ngIf="isDocument" #thumbImg [alt]="altText"  [src]="thumbUrl" [class]="docStyle()">
            <img *ngIf="isSVG" #thumbImg [alt]="altText" [src]="thumbUrl"   >
            <video *ngIf="isVideo" #thumbImg [alt]="altText" [poster]="thumbUrl">
                <source [src]="videoUrl" [type]="videoType">
            </video>
        </div>
        <div class="folder center" *ngIf="resource.kind === 'FOLDER'">
            <a style="color: inherit"
            [queryParams]="getQS(resource)"
            [routerLink]="[ './']"
            [ngStyle]=" { 'pointer-events': resource.error ? 'none' : 'auto' }"
            >
              <span class="iconContainer">
                <edu-icon-folder color="#e8e8e8"></edu-icon-folder>
              </span>
                <div class="display-text" >{{resource.name}}</div>
            </a>
        </div>
    </div>
    <div *ngIf="!resource['isNotYetProcessedByServer'] && resource.kind === 'FILE'"
        class="overlay"
        [ngClass]="{fullscreen: canGoToDetailAndUpload, external: context.isExternal}">

        <button class="select" *ngIf="context.isExternal" (click)="import()">
          <edu-icon-check-fat type="filled"></edu-icon-check-fat>
        </button>

        <div class="info">
            <span class="name">{{resource.name}}</span>
        </div>
    </div>
</div>
