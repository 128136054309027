<div class="detailContainer" [ngClass]="{'tileDetail': !isInExplorer}">
  <div class="closeButtonContainer">
    <button  type="button" (click)="close()" aria-label="Close" class="close pull-right">
      <span aria-hidden="true">
        <edu-icon-delete-fat type="filled"></edu-icon-delete-fat>
      </span>
    </button>
  </div>
  <form *ngIf="resource" #detailForm="ngForm" (ngSubmit)="onSave()">
    <div class="input-group mb-3">
      <input [(ngModel)]="filename" aria-describedby="nameHelp" (keypress)="onKeyPressName($event)" class="form-control" id="txtName" name="name"
             placeholder="bestandsnaam" type="text" [readonly]="!editName || !canEdit" [maxlength]="MAX_LENGTH">
      <small class="form-text text-muted d-none" i18n id="nameHelp">The original filename.</small>
      <div class="input-group-append" [hidden]="resource.kind === 'FOLDER'">
        <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="toggleEditName()"><i
          class="fas fa-pencil-alt"></i></button>
      </div>
    </div>
    <div class="previewBtnContainer" [hidden]="isPDF || isImage || showPreview || resource.kind === 'FOLDER' || isOfficeDoc || isVideo">
      <a class="previewBtn" href="javascript:void(0)"  (click)="showPreview = !showPreview"><span class="icon"></span> preview</a>
    </div>
    <div class="previewContainer" *ngIf="isImage || showPreview || isPDF || isOfficeDoc" [ngStyle]="{ 'min-width': isSVG ? '400px' : '', 'min-height': isSVG ? '250px' : ''  }">
      <img #detailImg [src]="imageSrc | safe" alt="{{filename}}" [hidden]="isOfficeDoc">
      <iframe [src]="pdfSelectUrl | safe" *ngIf="isPDF || isOfficeDoc"></iframe>
      <div class="overlay pt-1 pr-2 pb-1 pl-2" *ngIf="!loading && !isPDF">
        <div class="icon"></div>
        <div class="dimensions flex-fill text-right">{{resource.width}}x{{resource.height}}</div>
      </div>
    </div>
    <div class="previewContainer" *ngIf="isVideo && videoUrl">
      <video #detailImg controls #thumbImg [alt]="altText" [poster]="thumbUrl" style="width: 100%;" >
        <source [src]="videoUrl | safe" [type]="videoType">
      </video>
    </div>

    <div class="form-group" [hidden]="resource.kind === 'FOLDER'">
      <label for="selectTags">Tags</label>
      <ng-select
        id="selectTags" aria-describedby="tagsHelp"
        [items]="suggestions$ | async"
        [multiple]="true"
        [addTag]="true"
        placeholder="zoek op een woord"
        [loading]="suggestionsLoading"
        [ngModel]="tags"
        [ngModelOptions]="{standalone: true}"
        [typeahead]="suggestionsInput$"
        (open)="onOpen()"
        (change)="changeTags($event)"
        class="margin-small"
        addTagText="toevoegen"
        loadingText="bezig met laden ..."
        [closeOnSelect]="true"
        [disabled]="!canEdit"
        (add)="tagAdded($event)"
        [maxSelectedItems]="MAX_SELECTED_ITEMS"
      >
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <div [ngOptionHighlight]="search">{{ item }}</div>
        </ng-template>
      </ng-select>
      <small class="form-text text-muted d-none" i18n id="tagsHelp">Add custom tags to differentiate the image
      </small>
    </div>
    <div class="form-group" *ngIf="externalUsages.length" [hidden]="resource.kind === 'FOLDER'">
      <label i18n>Afbeelding wordt gebruikt op {{ externalUsages.length }} locatie(s)</label>
      <ul class="list-unstyled">
        <li *ngFor="let usage of externalUsages">
          <i class="streamline icon-link"></i>
          <a [href]="usage.url | safe">applicatie: {{ usage.app }}  id: {{ usage.uid }} naam: {{ usage.name }} eigenaar: {{ usage.owner }}</a>
        </li>
      </ul>
    </div>
    <div>
      <div class="mt-2 bmb-2 form-group" *ngIf="canViewTechicalInfo">
        <label for="txtPath">Locatie</label>
        <input [(ngModel)]="location" class="form-control" id="txtPath" name="path" type="text" disabled>
      </div>
      <div class="mt-2 bmb-2 form-group" *ngIf="isPDF || isOfficeDoc">
        <label for="pageCount">aantal pagina's </label>
        <input [value]="resource.pageCount" class="form-control" id="pageCount" name="pageCount" type="number" disabled>
      </div>
      <div class="mt-2 bmb-2 form-group" *ngIf="resource.kind === 'FOLDER'">
        <label for="fileCount">aantal resources (= files + folders)</label>
        <input [value]="folderInfo?.count" class="form-control" id="fileCount" name="path" type="text" disabled>
      </div>
      <div class="mt-2 bmb-2 form-group" *ngIf="resource.kind === 'FOLDER'">
        <label for="totalFileSize">grootte in MB</label>
        <input class="form-control" id="totalFileSize" [value]="(folderInfo?.totalFileSize / 1024).toFixed(2)" name="path" type="text" disabled>
      </div>
      <div class="mt-2 bmb-2 form-group" [hidden]="resource.kind === 'FOLDER'">
        <label for="txtExtension">Extensie</label>
        <input [(ngModel)]="resource.extension" class="form-control" id="txtExtension" name="extension" type="text" disabled>
      </div>
      <div class="mt-2 bmb-2 form-group" *ngIf="canViewTechicalInfo">
        <label for="txtURI">URI</label>
        <input [(ngModel)]="resource.uri" class="form-control" id="txtURI" name="URI" type="text" readonly>
        <a href='void:' (click)='copy2Clipboard("txtURI")'>kopiëer</a>
      </div>
      <div class="mt-2 bmb-2 form-group" [hidden]="resource.kind === 'FOLDER'" *ngIf="canViewTechicalInfo">
        <label for="txtVersion">Versie</label>
        <input [(ngModel)]="resource.version" class="form-control" id="txtVersion" name="version" type="text" disabled>
      </div>
      <div class="mt-2 bmb-2 form-group" *ngIf="canViewTechicalInfo">
        <label for="txtOwner">Gebruiker</label>
        <input [(ngModel)]="resource.user_email" class="form-control" id="txtOwner" name="owner" type="text" disabled>
      </div>
      <div class="mt-2 bmb-2 form-group" [hidden]="resource.kind === 'FOLDER'" *ngIf="canViewTechicalInfo">
        <label for="nFileSize">grootte MB</label>
        <input [(ngModel)]="size" class="form-control" id="nFileSize" name="nFileSize" type="number" readonly>
        <a href='void:' (click)='copy2Clipboard("nFileSize")'>kopiëer</a>
      </div>
      <div class="mt-2 bmb-2 form-group" *ngIf="canViewTechicalInfo">
        <label for="last_modified_at">Laatst gewijzigd op</label>
        <input [value]="formatDateTime(resource.last_modified_at)" class="form-control" id="last_modified_at" name="last_modified_at" type="text" readonly>
      </div>
      <div class="mt-4 bmb-2 form-group" *ngIf="resource.kind === 'FILE' && canViewTechicalInfo">
        <a [href]="s3OrigLink" target="_blank">bekijk origineel bestand op S3</a>
      </div>
      <div class="mt-4 bmb-2 form-group" *ngIf="resource.kind === 'FILE' && canViewTechicalInfo && !isOfficeDoc">
        <a [href]="s3MasterLink" target="_blank">bekijk master bestand op S3</a>
      </div>
    </div>
    <div class="mt-5 d-flex">
      <button type="button" [hidden]="resource.kind === 'FOLDER'" class="btn btn-danger" (click)="onDelete()" [disabled]="!canEdit || loading || externalUsages.length > 0">verwijderen</button>
      <span class="flex-fill"></span>
      <button type="submit" [hidden]="resource.kind === 'FOLDER'" class="btn btn-primary" [disabled]="loading || !isDirty || !canEdit || hasNameError">aanpassen</button>
      <a (click)="close(true)"  class="btn btn-default">annuleren</a>
    </div>
  </form>
</div>

