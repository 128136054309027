<div id="confirmDialogContainer">
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button *ngIf="!okOnly" (click)="onClose()" aria-label="Close" class="close pull-right" type="button">
          <edu-icon-delete-fat type="filled"></edu-icon-delete-fat>
        </button>
    </div>
    <div class="modal-body">
        <div class="messageBody">
            <form>
                <p class="message" [innerHtml]="message | newline">{{message}}</p>
                <div class="row">
                    <div class="col-sm-8"></div>
                    <div class="col-sm-4">
                        <button (click)="onYes()"
                        class="btn btn-primary mr-2"
                        type="button"
                        [ngClass]="{'okOnly': okOnly, '': !okOnly}">{{okOnly ? "Ok" :"Ja"}}</button>
                        <button (click)="onNo()" *ngIf="!okOnly" class="btn btn-danger" type="button">Nee</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
    </div>
</div>
