import { ChangeDetectorRef, Injectable, signal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IPageInfo } from "@iharbeck/ngx-virtual-scroller";
import { BulkHelper, EVENTS, IBulkDeletedResourcesEvent, IBulkFindResourcesByUrisQuery, IBulkMovedResourceEvent, IBulkUploadEndedEvent, IBulkUploadStartedEvent, IEmailChangedEvent, IFindResourcesQuery, IFolderCreatedEvent, INewResourceUploadedEvent, IPublicationLinkedEvent, IQuery, IRenamedEvent, IResourceDeletedEvent, IResourceMovedEvent, IResourceUpdatedEvent, OPERATORS, QUERIES, RESOURCE_KIND } from "prunus-common/dist";
import { DEFAULT_PAGE_SIZE } from "prunus-common/dist/constants/DEFAULT_PAGE_SIZE";
import { Subject } from "rxjs/internal/Subject";
import { v4 as uuid } from 'uuid';
import { email2MyFilesPath, MY_FILES } from "../../app/constants/MY_FILES";
import { TokenManagerService } from "./token-manager.service";
import { formatMsg, MESSAGES } from "../../MESSAGES";
import { PARAMS } from "../../params";
import { PUBLICATIONS } from "../../PUBLICATIONS";
import { IResource } from "../../resource/interface/IResource";
import { USER } from "../../user/USER";
import { CleanupService } from "../CleanupService";
import { LoggingsService } from "../LoggingsService";
import { FileSystemService } from "./FileSystemService";
import { ServerSocketService } from "./server-socket.service";
import { UniversalQueryService } from "./universal-query.service";
import { ErrorHandlingService } from "../ErrorHandlingService";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { UploaderService } from "src/app/upload-queue/UploaderService";
import { NotificationService } from "src/infrastructure/services/notification-service";
import { DIALOG_OPEN_OPTIONS } from "../DialogOptions";
import { BatchUploadCompleteModalComponent } from "src/app/batch-upload-complete-modal/batch-upload-complete-modal.component";
import { releaseWakeLock } from "../wakeLock";

@Injectable({
	providedIn: "root"
})
export abstract class AbstractDataSourceService {
    changeDetectorRef: ChangeDetectorRef
    // publics direct used in html components
    public isLoadingSignal = signal(false)
    public resources: IResource[] = [];
    searchTerms: string[] = [];
    or = true;
    allResourcesLoaded = false;
    pageSize = 35;//DEFAULT_PAGE_SIZE;
    marge = DEFAULT_PAGE_SIZE;
    from = 0;
    loadingPromise: Promise<any>;
    resource: IResource;
    rootPath: string;
    CleanupService = new CleanupService(AbstractDataSourceService.name + uuid());
    newTmpNotYetProcessedResource$ = new Subject<IResource>();
    dataCleared$ = new Subject<void>();
    moreDataFetched$ = new Subject<void>();
    dataChanged$ = new Subject<void>();

    constructor(
        protected log: LoggingsService, 
        protected fileSystemService: FileSystemService,
        protected serverSocketService: ServerSocketService,
        protected activatedRoute: ActivatedRoute,
        protected universalQueryService: UniversalQueryService,
        protected noticationService: NotificationService,
        protected tokenManagerService: TokenManagerService,
        protected errorHandlingService: ErrorHandlingService,
        protected modalService: BsModalService,
        protected uploaderService: UploaderService,
        ) {
        this.rootPath = PARAMS.ROOT_FOLDER;
    }

    abstract buildQuery(): IQuery;

    abstract onQueryResults(results: IResource[], resolve: Function, reject: Function);

    abstract get isAddingNewFolder(): boolean;

    abstract set isAddingNewFolder(value: boolean);

    abstract get parentPublicationIds(): number[];    

    abstract hideDetailIfPresent(): void;

    abstract snapshotCurrentFilters(): string;

    onQueryStringChanged(params: any): void {
      this.initFilters();
      this.searchTerms = params.tags ?  decodeURIComponent(params.tags).split(',') : [];
      this.or = !!(params[OPERATORS.OR] === undefined || params[OPERATORS.OR] === 'true');
    }

    initFilters() {
      this.searchTerms = [];
      this.or = true;
    }

    init(): void {
      this.fileSystemService.baseUrlChange$.next();
      this.CleanupService.addSubscription(  
        this.activatedRoute.queryParams.subscribe(async (params: any) => {
          this.log.debug(`AbstractDataSourceService.queryParams.subscribe`);
          const oldFiltersSnaphot = this.snapshotCurrentFilters();
          await this.loadingPromise;
          this.onQueryStringChanged(params);
          this.fileSystemService.baseUrlChange$.next();
          if (this.snapshotCurrentFilters() !== oldFiltersSnaphot) {
            this.clearData();
          }
        })
      );
      this.CleanupService.addSubscription(this.serverSocketService.subscribe2ServerEvents().subscribe(
        async (evt) => await this.onServerEvent(evt)
      ));  
    } 

    destroy(): void {
      this.clearData();
      this.CleanupService.cleanupSubscriptions();
    }

    clearData(): void {
        this.isLoadingSignal.set(false)
        this.allResourcesLoaded = false
        this.from = 0
        this.resources = []
        this.loadingPromise = undefined
        this.dataCleared$.next()
    }

    get(uri: string): IResource {
        return this.resources.find(r => r.uri === uri);
    }

    fetchMore(pageInfo: IPageInfo = { 
      startIndex: 0, 
      endIndex: DEFAULT_PAGE_SIZE, 
      scrollStartPosition: 0,
      scrollEndPosition: 0,
      startIndexWithBuffer: 0,
      endIndexWithBuffer: 0,
      maxScrollPosition: 0
    }) { 
      if (this.isLoadingSignal() || !pageInfo ) {

        return;
      }
      
      if (this.allResourcesLoaded) {
        this.isLoadingSignal.set(false)
       
        return;
      }

      if (this.from > (pageInfo.startIndex + pageInfo.endIndex + this.marge)) {
        this.isLoadingSignal.set(false)

        return
      }
      this.isLoadingSignal.set(true)
      this.fetchNextChunk().then(chunk => {
        if (chunk.length < this.pageSize) {
          this.allResourcesLoaded = true;
        }
        for(const resourceInChunk of chunk) {
          const idx = this.resources.findIndex(r => r.uri === resourceInChunk.uri);
          if (idx === -1) {
            this.resources.push(resourceInChunk);
          } else {
            this.resources[idx] = resourceInChunk;
          }
        }
        this.log.debug(`from ${this.resources.length - chunk.length} to ${this.resources.length} fetched # rows: ${chunk.length}`);
        this.isLoadingSignal.set(false)
        this.moreDataFetched$.next();
      }, (e) => {
        this.isLoadingSignal.set(false)
        this.log.debug(e);
      });

    }

    remove(resource: IResource) {
        const index = this.resources.findIndex(r => r.uri === resource.uri);
        if (index !== -1) {
          this.resources.splice(index, 1);
        } 
    }

    set(resource: IResource) {
        const index = this.resources.findIndex(r => r.uri === resource.uri);
        if (index !== -1) {
          this.resources[index] = resource;
        } else {
          this.resources.unshift(resource);
        }
    }

    fetchNextChunk(): Promise<IResource[]> {
        return new Promise((resolve, reject) => {
          if (this.allResourcesLoaded) {
            resolve([]);
            
            return;
          }
          
          this.execQuery(resolve, reject); 
        });
    }

    execQuery(resolve, reject) {
      const q = this.buildQuery();
      this.from += this.pageSize;
      if (this.allResourcesLoaded) {
        resolve();
        return;
      }
      this.log.debug('query:', q);
      const subscription =
      this.universalQueryService.query(q).subscribe((results: IResource[]) => {
        if (results.length < this.pageSize) {
          this.allResourcesLoaded = true;
        }
        this.onQueryResults(results, resolve, reject);
        subscription.unsubscribe();
      });
    }

    isResourceInOurPath(resourceOrPath: IResource | string): boolean {
      if (typeof resourceOrPath !== "string") {
        return resourceOrPath.path === this.fileSystemService.path;
      }
      
      return resourceOrPath === this.fileSystemService.path;
    }

    async onServerEvent(evt: any)  {
      const SRV_MSG = MESSAGES.SRV_MSG_TOAST_TITLE;
      this.log.debug(`onServerEvent received : ${JSON.stringify(evt)}`);
      if ([EVENTS.ExportProgressEvent, EVENTS.ResourceExportedEvent].includes(evt.eventType)) {
        return;
      }
      const didWeTriggerEvent = (USER && (evt['userInfo'] && (evt['userInfo'].email === USER.email)));
      let index = this.resources.findIndex(r => evt.resource && (r.uri === evt.resource.uri));

      const expected_not_found_evts = [
        EVENTS.NewUploadedEvent,
        EVENTS.FolderCreatedEvent,
        EVENTS.BulkDeletedResourcesEvent,
        EVENTS.ResourceMovedEvent,
        EVENTS.BulkMovedResourceEvent,
        EVENTS.BulkUploadEndedEvent,
        EVENTS.BulkUploadStartedEvent,
        "RekognitionCompletedEvent"
      ];
      if (index === -1 && expected_not_found_evts.indexOf(evt.eventType) === -1) {
       this.log.warn(`Could not find resource by uri ${evt.resource ? evt.resource.uri : evt.uri || evt.uid} in resources member field. eventType ${evt.eventType}`);
  
       return;
      }

      let isFilesView = true;
      if (location.href.toLocaleLowerCase().indexOf('tiles') !== -1) {
          isFilesView = false;
      }

      if (!isFilesView) {
          // tiles view doesn't care bout folders
          if (this.resources[index] && (this.resources[index].kind === RESOURCE_KIND.FOLDER)) {
              return;
          }
      }

      switch (evt.eventType) {
        case EVENTS.BulkDeletedResourcesEvent: {
          const iBulkDeletedResourcesEvent: IBulkDeletedResourcesEvent = evt as IBulkDeletedResourcesEvent;
          const names: string[] = [];
          let isInOurPath = false;
          for (const uri of iBulkDeletedResourcesEvent.uris) {
            index = this.resources.findIndex(r => r.uri === uri);
            if (index !== -1) {
              if (this.isResourceInOurPath(this.resources[index].path)) {
                names.push(this.resources[index].name);
                isInOurPath = true;
              } 
              this.resources.splice(index, 1);
            }   
            
          }
          if (didWeTriggerEvent && names.length) {
            this.noticationService.success(
              formatMsg(MESSAGES.DELETE_ITEM_SUCCESS, {name: names.map(rn => `'${rn}'`).join(", ")}), SRV_MSG);
          }
          this.hideDetailIfPresent();
          break;
        }
        case EVENTS.BulkMovedResourceEvent: {
          const bulkResourcesMovedEvent = evt as IBulkMovedResourceEvent;
          let resourceOnScreen = false;
          if (this.isResourceInOurPath(bulkResourcesMovedEvent.targetPath)) { // we are the target folder
            resourceOnScreen = true;
            const bh = new BulkHelper();
            const batches = bh.splitIntoBatches(bulkResourcesMovedEvent.sourceUris);
            for(const batch of batches) {
              const q: IBulkFindResourcesByUrisQuery = {
                queryType: QUERIES.BulkFindResourcesByUrisQuery,
                uris: batch
              };
              this.CleanupService.addSubscription(
                this.universalQueryService.query(q).subscribe((results: IResource[]) => {
                  this.log.debug("results", results)
                  for(const r of results) {
                    index = this.resources.findIndex(res => res.uri === r.uri);
                    if (index !== -1) {
                      this.resources.splice(index, 1);
                    }   
                    r['isNotYetProcessedByServer'] = false;
                    r['isNew'] = true;
                    this.resources.unshift(r)
                  }
                  this.resources = [...this.resources]
                }, (e) => { this.log.error(e)})
              );
            }
          } else {
            // if we have the moved resources they should be removed from the current view
            for(const uri of bulkResourcesMovedEvent.sourceUris) {
              const idx = this.resources.findIndex(r => r.uri === uri);
              if (idx !== -1) {
                this.log.debug(`moved away ${this.resources[idx].name}, uri: ${this.resources[idx].uri}`);
                resourceOnScreen = true;
                this.resources.splice(idx, 1);
              }
            }
            this.resources = [...this.resources]
            this.dataChanged$.next();
          }

          if (didWeTriggerEvent && resourceOnScreen) {
            let destination = bulkResourcesMovedEvent.targetPath;
            if (USER.isTeacher) {
              destination = email2MyFilesPath(destination)
            }
            this.noticationService.success(
              formatMsg(MESSAGES.MOVE_SUCCESS, {name: bulkResourcesMovedEvent.resourceNames.map(rn => `'${rn}'`).join(", "), destination }), SRV_MSG
            );
          }
          
          break;
        }
        case EVENTS.DeletedResourceEvent: {
          const iResourceDeletedEvent: IResourceDeletedEvent = evt as IResourceDeletedEvent
          this.resources.splice(index, 1)
          this.resources = [... this.resources]
          this.hideDetailIfPresent();
          if (didWeTriggerEvent && iResourceDeletedEvent.resource && this.isResourceInOurPath(iResourceDeletedEvent.resource.path)) {
            this.noticationService.success(
              formatMsg(MESSAGES.DELETE_ITEM_SUCCESS, {name: iResourceDeletedEvent.resource.name}), SRV_MSG);
          }
          break;
        }
        case EVENTS.FolderCreatedEvent: {
          // use the string not the type, otherwise IOC cyclic dep 
          if (this.constructor.name === 'TilesDataSourceService') {
            break;
          }
          const iFolderCreatedEvent: IFolderCreatedEvent = evt as IFolderCreatedEvent;
          if (index !== -1) {
            if (evt['userInfo'] && (evt['userInfo'].email === USER.email)) {
              if (iFolderCreatedEvent.resource.isTeachersRootFolder) {
                this.resources[index].isNew = false;
                break; // mijn bestanden folder
              }
              this.noticationService.warning(
                formatMsg(MESSAGES.FOLDER_ALREADY_EXISTS, {name: evt.resource.name}), MESSAGES.SRV_MSG_TOAST_TITLE
              );
              break;
            }
            // ignore if we have the resource already, sometimes we receive this event multiple times ...
            break;
          }
          
          if (iFolderCreatedEvent.resource.isOwnedByTeacher) {
            if (USER.isAdmin || iFolderCreatedEvent.resource.user_id !== USER.id) {
              // not of our business
              return;
            }
          }
          if (!this.isResourceInOurPath(iFolderCreatedEvent.resource)) {
            break;
          }
          this.resources.unshift(evt.resource);
          this.resources = [...this.resources]
          this.isAddingNewFolder = false;
          const idx = this.resources.findIndex(r => r.uri === iFolderCreatedEvent.resource.uri);
          if (idx !== -1) {
            this.resources[idx].isNew = true
            this.resources[idx] = { ... this.resources[idx] }
          }
          if (didWeTriggerEvent) {
            if (this.resources[idx].isTeachersRootFolder) {
              // check to see folder already existed. the event can be triggered while the resources are not yet loaded, 
              // hence index will be -1. but is already existed on the server and the enduser would get a popup. 
              // fix: check creation date of the teachers root folder if was created less than a minute a go it must me new
              const diffInMs = new Date().getTime() - new Date(this.resources[idx].created_at).getTime();
              if (diffInMs < 1000 * 60) {
                this.noticationService.success(
                  formatMsg(MESSAGES.TEACHER_MYFILES_CREATED, {MY_FILES})
                );
              } else {
                this.resources[idx].isNew = false;
              }
            } else {
              this.noticationService.success(
                formatMsg(MESSAGES.NEW_FOLDER_CREATED, { name: iFolderCreatedEvent.name}), MESSAGES.ON_SRV_CREATED_TITLE
              );
            }
          }
          break;
        }
        case EVENTS.RenamedEvent: {
          const iRenamedEvent: IRenamedEvent = evt as IRenamedEvent
          if (index !== -1) {
            this.resources[index].name = iRenamedEvent.name
            this.resources[index].isNotYetProcessedByServer = false
            this.resources[index] = { ... this.resources[index] }
          }
          if (didWeTriggerEvent) {
            this.noticationService.success(
              formatMsg(MESSAGES.RENAME_ITEM_SUCCESS, { name: iRenamedEvent.resource.name}), SRV_MSG
            );
          }
          break;
        }
        case EVENTS.ResourceMovedEvent: {
          const iResourceMovedEvent: IResourceMovedEvent = evt as IResourceMovedEvent;
          let resourceOnScreen = index !== -1;
          if (index !== -1) {
              this.resources.splice(index, 1);
          } else {
            if (this.isResourceInOurPath(iResourceMovedEvent.resource)) {
              iResourceMovedEvent.resource['isNotYetProcessedByServer'] = false;
              iResourceMovedEvent.resource['isNew'] = true;
              this.resources.unshift(iResourceMovedEvent.resource)
              this.resources = [ ... this.resources]
              resourceOnScreen = true;
            }
          }
          if (didWeTriggerEvent && resourceOnScreen) {
            let destination = iResourceMovedEvent.resource.path;
            if (USER.isTeacher) {
              destination = email2MyFilesPath(destination)
            }
            this.noticationService.success(
              formatMsg(MESSAGES.MOVE_SUCCESS, {name: iResourceMovedEvent.resource.name, destination}), SRV_MSG
            );
          }
          this.hideDetailIfPresent();
          break;
        }  
        case EVENTS.NewUploadedEvent: {
          const iNewResourceUploadedEvent: INewResourceUploadedEvent = evt as INewResourceUploadedEvent;
          if (iNewResourceUploadedEvent.resource.isOwnedByTeacher) {
            if ((USER.isAdmin && !USER.isTeacher) || // for the rare case that a user is teacher and admin
              iNewResourceUploadedEvent.resource.user_id !== USER.id ) { // eigendom van andere teacher
              // not of the current users' business
              return;
            }
          }
          const msg = `${evt.resourceExistedAlready ? 'Update' : 'Opladen nieuwe bestand(en)'} geslaagd: '${iNewResourceUploadedEvent.resource.name}'.`;
          if (this.isResourceInOurPath(iNewResourceUploadedEvent.resource)) {
            // a teacher should not see resources not owned by him or comming from publications he is isn't licensed for
            if (USER.isTeacher && 
              iNewResourceUploadedEvent.resource.user_id !== USER.id) {
              if (iNewResourceUploadedEvent.resource.isOwnedByTeacher) {
                  return; // owned by other teacher => dada
              }

              const pub_ids = PUBLICATIONS ? PUBLICATIONS.map(p => p.id) : [];
              if (!iNewResourceUploadedEvent.resource.isOwnedByTeacher &&
                  // array intersection for checking license
                  (iNewResourceUploadedEvent.resource.publication_ids &&
                    !iNewResourceUploadedEvent.resource.publication_ids?.filter(value => pub_ids.includes(value)).length)
              ) {
                  return; // not licensed
              }
            }  
            let idx = this.resources.findIndex(r =>
              r.uri === iNewResourceUploadedEvent.resource.uri ||
              // uri can be changed by server, in case the img contains an xmp tag with preexisting uri
              // so we have to look by name in the same folder
              (r.name === iNewResourceUploadedEvent.resource.name /*&& r.isNotYetProcessedByServer*/));
            if (idx === -1) {
              // upload by other user in this folder, add it
              this.resources.unshift(iNewResourceUploadedEvent.resource);
              idx = 0;
            } 
            const currentResource = this.resources[idx];
            if (!currentResource.uri || ((currentResource.uri !== iNewResourceUploadedEvent.uri) && iNewResourceUploadedEvent.resourceExistedAlready)) {
              currentResource.uri = iNewResourceUploadedEvent.uri;
            }
            currentResource.isNotYetProcessedByServer = false;
            if (this.parentPublicationIds && this.parentPublicationIds.length) {
              currentResource.publication_ids = this.parentPublicationIds;
            }
            currentResource.isOwnedByTeacher = iNewResourceUploadedEvent.resource.isOwnedByTeacher;
            currentResource.user_id = iNewResourceUploadedEvent.resource.user_id;
            currentResource.isNew = true
            currentResource.version = iNewResourceUploadedEvent.resource.version || 0
            currentResource.created_at = iNewResourceUploadedEvent.resource.created_at
            currentResource.last_modified_at = iNewResourceUploadedEvent.resource.last_modified_at
            currentResource.signature = iNewResourceUploadedEvent.resource.signature;
            currentResource.thumbnail = undefined;
            currentResource.tags = iNewResourceUploadedEvent.tags;
            currentResource.fileSize = iNewResourceUploadedEvent.resource.fileSize;
            if (iNewResourceUploadedEvent.resource.pageCount) {
              currentResource.pageCount = iNewResourceUploadedEvent.resource.pageCount;
            }

            if (currentResource.processedByServer$) {
              currentResource.processedByServer$.next(currentResource);
            }

            if (didWeTriggerEvent) {
              this.noticationService.success(
                msg, SRV_MSG
              );
            }
          } 
          
          break;
        }
        case EVENTS.PublicationLinkedEvent: {
          this.resources[index].publication_ids =
            (evt as IPublicationLinkedEvent).publication_ids;
          break;
        }
        case EVENTS.UpdatedResourceEvent: {
          const resourceUpdatedEvent = (evt as IResourceUpdatedEvent);
          this.resources[index].name = resourceUpdatedEvent.name;
          this.resources[index].tags = resourceUpdatedEvent.tags;
          this.resources[index] = { ... this.resources[index] }
          break;
        }
        case EVENTS.EmailChangedEvent: {
          const emailChangedEvent = evt as IEmailChangedEvent
          let didChange = false
          for(const r of this.resources) {
            if (r.isTeachersRootFolder && r.name === emailChangedEvent.old_value) {
              r.oldMail = emailChangedEvent.old_value
              r.name =  emailChangedEvent.new_value  
              didChange = true            
            } 
            if (r.isTeachersRootFolder && r.name === emailChangedEvent.new_value) {
              r.oldMail = emailChangedEvent.old_value
              didChange = true
            }
          }
          if (didChange)  {
            this.resources = [...this.resources]
          }
          break;
        }
        case EVENTS.BulkUploadEndedEvent: {
          this.noticationService.success(MESSAGES.BULK_UPLOAD_SUCCESS)
          await releaseWakeLock()
          const bulkUploadEndedEvent = evt as IBulkUploadEndedEvent
          if (this.uploaderService.bulkUid === bulkUploadEndedEvent.bulkUid) {
            this.uploaderService.isBulkUploadProcessingOnServerSignal.set(false)
          }
          if (USER.isAdmin) {
            this.modalService.show(BatchUploadCompleteModalComponent, DIALOG_OPEN_OPTIONS)
          }
          break
        }
        case EVENTS.BulkUploadStartedEvent: {
          const bulkUploadStartedEvent = evt as IBulkUploadStartedEvent
          if (this.uploaderService.bulkUid === bulkUploadStartedEvent.bulkUid) {
            this.uploaderService.isBulkUploadProcessingOnServerSignal.set(true)
          }
          break
        }
        case "RekognitionCompletedEvent": {
          const matchingResource = this.resources.find(r => r.uri === evt["uri"])
          if (matchingResource) {
            if (!matchingResource.tags) {
              matchingResource.tags = []
            }
            matchingResource.tags = [...matchingResource.tags, ...evt["tags"]]
          }
          break
        }
        default: {
          this.log.debug(`unhandled event type ${evt.eventType}, ignore ....`)
        }
      }

      this.changeDetectorRef.markForCheck()
    }

}