export class ErrorInfo {
  uri: string
  message: string
  stack: string
  name: string
  context: any
  code: string
  date: Date

  constructor (messageOrError: string | Error) {
    const now = new Date()
    now.setHours(now.getHours() + 1); // 1 hour UTC diff
    this.date = now

    if (typeof messageOrError === 'string') {
      this.message = messageOrError as string
    } else {
      const error: Error = messageOrError as Error
      this.message = error.message;
      this.stack = error.stack;
      this.name = error.name;
    }
  }

  toString (): string {
    return `message: ${this.message} stack: ${this.stack} `
  }

}
