import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, OnDestroy, OnInit, signal, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { NgSelectComponent } from '@ng-select/ng-select'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { COMMANDS, CONSTRAINTS, DEFAULT_PAGE_SIZE, DOCUMENT_EXTENSIONS, EVENTS, IDeleteResourceCommand, IFindResourceByUriQuery, IFolderInfoQuery, IFolderInfoQueryResult, INewResourceUploadedEvent, ISuggestTagsQuery, IUpdateResourceCommand, QUERIES, RESOURCE_KIND, isDocumentExtension, isImageExtension, isVideoExtension } from 'prunus-common/dist'
import { ROLES } from 'redwood-model/dist'
import { Subject } from 'rxjs/internal/Subject'
import { Subscription } from 'rxjs/internal/Subscription'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { v4 as uuid } from 'uuid'
import { MESSAGES, formatMsg } from '../../MESSAGES'
import { CleanupService } from '../../infrastructure/CleanupService'
import { DIALOG_OPEN_OPTIONS } from '../../infrastructure/DialogOptions'
import { ErrorHandlingService } from '../../infrastructure/ErrorHandlingService'
import { ErrorInfo } from '../../infrastructure/ErrorInfo'
import { LoggingsService } from '../../infrastructure/LoggingsService'
import { uniqueValues } from '../../infrastructure/UniqueValues'
import { ConfirmDialogComponent } from '../../infrastructure/confirm-dialog/confirm-dialog.component'
import { UniversalCommandService } from '../../infrastructure/cqrs/universal-command.service'
import { ServerSocketService } from '../../infrastructure/services/server-socket.service'
import { UniversalQueryService } from '../../infrastructure/services/universal-query.service'
import { PARAMS } from '../../params'
import { IResource } from '../../resource/interface/IResource'
import { USER } from '../../user/USER'
import { User } from '../../user/model/User'
import { AWSCredentialService } from '../../infrastructure/services/AWSCredentialService'
import { email2MyFilesPath, MY_FILES } from '../constants/MY_FILES'
import { TAG_REGEX } from '../constants/TAG_REGEX'
import { ExplorerDataSourceService } from '../explorer-view/explorer-data-source-service'
import { ExplorerMultiSelectService } from '../explorer-view/explorer-multi-select.service'
import { RecentTagsService } from '../../infrastructure/services/recent-tags.service'
import { NotificationService } from '../../infrastructure/services/notification-service'
import { PRESIGNER_CACHE, s3urlpresigner } from 'src/infrastructure/services/s3urlpresigner'
const { getSignedUrl } = require("@aws-sdk/s3-request-presigner")

@Component({
  selector: 'app-detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: [ './detail-view.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailViewComponent implements OnInit, OnDestroy {
  private _imageSrc: string
  private storedResource: any
  private cleanupService = new CleanupService(DetailViewComponent.name)
  MAX_LENGTH = CONSTRAINTS.MAX_RESOURCE_NAME_LENGTH
  suggestions$ = new Subject<string[]>()
  suggestionsInput$ = new Subject<string>()
  suggestionsLoading = false
  searchTerms: string[] = []
  externalUsages: { name: string, uid: string, url: string, app: string, owner: string }[] = []
  editName = false
  _resource: IResource
  uri: string
  tags: string[] = []
  serverEvtSubscription: Subscription
  canViewTechicalInfo = false
  path: string
  backUrl: string
  user: User
  routeSubscription: Subscription
  folderInfo: IFolderInfoQueryResult
  mapleUiUrl: string
  currentSuggestions: string [] = []
  public MAX_SELECTED_ITEMS = CONSTRAINTS.MAX_NR_OF_USER_TAGS
  @ViewChild(NgSelectComponent, {static: false}) ngSelect: NgSelectComponent
  hasNameError = false
  showPreview = false
  s3OrigLink: string
  s3MasterLink: string
  videoUrlSignal = signal(undefined)
  videoUrl = ""

  constructor (
    public bsModalRef: BsModalRef,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private modalService: BsModalService,
    private explorerDataSourceService: ExplorerDataSourceService,
    private universalCommandService: UniversalCommandService,
    private universalQueryService: UniversalQueryService,
    private serverSocketService: ServerSocketService,
    private explorerMultiSelectService: ExplorerMultiSelectService,
    private log: LoggingsService,
    private changeDetectorRef: ChangeDetectorRef,
    private recentTagsService: RecentTagsService,
    private errorHandlingService: ErrorHandlingService,
    private awsCredentialService: AWSCredentialService
  ) {
    effect(() => {
      const value = this.videoUrlSignal()
      if (value) { 
        this.videoUrl = value
        changeDetectorRef.markForCheck()
      }
    })
  }

  cloneResource() {
    this._resource = { ...this._resource }
  }

  get isInExplorer(): boolean {
    return location.pathname.indexOf("explorer-detail") !== -1
  }

  get resource(): IResource {
    return this._resource
  }

  set resource(value: IResource) {
    this._resource = value
    this.cloneResource()
    if (value) {
      this.uri = value.uri
      this.tags = value.tags ? [...value.tags] : []
      this.videoUrlSignal.set(value["videoUrl"])
      this.snapshotResource()
      if (value.processedByServer$) {
        this.cleanupService.addSubscription(value.processedByServer$.subscribe(async (r) => {
          this._resource = r
          this.cloneResource()
          await this.calculateImgSrcAndS3SignUrl()
        }))
      }
      if (!value.signature) {
        this.loadResourceByUri(this.uri)
      } else {
        (async () => {await this.calculateImgSrcAndS3SignUrl()})()
      }

      this.setExternalUsage()

      if (this._resource.kind === 'FOLDER') {
        this.fetchFolderInfo()
      } else {
        this.changeDetectorRef.markForCheck()
      }

    }
  }

  fetchFolderInfo() {
    if (this.folderInfo) {

      return
    }
    let path = this._resource.path + '/'
    path += (USER.isTeacher && this._resource.name === MY_FILES) ? USER.email : this._resource.name
    const iFolderInfoQuery: IFolderInfoQuery = {
      queryType: QUERIES.FolderInfoQuery,
      path
    }
    const qFolderInfoQuerySubscription = this.universalQueryService
      .query(iFolderInfoQuery).subscribe((folderInfo: IFolderInfoQueryResult) => {

        this.folderInfo = folderInfo
        this.changeDetectorRef.markForCheck()

        if (qFolderInfoQuerySubscription) {
          qFolderInfoQuerySubscription.unsubscribe()
        }
      })
  }

  ngOnInit () {
    this.cleanupService.cleanupSubscriptions()
    this.user = USER
    this.canViewTechicalInfo = true

    if (this.showTechInfoInUI) {
      this.canViewTechicalInfo = this.user.hasRoles(ROLES.ALL_INTERNAL_USERS)
    } else {
      this.canViewTechicalInfo = false
    }

    // TODO: investigate does not subscribe4ServerEvents with cleanupService
    // the subscription is not called :-( => very strange because it works in FileSystemComponent
    this.serverEvtSubscription =
    this.serverSocketService.subscribe2ServerEvents().subscribe(
      async (evt) => await this.onServerEvent(evt)
    )
    if (!this.routeSubscription) {
      this.routeSubscription =
        this.route.params.subscribe(p => {
          if (p.uri) {
            this.uri = p.uri
            this.loadResourceByUri(this.uri)
          }
        })
    }
    this.cleanupService.addSubscription(this.routeSubscription)
    this.cleanupService.addSubscription(
      this.route.queryParams.subscribe((params: any) => {
        this.path = params.path
        this.backUrl = params.backUrl
      })
    )

    this.cleanupService.addSubscription(
      this.suggestionsInput$.pipe(
        distinctUntilChanged(),
        debounceTime(500)
      ).subscribe((term) => {
        const iSuggestTagsQuery: ISuggestTagsQuery = {
          from: 0,
          size: DEFAULT_PAGE_SIZE,
          queryType: QUERIES.SuggestTagsQuery,
          term
        }
        const subscription =
          this.universalQueryService.query(iSuggestTagsQuery).subscribe((results: string[]) => {
            const recents = this.recentTagsService.findRecent(term)
            this.suggestions$.next(uniqueValues([...recents, ...results]).sort())
            for(const r of results) {
              if (!this.currentSuggestions.includes(r)) {
                this.currentSuggestions.push(r)
              }
            }
            subscription.unsubscribe()
          })
      })
    )
    this.suggestionsInput$.next('')

    this.cleanupService.addSubscription(
      this.errorHandlingService.errors$.subscribe(e => this.onError(e))
    )
  }

  onError(e: ErrorInfo): void {
    if(e.uri || e['resource'] ) {
      const uri = e.uri || (e['resource'] ? e['resource'].uri : undefined)
      if (this._resource && this._resource.uri === uri) {
        this.notificationService.error(e.message)
        this.changeDetectorRef.markForCheck()
      }
    }
  }

  loadResourceByUri(uri: string) {
    const iFindResourceByUriQuery: IFindResourceByUriQuery = {
      queryType: QUERIES.FindResourceByUriQuery,
      uri
    }
    const subscription = this.universalQueryService.query(iFindResourceByUriQuery).subscribe(
      async (r: IResource) => {
        if (subscription) {
          subscription.unsubscribe()
        }
        if (!r) { return }
        this.folderInfo = undefined
        this._resource = r
        this.cloneResource()
        await this.calculateImgSrcAndS3SignUrl()
        this.explorerMultiSelectService.deselectAll()
        this.explorerMultiSelectService.select(this._resource)
        this.tags = [...r.tags]
        this.snapshotResource()
        this.externalUsages = []
        if (r.kind === RESOURCE_KIND.FOLDER) {
          this.fetchFolderInfo()
        }
        this.setExternalUsage()
      })
  }

  setExternalUsage() {
    if (this._resource.externalUse) {
      this.externalUsages = this._resource.externalUse.map(r => {
        return {
          app: r.external_app,
          name: r.external_resource_name,
          uid: r.external_resource_uid,
          url: PARAMS['MAPLE'] + '#/seed/' + r.external_resource_slug,
          owner: r.resource_owner.email
        }
      })
    }
  }

  get s3Bucket(): string {
    return location.hostname.includes("e-ducate.me") ? "prunus-orig-master": "prunus-test-orig-master"
  }

  get loading () {
    return ! this._resource
  }

  get imageSrc (): string {
    return this._imageSrc
  }

  get isImage() : boolean {
    if (this.loading) {

      return true
    }

    if (this._resource.kind === RESOURCE_KIND.FOLDER) {

      return false
    }

    return isImageExtension(this._resource.extension)
  }

  get isVideo() : boolean {
    if (this.loading) {

      return true
    }

    if (this._resource.kind === RESOURCE_KIND.FOLDER) {

      return false
    }

    return isVideoExtension(this._resource.extension)
  }

  get videoType(): string {
    return `video/${this.resource?.extension}`
  }

  async calculateVideoUrl(): Promise<void> {
      try {
        if (this.videoUrl) return
        const Key = `${this.resource.uri}.orig`
        const signedUrl = await s3urlpresigner(Key, this.s3Bucket, this.resource, this.awsCredentialService)
        if (signedUrl) {
            this.videoUrlSignal.set(signedUrl)
        }
      } catch(e) {
        this.log.error("getVideoUrl", e)
        return Promise.reject(e)
      }
  }

  get isPDF() : boolean {
    if (this.loading) {

      return true
    }

    if (this._resource.kind === RESOURCE_KIND.FOLDER) {

      return false
    }

    return this._resource?.extension?.toLowerCase() === "pdf"
  }

  get isSVG() : boolean {
    if (this.loading) {

      return true
    }

    if (this._resource.kind === RESOURCE_KIND.FOLDER) {

      return false
    }

    return this._resource?.extension?.toLowerCase() === "svg"
  }

  get location(): string {
    if (USER.isTeacher && this._resource.path.indexOf(USER.email) !== -1) {
      return email2MyFilesPath(this._resource.path)
    }

    return this._resource.path
  }

  get isOfficeDoc() : boolean {
    if (this.loading) {

      return true
    }

    if (this._resource.kind === RESOURCE_KIND.FOLDER) {

      return false
    }

    return this._resource?.extension?.toLowerCase() !== "pdf" && isDocumentExtension(this._resource?.extension?.toLowerCase())
  }

  get showTechInfoInUI(): boolean {
    return PARAMS['SHOW_TECHINFO_IN_UI']
  }

  async calculateImgSrcAndS3SignUrl() {
    if (this._resource) {
      if (this.isVideo) {
        await this.calculateVideoUrl()
        return 
      }
      if (!isImageExtension(this._resource.extension)) {
        this._imageSrc = this._resource.thumbnail
      }
      if(this._resource.signature) {
        this._imageSrc = `${PARAMS["PRUNUS-IMG-SERVER"]}/img/${this.uri}?signedUri=${encodeURIComponent(this._resource.signature.signedUri)}&signedTimestamp=${this._resource.signature.signedTimestamp}&width=768&height=400&version=${this._resource ? this._resource.version : 1}&cache_burst=${this._resource['cache_burst'] || ''}`
      }
      
      if (!this.s3OrigLink) {
        this.presign(this._resource.uri, this.s3Bucket).then(url => {
          this.s3OrigLink = url
          if (this.isPDF) {
            this.s3MasterLink = url
          }
        })
        if (!this.isPDF) {
          this.presign(`${this._resource.uri}.master`, this.s3Bucket).then(url => this.s3MasterLink = url)
        }
      }
    }
  }

  get filename (): string {
    if (this.loading) {
      return ''
    }

    return this._resource.name
  }

  set filename (value: string) {
    if (this._resource && (this.resource.path + "/" + value).length > CONSTRAINTS.MAX_PATH_LENGTH) {
      this.hasNameError = true
      this.notificationService.error(formatMsg(MESSAGES.STRUCTURE_2_DEEP, {value, MAX_PATH_LENGTH: CONSTRAINTS.MAX_PATH_LENGTH}))
      this.changeDetectorRef.markForCheck()
      return
    }

    if (this._resource && (this.resource.name).length > CONSTRAINTS.MAX_RESOURCE_NAME_LENGTH) {
      this.hasNameError = true
      this.notificationService.error(
        formatMsg(MESSAGES.ITEM_NAME_2_LONG, {value, MAX_RESOURCE_NAME_LENGTH: CONSTRAINTS.MAX_RESOURCE_NAME_LENGTH})
      )
      this.changeDetectorRef.markForCheck()
      return
    }
    this._resource.name = value
    this.hasNameError = false
  }

  get isDirty (): boolean {
    const copy: IResource = { ...this._resource }
    delete copy.processedByServer$
    const stored_copy = { ...this.storedResource }
    delete stored_copy.processedByServer$
    return JSON.stringify(copy) !== JSON.stringify(stored_copy)
  }

  get canEdit(): boolean {
    if (!this.user) { return false }
    if (this.universalCommandService.readonlyMode) return false
    if (this.user.isAdmin) { return true }

    if (this.user.isTeacher) {
      return this._resource.isOwnedByTeacher && this._resource.user_id === this.user.id
    }

    return false
  }

  toggleEditName (): void {
    if (this.hasNameError) {
      return
    }
    this.editName = ! this.editName

    this.changeDetectorRef.markForCheck()
  }

  ngOnDestroy () {
    this.cleanupService.cleanupSubscriptions()
    if (this.serverEvtSubscription) {
      this.serverEvtSubscription.unsubscribe()
      this.serverEvtSubscription = null
    }
    if (this.routeSubscription) {
      this.routeSubscription = undefined
    }

    this.currentSuggestions = []
  }

  onSave () {
    const cmd: IUpdateResourceCommand = {
      uid: uuid(),
      commandType: COMMANDS.UpdateResourceCommand,
      uri: this._resource.uri,
      tags: this._resource.tags,
      name: this._resource.name,
    }
    this.universalCommandService.handle(cmd)
  }

  onDelete () {
    const ref: BsModalRef = this.modalService.show(ConfirmDialogComponent, DIALOG_OPEN_OPTIONS)
    const confirmDialog: ConfirmDialogComponent = ref.content
    confirmDialog.message = `Bent u zeker dat u deze afbeelding wenst te verwijderen ?`
    confirmDialog.onYesAction = () => {
      this.cleanupService.cleanupSubscriptions()
      const cmd: IDeleteResourceCommand = {
        uid: uuid(),
        commandType: COMMANDS.DeleteResourceCommand,
        uri: this._resource.uri,
        deleteMasterFromS3: true,
        fullPath: this._resource.path + "/" + this._resource.name
      }
      const subscr =
      this.universalCommandService.handle(cmd).subscribe(() => {
        this.notificationService.success(MESSAGES.SRV_RECEIVED_DEL_REQ)
        this.explorerDataSourceService.remove(this._resource)
        const url = location.pathname.toLowerCase()
        const detailStart = url.indexOf('/file/detail/')
        if (detailStart !== -1) {
          this.router.navigate(['/main/file'], { queryParams: { path: this._resource.path }})
        }
        subscr?.unsubscribe()
      })
    }
  }

  onOpen() {
    if (!this.suggestionsInput$) {
      return
    }
    this.suggestionsInput$.next('')
  }

  close (cancel = false) {
    // are we running in modal ?
    if (cancel) {
      this.restoreResource()
    }

    if (this.bsModalRef && this.bsModalRef.content) {
      this.bsModalRef.hide()

      return
    }
    const url = location.pathname.toLowerCase()
    const detailStart = url.indexOf('/file/detail/')
    if (detailStart !== -1) {
      let queryString = ''
      if (this.backUrl.indexOf('?') !== -1) {
        const parts = this.backUrl.split('?')
        if (parts.length > 1) {
          queryString = parts[1]
        }
      }
      this.router.navigateByUrl('/main/tile?' + queryString)
    } else if (this.backUrl) {
      this.router.navigateByUrl(this.backUrl.replace(location.origin, ""))
    }
  }

  restoreResource() {
    this._resource = this.storedResource
    const index = this.explorerDataSourceService.resources.findIndex(r => r.uri === this._resource.uri)
    if (index !== -1) {
      this.explorerDataSourceService.resources[index] = this._resource
    }
  }

  removesObservablesFromResource() {
    // JSON.string on obj with obs crashes ...
    if (this._resource.processedByServer$) {
      const cpy = { ... this._resource }
      cpy.processedByServer$.complete()
      delete cpy.processedByServer$

      return cpy
    }

    return this._resource
  }

  snapshotResource() {
    this.storedResource = JSON.parse(JSON.stringify(this.removesObservablesFromResource()))
  }

  onKeyPressName($event) {
    if ($event.key === '/') {
      return false
    }

    return true
  }

  changeTags($event: string[]) {
    this._resource.tags = $event
  }

  copy2Clipboard(id: string) {
    const copyText: any = document.getElementById(id)
    copyText.focus()
    copyText.select()
    this.copyTextToClipboard(copyText.value)
    this.notificationService.success(
      formatMsg(MESSAGES.CLIPBOARD_CPY, {value: copyText.value}))
  }

  fallbackCopyTextToClipboard(text) {
    try {
      document.execCommand('copy')
    } catch (err) {
      this.log.error('unable to copy to clipboard', err)
    }
  }

  copyTextToClipboard(text: string) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text)
      return
    }
    navigator.clipboard.writeText(text).then(() => {
      this.log.debug('Copying to clipboard was successful!')
    }, (err) => {
      this.log.error('Could not copy text: ', err)
    })
  }

  formatDateTime(ts: string) {
    if (!ts) {
      return ""
    }
    const splitted = ts.split(/[A-Z:\.\-]/)
    let hour = Number(splitted[3])
    hour += 2 // from UTC
    if (hour > 24) {
      hour -= 24
    }
    return `${splitted[2]}/${splitted[1]}/${splitted[0]} ${hour}:${splitted[4]}:${splitted[5]}`
  }

  async onServerEvent(evt: any)  {
    const newResource: IResource = evt.resource
    switch (evt.eventType) {
      case EVENTS.UpdatedResourceEvent: {
        this.notificationService.success(MESSAGES.ITEM_UPDATE_SUCCESS)
        if (newResource) {
          this._resource.name = newResource.name
          this._resource.tags = newResource.tags
          this.snapshotResource()
          this.cloneResource()
          this.tags = [...newResource.tags]
        }
        this.close()
        break
      }
      case EVENTS.DeletedResourceEvent: {
        if (evt.resource.uri === this._resource.uri) {
          this.notificationService.success(
            formatMsg(MESSAGES.DELETE_ITEM_SUCCESS, {name: this._resource.name})
          )
          const url = location.pathname.toLowerCase()
          const detailStart = url.indexOf('/file/detail/')
          if (detailStart !== -1) {
            this.router.navigate([`/main/file`], { queryParams: { path: this._resource.path }})
          } else {
            this.close()
          }
        }
        break
      }
      case EVENTS.RenamedEvent: {
        if (evt.resource.uri === this._resource.uri) {
          this._resource.name = evt.resource.name
          this.cloneResource()
        }
        break
      }
      case EVENTS.NewUploadedEvent: {
        if (this._resource) {
          const newUploadedEvent = evt as INewResourceUploadedEvent
          // don't update the thumbail for each upload.
          // it will put to much load on the aws lambda resulting in throtling exceptions
          // Thus only do it, if no other choice e.g. the file is a TIF, a browser can not render it.
          // So we need a server side conversion to jpg to create the thumb.
          // Also in case the resource already existed (in case of update of the image), the server side thumbnail should be used
          if ((newUploadedEvent.resource.uri === this._resource.uri ||
              (newUploadedEvent.resource?.name === this._resource.name && newUploadedEvent.resource?.path === this._resource.path ))
              && (newUploadedEvent.resourceExistedAlready)
              ) {
            if (newUploadedEvent.uri !== this._resource.uri) {
              console.log(`preexisting resource uri changed on client ${this._resource.uri} <= ${newUploadedEvent.uri}`)
              this._resource.uri = newUploadedEvent.uri
            }
            this._resource['cache_burst'] = uuid()
            this.tags = this._resource.tags = [...newUploadedEvent.resource.tags]
            this._resource.version = newUploadedEvent.resource.version
            await this.calculateImgSrcAndS3SignUrl()
            this.storedResource = JSON.parse(JSON.stringify(newUploadedEvent.resource))
            const index = this.explorerDataSourceService.resources.findIndex(r => r.uri === this._resource.uri)
            if (index !== -1) {
              this.explorerDataSourceService.resources[index] = newUploadedEvent.resource
            }
            if (newResource?.pageCount) {
              this._resource.pageCount = newUploadedEvent.resource?.pageCount
            }
            this.cloneResource()
            this.changeDetectorRef.markForCheck()
          }
        }
        break
      }
    }
  }

  get size(): string {
    return ((this._resource.kind === RESOURCE_KIND.FILE?  this._resource.fileSize :  this.folderInfo ? this.folderInfo.totalFileSize : 0) / 1024).toFixed(2)
  }

  tagAdded($event) {
    if ($event && $event.length > CONSTRAINTS.MAX_RESOURCE_NAME_LENGTH) {
      const index2Del = this.tags.findIndex(t => t === $event)
      if (index2Del !== -1) {
        this.tags.splice(index2Del, 1)
        this.tags = [ ... this.tags]
      }
      this.notificationService.error(
        formatMsg(MESSAGES.TAG_MAX_LENGTH_EXCEEDED, {MAX_RESOURCE_NAME_LENGTH : CONSTRAINTS.MAX_RESOURCE_NAME_LENGTH, length: $event.length})
        )

      let items = this.ngSelect.itemsList["_selectionModel"]["_selected"]
      for(let i = 0; i < items.length; i++) {
        if (items[i].value === $event) {
          items.splice(i, 1)
          break
        }
      }
      return
    }
    if(!TAG_REGEX.test($event)) {
      const index2Del = this.tags.findIndex(t => t === $event)
      if (index2Del !== -1) {
        this.tags.splice(index2Del, 1)
      }
      this.tags = [ ... this.tags.sort()]
      this.notificationService.error(MESSAGES.TAGS_ALFA_NUM)

      return
    }
    if (!this.currentSuggestions.includes($event)) {
      this.recentTagsService.add($event)
    }
  }

  async presign(Key: string, Bucket: string) {
    if (!Key || !Bucket) {
      return undefined
    }
    const s3Client = new S3Client({
      region: PARAMS['REGION'],
      credentials: await this.awsCredentialService.getCredentials()
    })

    const MAX_EXPIRY = 604800 // 1 week
    const command = new GetObjectCommand({
        Bucket,
        Key
    })

    /*
    The ResponseContentDisposition parameter allows you to control how the browser or client handles the response when downloading the document. By default, it is set to "attachment", which prompts the browser to download the file as an attachment. If you set it to "inline", the browser may attempt to open the file directly, depending on the user's browser settings and the file type.
    */
    if (this.isPDF) {
      command["ResponseContentDisposition"] = "inline"
      command["ResponseContentType"] = "application/pdf"
    } else if ([ "doc", "docx" ].includes(this._resource?.extension?.toLowerCase())) {
      command["ResponseContentDisposition"] = "attachement"
      command["ResponseContentType"] = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    } else if ([ "xls", "xlsx" ].includes(this._resource?.extension?.toLowerCase())) {
      command["ResponseContentDisposition"] = "attachement"
      command["ResponseContentType"] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    } else if ([ "ppt", "pptx" ].includes(this._resource?.extension?.toLowerCase())) {
      command["ResponseContentDisposition"] = "attachement"
      command["ResponseContentType"] = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    }

    return await getSignedUrl(s3Client, command, { expiresIn: MAX_EXPIRY })
  }

  get pdfSelectUrl(): string {
    let urlBase = 'https://pdf-select.sharklazers.be';

    if (location.host.includes('localhost')) urlBase = 'http://localhost:5173';
    if (location.host.includes('e-ducate')) urlBase = 'https://pdf-select.e-ducate.me/';

    return `${urlBase}/?uri=${this.uri}&expectsImport=false`
  }

}
