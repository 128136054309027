import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs/internal/Subject';
import { CleanupService } from '../../infrastructure/CleanupService';
import { ITreeNode } from '../../infrastructure/ITreeNode';
import { FileSystemService, IPathTuple } from '../../infrastructure/services/FileSystemService';
import { TreeFoldersComponent } from '../tree-folders/tree-folders.component';
import { UploaderService } from '../upload-queue/UploaderService';
import { MY_FILES } from 'prunus-common/dist';
import { USER } from 'src/user/USER';

@Component({
  selector: 'app-explorer',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExplorerComponent implements OnInit {
  name = ''
  title: string
  message: string
  cleanupService = new CleanupService(ExplorerComponent.name)
  selectedTreeNode: ITreeNode
  moveStarted$ = new Subject<void>()
  @ViewChild('tree')
  treeFoldersComponent: TreeFoldersComponent
  uploadRootPath: string | undefined

  constructor(public bsModalRef: BsModalRef,
              private fileSystemService: FileSystemService,
              private uploaderService: UploaderService,
              private router: Router,
      //        private changeDetectorRef: ChangeDetectorRef,
  ) {
    effect(() => {
      const newUploadRootPath = uploaderService.uploadRootPathSignal()
      if (this.uploadRootPath != newUploadRootPath?.newPath) {
        this.onPathChanged(newUploadRootPath)
        this.uploadRootPath = newUploadRootPath?.newPath
    //    this.changeDetectorRef.markForCheck()
      }
    })
  }

  ngOnInit() {
    const searchParams = this.parseQueryString(location.search)
    this.onPathChanged(searchParams.path ? searchParams.path : this.fileSystemService.root)
  }

  onTreeInitialized($event) {
  }

  parseQueryString(searchString: string): any {
    const parameters: any = {};

    if (searchString.charAt(0) === '?') {
      searchString = searchString.substr(1);
    }

    searchString.split('&').forEach(s => {
      const pair = s.split('=');
      parameters[pair[0]] = decodeURIComponent(pair[1]);
    });

    return parameters;
  }

  onPathChanged(pathTuple: IPathTuple) {
    if (this.treeFoldersComponent && pathTuple) {
      this.treeFoldersComponent.expandPathInTree(pathTuple.newPath)
      const match = this.treeFoldersComponent.findPath(this.treeFoldersComponent.root, pathTuple.newPath)
      if (match) {
        this.treeFoldersComponent.select(match.uri, pathTuple)
      }
    }
  }

  onSelect($event: any) {
    this.selectedTreeNode = $event
    if (this.selectedTreeNode.name === MY_FILES && this.selectedTreeNode.parent.name === "prunus" && USER?.isTeacher) {
      $event = { ... $event }
      $event.name = USER.email
    }
    const path = encodeURIComponent(this.treeFoldersComponent.nodeToPath($event))
    this.router.navigate(['/main/explorer/explorer-detail/'],
    {
      fragment: 'explorerDetail',
      queryParams: { path }
    })
    //this.changeDetectorRef.markForCheck()
  }

}
