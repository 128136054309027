export const isTrustedMessageSource = (orgin: string): boolean => {
    if (location.hostname === "localhost") { 
        return true;  
    }     
    let splitted = location.hostname.split(".")
    while (splitted.length > 2) {
        splitted.shift()
    }
    const joined = splitted.join(".");
    if (!orgin.endsWith(joined)) {
        console.warn("received post message from untrusted source", orgin)
        return false;
    }

    return true;
}