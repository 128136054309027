import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_PAGE_SIZE, EVENTS, IBulkDeletedResourcesEvent, IBulkMovedResourceEvent, IFolderCreatedEvent, IFolderQuery, IRenamedEvent, IResource, IResourceDeletedEvent, IResourceMovedEvent, IResourceUpdatedEvent, PathDepth, QUERIES, RESOURCE_KIND } from 'prunus-common/dist';
import { CleanupService } from '../../infrastructure/CleanupService';
import { FileSystemService } from '../../infrastructure/services/FileSystemService';
import { ServerSocketService } from '../../infrastructure/services/server-socket.service';
import { UniversalQueryService } from '../../infrastructure/services/universal-query.service';
import { USER } from '../../user/USER';
import { MY_FILES } from '../constants/MY_FILES';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  // tiles or files
  //baseUrl = '';
  empty: IResource = {
    uri: 'empty-uri',
    isOwnedByTeacher: false,
    user_id: 0,
    name: 'kies een map',
    last_modified_at: undefined,
  };
  selectedSubFolder: IResource;
  subFolders: IResource[] = [];
  queryParams: any = {};
  userEmail = USER.email;
  _parts: string[] = [];
  routeQueryParams: any[] = [];
  private cleanupService = new CleanupService(NavComponent.name);
  _path: string;
  @ViewChild('dropdown', {static: false})
  dropdown: ElementRef;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private fileSystemService: FileSystemService,
    private serverSocketService: ServerSocketService,
    private universalQueryService: UniversalQueryService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnDestroy(): void {
    console.log("BreadcrumbComponent.ngOnDestroy");
    this.cleanupService.cleanupSubscriptions();
  }

  ngOnInit() {
    this.path = this.fileSystemService._path;

    this.cleanupService.addSubscription(
      this.fileSystemService.pathChange$.subscribe((pathTuple) => {
        console.log("this.fileSystemService.pathChange$", pathTuple.newPath)
        this.path = pathTuple.newPath
      })
    );

    this.cleanupService.addSubscription(
      this.fileSystemService.baseUrlChange$.subscribe(() => this.manageParts(this.fileSystemService._path))
    );

    this.cleanupService.addSubscription(
      this.serverSocketService.subscribe2ServerEvents().subscribe(
        (evt) => this.onServerEvent(evt)
      )
    );

  }

  get baseUrl(): string {
    return location.pathname;
  }
  
  get parts(): string[] {
    return this._parts;
  }

  set parts(path: string[]) {
    this._parts = path.filter(x => x); // filter out empty & whitespaces
    if (this._parts[1] === this.userEmail) {
      this._parts[1] = MY_FILES;
    }
  }

  get path(): string {
    return this._path;
  }

  set path(value: string) {
    if (!value) {
      value = this.fileSystemService.ROOT;
    }

    if (this._path === value) {
        return;
    }

    console.log("set path:", value);    

    this._path = value;

    const q: IFolderQuery = {
      queryType: QUERIES.FolderQuery,
      path: value,
      from: 0,
      size: DEFAULT_PAGE_SIZE,
      childrenKind: [RESOURCE_KIND.FOLDER],
      pathDepth: PathDepth.OneLevel

    };

    this.manageParts(value);

    const subscription =
    this.universalQueryService.query(q).subscribe((folders: IResource[]) => {
      subscription.unsubscribe();
      console.log("FolderOneLevelDeepQuery", value);    
      this.subFolders = folders.filter(fo => fo.path === q.path).sort((f1, f2) => f1.name.localeCompare(f2.name));
      if (folders.length > 1) {
        this.subFolders.unshift(this.empty);
        this.selectedSubFolder = this.empty;
      } else {
        this.dropdown.nativeElement.blur();
      }

      console.log(`set path to ${value}, after FolderOneLevelDeepQuery`);
      this.fileSystemService._path = value;
    });

  }

  manageParts(value: string) {
    this.routeQueryParams = [];
    this.parts = value.split("/");

    let breadCrumbString = '';
    for (let i = 0; i < this.parts.length; i++) {
      breadCrumbString += `/${this.parts[i]}`;
      this.routeQueryParams[i] = { ...this.queryParams, path: breadCrumbString };
    }
  }

  parseQueryString(searchString: string): any {
    const parameters: any = {};

    if (searchString.indexOf('?') !== -1) {
      searchString = searchString.split('?')[1];
    }

    searchString.split('&').forEach(s => {
      if (s.indexOf('#') !== -1) {
        s = s.split('#')[0];
      }
      const pair = s.split('=');
      parameters[pair[0]] = decodeURIComponent(pair[1]);
    });

    return parameters;
  }

  onSubFolderChange(folder: IResource) {
    if (folder.uri === this.empty.uri) {
      return;
    }
    let url = '/' + this.activatedRoute.snapshot['_urlSegment'].segments.map(s => s.path).join('/') + '?';
    Object.keys(this.activatedRoute.snapshot.queryParams).forEach(qp => {
      if (qp.toLowerCase() === 'path') { return; }
      url += `${qp}=${this.activatedRoute.snapshot.queryParams[qp]}&`;
    });
    url += 'path=' + encodeURIComponent(`${this.path}/${folder.name}`);
    this.selectedSubFolder = undefined;
    this.router.navigateByUrl(url);
  }

  onServerEvent(evt: any) {
    if (!evt.resource || !evt.resource.path || evt.resource.path !== this.path) {
      if (![EVENTS.ResourceMovedEvent, EVENTS.BulkDeletedResourcesEvent, EVENTS.BulkDeletedResourcesEvent].includes(evt.eventType)) {
        return;
      }
    }
    switch (evt.eventType) {
      case EVENTS.FolderCreatedEvent: {
        const folderCreatedEvent = evt as IFolderCreatedEvent;
        this.subFolders.push(folderCreatedEvent.resource);
        break;
      }
      case EVENTS.UpdatedResourceEvent: {
        const updatedResourceEvent = evt as IResourceUpdatedEvent;
        const idx = this.subFolders.findIndex(f => f.uri === updatedResourceEvent.uri);
        if (idx !== -1) {
          this.subFolders[idx] = updatedResourceEvent.resource;
        }
        break;
      }
      case EVENTS.DeletedResourceEvent: {
        const deletedResourceEvent = evt as IResourceDeletedEvent;
        const idx = this.subFolders.findIndex(f => f.uri === deletedResourceEvent.resource.uri);
        if (idx !== -1) {
          this.subFolders.splice(idx, 1);
        }
        break;
      }
      case EVENTS.BulkDeletedResourcesEvent: {
        const bulkDeletedResourceEvent = evt as IBulkDeletedResourcesEvent;
        for (let i = 0; i < bulkDeletedResourceEvent.uris.length; i++) {
          const index = this.subFolders.findIndex(f => f.uri === bulkDeletedResourceEvent.uris[i]);
          if (index !== -1) {
            this.subFolders.splice(index, 1);
          }
        }
        break;
      }
      case EVENTS.ResourceMovedEvent: {
        const resourceMovedEvent = evt as IResourceMovedEvent;
        const idx = this.subFolders.findIndex(f => f.uri === resourceMovedEvent.resource.uri);
        if (idx !== -1) {
          this.subFolders.splice(idx, 1);
        }
        break;
      }
      case EVENTS.BulkMovedResourceEvent: {
        const resourceMovedEvent = evt as IBulkMovedResourceEvent;
        for (let idx = 0; idx < this.subFolders.length; idx++) {
          if (resourceMovedEvent.sourceUris.includes(this.subFolders[idx].uri)) {
            this.subFolders.splice(idx, 1);
          }
        }
        break;
      }
      case EVENTS.RenamedEvent: {
        const renamedEvent = evt as IRenamedEvent;
        const idx = this.subFolders.findIndex(f => f.uri === renamedEvent.resource.uri);
        if (idx !== -1) {
          this.subFolders[idx] = renamedEvent.resource;
        }
        break;
      }
    }
  }
}