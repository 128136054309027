import { ROLES } from "redwood-model/dist";
import { MESSAGES } from "../MESSAGES";
import { PARAMS } from "../params";
import { User } from "./model/User";
import { SSO_FRAME } from "../SSO/SSOFrame";
import { TOAST_LEVELS, showToast } from "../toast";

let USER: User;

const setUser = (user: User) : boolean => {
    USER = Object.assign(new User(), user)
    const allowed = USER.hasRoles([  ROLES.SCHOOL_TEACHER , ROLES.USER_TEACHER, ...  ROLES.ALL_INTERNAL_USERS])
    if (!allowed) {
        showToast(MESSAGES.UNAUTHORIZED, TOAST_LEVELS.ERROR, "Fout")
        if (SSO_FRAME) {
            SSO_FRAME["contentWindow"].postMessage("logout", location.origin.replace("prunus", "sso")); 
        }
        
        return false
    }

    return true
}
 

export { USER, setUser }