import { Routes } from '@angular/router';
import { DetailViewComponent } from './detail-view/detail-view.component';
import { ExplorerDetailComponent } from './explorer-view/explorer-detail/explorer-detail.component';
import { ExplorerComponent } from './explorer-view/explorer.component';
import { MainComponent } from './main/main.component';
import { Page404Component } from './page404/page404.component';
import { TilesViewComponent } from './tiles-view/tiles-view.component';
import { PrunusCanActivate } from '../PrunusCanActivate';

export const APP_ROUTES: Routes = [
  { path: 'detail/:uri', component: DetailViewComponent, canActivate: [PrunusCanActivate] },
  { path: 'main', component: MainComponent,
    children: [
      { path: '', redirectTo: '/explorer', pathMatch: 'full' },
      {
        path: 'tile',
        children: [
          { path: '', component: TilesViewComponent },
          { path: 'detail/:uri', component: DetailViewComponent },
        ]
      },
      {
        path: 'explorer',
        children: [
          { path: '',
            component: ExplorerComponent,
            children: [
              {
                path: 'explorer-detail',
                children: [
                      { path: '', component: ExplorerDetailComponent },
                      { path: 'detail/:uri', component: DetailViewComponent },
                ]
              }
            ]
          },
        ]
      },
    ]
    , canActivate: [PrunusCanActivate]
  },
  { path: '', redirectTo: 'main/explorer', pathMatch: 'full'/*, canActivate: [PrunusCanActivate]*/ },
  { path: '**', component: Page404Component },
];

