<div class="upload-queue d-flex flex-column flex-fill">
    <div class="text-right border-bottom border-top p-2">
    </div>
    <div class="flex-fill overflow-auto">
        <table class="table">
            <thead>
            <tr>
                <th><input (change)="toggleSelectAll($event)" [checked]="checkAll" type="checkbox" /></th>
                <th>thumb</th>
                <th max-width="25%">naam</th>
                <th max-width="25%" (click)="selectUploadFolder()">map <edu-icon-pencil color="black" size="12px" type="filled"></edu-icon-pencil></th>
                <th>grootte</th>
                <th>vooruitgang</th>
                <th>status</th>
                <th>tags</th>
                <th>acties</th>
            </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of currentPageData; let i = index">
                    <td><input [(ngModel)]="item.checked" type="checkbox" (change)="checkedChange($event)"/></td>
                    <td class="d-flex align-items-center justify-content-center">
                        <img *ngIf="item.thumb"
                        [src]="item.thumb | safe" style="max-height: 75px; max-width: 75px;" />
                        <span *ngIf="!item.thumb">niet voor dit formaat</span>
                    </td>
                    <td class="name-text-overflow">{{ item?.filename }}</td>
                    <td class=".name-text-overflow">{{ replaceMyFiles(item.path)}}</td>
                    <td nowrap>{{ item?.file?.size / 1024 / 1024 | number:'.2' }} MB</td>
                    <td>
                        <div class="progress" style="margin-bottom: 0;">
                            <div [ngStyle]="{ 'width': item.progress + '%' }"
                                class="progress-bar progress-bar-custom"
                                role="progressbar"></div>
                        </div>
                    </td>
                    <td class="text-center">
                        <span *ngIf="item.isSuccess"><edu-icon-check-fat class="statusIcon success" type="filled"></edu-icon-check-fat></span>
                        <span *ngIf="item.isCancelled"><edu-icon-bin class="statusIcon danger"  type="filled"></edu-icon-bin></span>
                        <span *ngIf="item.isError"><edu-icon-full-moon class="statusIcon"  type="filled"></edu-icon-full-moon></span>
                        <span *ngIf="!item.isError && !item.isCancelled && ! item.isSuccess"><edu-icon-list class="statusIcon" type="filled"></edu-icon-list></span>
                    </td>
                    <td class="tags4Upload">
                        <ng-select
                        [items]="suggestions$ | async"
                        [multiple]="true"
                        [addTag]="true"
                        placeholder="zoek op een woord"
                        [loading]="suggestionsLoading"
                        [(ngModel)]="item['tags']"
                        [typeahead]="suggestionsInput$"
                        (open)="onOpen()"
                        (change)="newTagsSelectionChanged($event, i + (currentPage * pageSize))"
                        class="margin-small"
                        addTagText="toevoegen"
                        (add)="tagAdded($event, i)"
                        loadingText="bezig met laden ..."
                        [closeOnSelect]="true"
                        [maxSelectedItems]="MAX_SELECTED_ITEMS"
                        >
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                            <div [ngOptionHighlight]="search">{{ item }}</div>
                        </ng-template>
                        </ng-select>
                    </td>
                    <td nowrap>
                        <button (click)="onItemUpload(i + (currentPage * pageSize), item)"
                                [disabled]="item.isReady || item.isUploading || item.isSuccess || quota_exceeded"
                                class="uploadOneButton btn btn-primary" type="button">
                          <edu-icon-cloud-upload-alt color="white" size="1rem" type="filled"></edu-icon-cloud-upload-alt>
                        </button>
                        <!--
                        <button (click)="onCancelItem(item)" [hidden]="!item.isUploading" class="cancelOneButton btn btn-warning"
                                type="button">
                            <span class="fa fa-ban"></span>
                        </button>-->
                        <button (click)="onItemRemove(item, i)" [disabled]="isBatchUploading || item.isUploading" class="removeOneButton btn btn-danger" type="button">
                          <edu-icon-bin size="1rem" color="white" type="filled"></edu-icon-bin>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="border-top p-2">
        <div class="pager">
            <button (click)="previousPage()" [hidden]="currentPage === 0"
                    class="btn btn-secondary" type="button">
                <span class="fa fa-angle-double-left"></span>
            </button>
            <span *ngIf="numberOfPages > 1">
        <button (click)="setPage(i)" *ngFor="let i of pageNumbers"
                [ngClass]="currentPage === i ? 'btn-success' : 'btn-light'"
                class="btn mr-1"
                type="button"
        >        {{i + 1}}
        </button>
      </span>
            <button (click)="nextPage()" [hidden]="currentPage === numberOfPages - 1"
                    class="btn btn-secondary"
                    type="button"
            >
                <span class="fa fa-angle-double-right"></span>
            </button>
        </div>
        <div class="buttons">
            <span class="mr-3"># uploads: {{uploaderService.iQItems.length}}, size: {{queueSize}} MB</span>
            <button (click)="uploadCheckedItems()" [disabled]="(!uploaderService.getActive2UploadItems().length) || uploaderService.isUploading || uploaderService.isBatchUploading || quota_exceeded"
                    class="btn btn-primary text-uppercase"
                    id="uploadAllButton" type="button">
                Opladen
            </button>
            <button (click)="clearCheckedItems()" [disabled]="(!uploaderService.getActive2UploadItems().length) || uploaderService.isBatchUploading || uploaderService.isUploading"
                    class="btn btn-danger text-uppercase"
                    id="clearAllButton" type="button">
                Verwijderen
            </button>
            <button (click)="cancelCheckedItems()" [hidden]="!uploaderService.isUploading"
                    class="btn btn-warning text-uppercase"
                    id="cancelAllButton" type="button">
                Annuleren
            </button>
        </div>
    </div>
</div>
