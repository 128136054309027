import { IResource } from "prunus-common/dist";

export const ellipsis = (resources: IResource[]) : string => {
    let addEllipsis = false;
    const SLICE_LENGTH = 10;
    if (resources.length > (SLICE_LENGTH - 1)) {
      addEllipsis = true;
    }
    const sliced = resources.slice(0, SLICE_LENGTH).map(r => "'" + r.name + "'");
    if (addEllipsis) {
      sliced.push('...');
    }

    return sliced.join(",");
}