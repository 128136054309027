import { Spinner } from "./app/ISpinner";


export const showSpinner = (input?: string, isError = false) => {
  const spinner: Spinner = document.querySelector('educateme-spinner') as Spinner;
  if (spinner) {
    spinner.isError = isError;
    spinner.message = input || '';
    spinner.isVisible = true;
  }
}
export const hideSpinner = () => {
  const spinner: Spinner = document.querySelector('educateme-spinner') as Spinner;
  if (spinner) {
    spinner.message = '';
    spinner.isVisible = false;
  }
}
