<div  class="grid">
  <as-split direction="horizontal" style="width: 100vw;">
    <as-split-area [size]="20">
      <div class="tree-container">
        <app-tree-folders #tree (treeInitialized)="onTreeInitialized($event)" (nodeSelected)="onSelect($event)"></app-tree-folders>
      </div>
    </as-split-area>
    <as-split-area [size]="80" class="selection-area-container">
      <div id="explorerDetail">
        <router-outlet>
        </router-outlet>
      </div> 
    </as-split-area>
  </as-split>
</div>

