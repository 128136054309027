import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { TemplatePortal } from '@angular/cdk/portal'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { COMMANDS, CONSTRAINTS, DOCUMENT_EXTENSIONS, ICommand, ICreateFolderCommand, IFindResourceByUriQuery, IRenameFileCommand, QUERIES, RESOURCE_KIND } from 'prunus-common/dist'
import { ROLES } from 'redwood-model/dist'
import { Subscription } from 'rxjs/internal/Subscription'
import { fromEvent } from 'rxjs/internal/observable/fromEvent'
import { filter, take } from 'rxjs/operators'
import { DetailViewComponent } from 'src/app/detail-view/detail-view.component'
import { NotificationService } from 'src/infrastructure/services/notification-service'
import { UploaderService } from 'src/app/upload-queue/UploaderService'
import { v4 as uuid } from 'uuid'
import { MESSAGES, formatMsg } from '../../../MESSAGES'
import { CleanupService } from '../../../infrastructure/CleanupService'
import { CONTEXT, Context } from '../../../infrastructure/Context'
import { DIALOG_OPEN_OPTIONS } from '../../../infrastructure/DialogOptions'
import { ErrorHandlingService } from '../../../infrastructure/ErrorHandlingService'
import { ErrorInfo } from '../../../infrastructure/ErrorInfo'
import { MouseButtons } from '../../../infrastructure/MouseButton'
import { ConfirmDialogComponent } from '../../../infrastructure/confirm-dialog/confirm-dialog.component'
import { UniversalCommandService } from '../../../infrastructure/cqrs/universal-command.service'
import { ellipsis } from '../../../infrastructure/ellipsis'
import { KeyCodes } from '../../../infrastructure/keyboard/KeyCodes'
import { ShortcutService } from '../../../infrastructure/keyboard/shortcut.service'
import { FileSystemService } from '../../../infrastructure/services/FileSystemService'
import { UniversalQueryService } from '../../../infrastructure/services/universal-query.service'
import { PARAMS } from '../../../params'
import { IResource } from '../../../resource/interface/IResource'
import { USER } from '../../../user/USER'
import { AppService } from '../../app.service'
import { email2MyFilesPath, MY_FILES } from '../../constants/MY_FILES'
import { ErrorModalComponent } from '../../error-modal/error-modal.component'
import { ExportModalComponent } from '../../export-modal/export-modal.component'
import { LinkPublicationModalComponent } from '../../link-publication-modal/link-publication-modal.component'
import { MoveModalComponent } from '../../move-modal-component/move-modal.component'
import { ProgressModalComponent } from '../../progress-modal/progress-modal.component'
import { PromptNameModalComponent } from '../../prompt-name-modal/prompt-name-modal.component'
import { TokenManagerService } from '../../../infrastructure/services/token-manager.service'
import { ExplorerDataSourceService } from '../explorer-data-source-service'
import { ExplorerMultiSelectService } from '../explorer-multi-select.service'
import { ExportOptionsModalComponent } from 'src/app/export-options-modal/export-options-modal.component'

@Component({
  selector: 'app-explorer-detail',
  templateUrl: './explorer-detail.component.html',
  styleUrls: ['./explorer-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExplorerDetailComponent implements OnInit {
  @ViewChild('resourceMenu', {static: true}) resourceMenu: TemplateRef<any>
  @ViewChild('newFolderMenu', {static: true}) newFolderMenu: TemplateRef<any>
  @ViewChild('recoverMenu', {static: true}) recoverMenu: TemplateRef<any>
  @ViewChild('thumbs', {static: false}) thumbs: ElementRef
  overlayRef: OverlayRef | null
  fileSystemComponent: ElementRef
  subscription: Subscription
  hasBaseDropZoneOver = false
  allowedResourcesExtensions: string[] = []
  canLinkPublication = USER.hasRoles([ ROLES.USER_DEVELOPER, ROLES.USER_ADMIN ])
  isAdmin = USER.hasRoles([ ROLES.USER_DEVELOPER, ROLES.USER_ADMIN ])
  private cleanupService = new CleanupService(ExplorerDetailComponent.name)

  constructor(
    public context: Context,
    private notificationService: NotificationService,
    private fileSystemService: FileSystemService,
    private universalQueryService: UniversalQueryService,
    public explorerDataSourceService: ExplorerDataSourceService,
    private shortcutService: ShortcutService,
    private universalCommandService: UniversalCommandService,
    private modalService: BsModalService,
    public tokenManagerService: TokenManagerService,
    private changeDetectorRef: ChangeDetectorRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private errorHandlingService: ErrorHandlingService,
    private appService: AppService,
    private uploaderService: UploaderService,
    public explorerMultiSelectService: ExplorerMultiSelectService,
    ) {}

  ngOnInit() {
    this.explorerMultiSelectService.init()
    this.explorerDataSourceService.changeDetectorRef = this.changeDetectorRef
    this.explorerDataSourceService.init()
    this.explorerDataSourceService.fetchMore()

    if (this.context.filterType && this.context.filterType.indexOf('svg') !== -1) {
      this.allowedResourcesExtensions = ['svg']
    } else {
      this.cleanupService.addSubscription(
        this.universalQueryService.query({
          queryType: QUERIES.AllowedResourceExtensionsQuery
        }).subscribe(result => {
          this.allowedResourcesExtensions = [ ...result ]
        })
      )
    }
     
    this.cleanupService.addSubscription(
      this.shortcutService.shortcutStream$.subscribe(sc => {
        if (sc.keyconstant === KeyCodes.KEY_DELETE && this.explorerMultiSelectService.selectedResources.length) {
          this.delete(this.explorerMultiSelectService.selectedResources[0])
        }
      })
    )

    this.cleanupService.addSubscription(
      this.explorerDataSourceService.newTmpNotYetProcessedResource$.asObservable().subscribe((r: IResource) => {
        if (r.path === this.fileSystemService.path) {
          r.isNew = true
          this.onNewImageUploaded(r)
        }
    }))

    this.cleanupService.addSubscription(
      this.fileSystemService.pathChange$.subscribe(p => this.changeDetectorRef.markForCheck())
    )

    this.cleanupService.addSubscription(
      this.errorHandlingService.errors$.subscribe(e => this.onError(e))
    )

    this.cleanupService.addSubscription(
      this.appService.emailChangedEvent$.subscribe((newMail: string) => {
        this.changeDetectorRef.markForCheck()
      })
    )
  }

  ngAfterViewInit() {
    this.cleanupService.addSubscription(
      this.explorerDataSourceService.dataCleared$.subscribe(() => {
        this.explorerDataSourceService.fetchMore()
        this.changeDetectorRef.markForCheck()
      })
    )
    this.cleanupService.addSubscription(
      this.explorerDataSourceService.dataChanged$.subscribe(() => {
        this.changeDetectorRef.markForCheck()
      })
    )
  }

  ngOnDestroy() {
    this.explorerDataSourceService.destroy()
    this.cleanupService.cleanupSubscriptions()
  }

  get canUpload(): boolean {
    return  USER.hasRoles([ ... ROLES.ALL_INTERNAL_USERS])
    || (USER.isTeacher && this.fileSystemService.path.indexOf(USER.email) !== -1)
  } 
 
  /***
   * Support for drop zone of images, this hands over the files of files to upload to the uploader
   * @param e (list of files)
   */
  fileOverBase (e: any): void {
    this.hasBaseDropZoneOver = e
  }

  /***
   * fires each time the uploader complete a new upload
   * @param {Resource} newUpload
   */
  onNewImageUploaded (newUpload: IResource) {
    newUpload.isNew = true
    
    if (DOCUMENT_EXTENSIONS.includes(newUpload.extension)) {
      newUpload.thumbnail = `./assets/${newUpload.extension}.svg `
    }
    
    const existingIndex = this.explorerDataSourceService.resources.findIndex( r => r.name === newUpload.name && r.path === newUpload.path)
    if (existingIndex !== -1) {
      this.explorerDataSourceService.resources.splice(existingIndex, 1)
    }
    this.explorerDataSourceService.resources.splice(0, 0, newUpload) // insert as first item on screen
    this.explorerDataSourceService.resources = [... this.explorerDataSourceService.resources ]
    this.changeDetectorRef.markForCheck()
  }

  isSelected(resource: IResource) {
    return this.explorerMultiSelectService.selectedResources.findIndex(
      r => r.uri === resource.uri
    ) !== -1
  }

  deSelectAll(evt: MouseEvent): void {
    if (evt.button === MouseButtons.SecondaryOrRight) {
      return
    }
    this.explorerMultiSelectService.deselectAll()
  }

  forceDelete(resource) {
    if (this.checkIsBulkUploadInProgress()) return
    return this.delete(resource, true)
  }

  delete(iResource: IResource, force = false) {
    if (this.checkIsBulkUploadInProgress()) return
    if (iResource.isNotYetProcessedByServer) {
      this.notificationService.error(
        MESSAGES.DELETE_ITEM_NOT_YET_PROCESSED,
        MESSAGES.ITEM_NOT_YET_PROCESSED_TOAST_TITLE)

      return
    }

    if (iResource.externalUse && iResource.externalUse.length) {
      this.notificationService.error(
        formatMsg(MESSAGES.MAPLE_USAGE, { scenes: iResource.externalUse.map(usage => usage.external_resource_name).join(" ")}),
        MESSAGES.ITEM_IN_USE_TOAST_TITLE)

      return
    }

    if (this.explorerMultiSelectService.selectedResources.length) {
      for (const r of this.explorerMultiSelectService.selectedResources) {
        if (r.externalUse && r.externalUse.length) {
          this.notificationService.error(
            formatMsg(MESSAGES.MAPLE_USAGE, { scenes: r.externalUse.map(usage => usage.external_resource_name).join(" ")}),
            MESSAGES.ITEM_IN_USE_TOAST_TITLE)

          return  
        }
      }
    }

    const ref: BsModalRef = this.modalService.show(ConfirmDialogComponent, DIALOG_OPEN_OPTIONS)
    const confirmDialog: ConfirmDialogComponent = ref.content
    confirmDialog.message = `Bent u zeker dat u deze item(s) ${this.explorerMultiSelectService.selectedResources.map(r => r.name).join(', ')} wenst te verwijderen?`
    confirmDialog.onYesAction = () => {
      const cmd: ICommand = {
        uid: uuid(),
        commandType: ''
      }
      if (force) {
        cmd["force"] = true
      }
      const uris2Delete: string[] = []
      let isBulkDelete = this.explorerMultiSelectService.selectedResources.length > 1
      if (isBulkDelete) {
        cmd.commandType = COMMANDS.BulkDeleteResourcesCommand
        cmd['uris'] = []
        cmd['deleteMasterFromS3'] = []
        cmd['fullPaths'] = []
        this.explorerMultiSelectService.selectedResources.forEach(resource => {
          cmd['uris'].push(resource.uri)
          cmd['deleteMasterFromS3'].push(true)
          cmd['fullPaths'].push(resource.path + "/" + resource.name)
        })
      } else {
        cmd.commandType = COMMANDS.DeleteResourceCommand
        cmd['uri'] = iResource.uri
        cmd['deleteMasterFromS3'] = true
        cmd['fullPath'] = iResource.path + "/" + iResource.name
        uris2Delete.push(iResource.uri)
      }

      const startMsg = formatMsg(MESSAGES.DELETE_ACTION_STARTED, { names: this.explorerMultiSelectService.selectedResources.map(r => `'${r.name}'`).join(', ')  })
      let moveModalComponent: ProgressModalComponent
      if (isBulkDelete || iResource.kind === RESOURCE_KIND.FOLDER) {
        const ref: BsModalRef = this.modalService.show(ProgressModalComponent, DIALOG_OPEN_OPTIONS)
        const moveModalComponent: ProgressModalComponent = ref.content
        moveModalComponent.title = MESSAGES.PROGRESS_DEL_BULK_TILE
        moveModalComponent.cmd = cmd
        moveModalComponent.progressMessage = startMsg
      } else {
        this.notificationService.info(startMsg)  
      }
      
      const subscription =
        this.universalCommandService.handle(cmd).subscribe(() => {
          uris2Delete.forEach((uri: string) => {
            const resource = this.explorerDataSourceService.resources.find(r => r.uri === uri)
            if (resource) {
              const sentMsg = formatMsg(MESSAGES.RESOURCE_DELETE_REQ_SENT, {name: resource.name})
              if (isBulkDelete) {
                moveModalComponent.progressMessage = sentMsg
              } else {
                this.notificationService.success(sentMsg)  
              }
              
              resource.isNotYetProcessedByServer = true
              this.explorerMultiSelectService.deselect(resource)
            }
          })
          this.changeDetectorRef.markForCheck()
          if (subscription) {
            subscription.unsubscribe()
          }
        })
      this.cleanupService.addSubscription(subscription)
    }
  }

  trackByFunction(index: number, resource: IResource): string {
    return resource.uri
  }
  
  openDetail(resource: IResource) {
    if (resource.isNotYetProcessedByServer == true) { return }
    if (resource.errorInfo) { 
      const refErr: BsModalRef = this.modalService.show(ErrorModalComponent, {
        ... DIALOG_OPEN_OPTIONS
      })

      const errorModal: ErrorModalComponent = refErr.content
      errorModal.message = resource.errorInfo.message
      return 
    }
    const detailViewModal = this.modalService.show(DetailViewComponent, {
      ... DIALOG_OPEN_OPTIONS, 
      ... { class: "xlModal" }
    })
    const detailViewModalComponent: DetailViewComponent = detailViewModal.content
    detailViewModalComponent.resource = resource
  }

  move(resource: IResource) {
    if (this.checkIsBulkUploadInProgress()) return
    if (resource.isNotYetProcessedByServer) {
      this.notificationService.error(
        MESSAGES.RESOURCE_NOT_YET_PROCESSED,
        MESSAGES.RESOURCE_NOT_YET_PROCESSED_TILE)

      return
    }
    
    const ref: BsModalRef = this.modalService.show(MoveModalComponent, DIALOG_OPEN_OPTIONS)
    const moveModalComponent: MoveModalComponent = ref.content
    moveModalComponent.sourceResources = [...this.explorerMultiSelectService.selectedResources]
    moveModalComponent.path = email2MyFilesPath(this.fileSystemService.path)
    this.cleanupService.addSubscription(
      moveModalComponent.moveStarted$.subscribe(() => {
        moveModalComponent.sourceResources.forEach(r => {
            const matchedResource = this.explorerDataSourceService.resources.find(dr => dr.uri === r.uri)
            if (matchedResource) {
              matchedResource.isNotYetProcessedByServer = true
            }
          }
        )
        this.changeDetectorRef.markForCheck()
      })
    )
  }

  export(resource: IResource) {
    if (this.checkIsBulkUploadInProgress()) return
    if (resource.isNotYetProcessedByServer) {
      this.notificationService.error(
        MESSAGES.EXPORT_ITEM_NOT_YET_PROCESSED,
        MESSAGES.ITEM_IN_USE_TOAST_TITLE)

      return
    }

    const selection = [...this.explorerMultiSelectService.selectedResources]
    const msg = MESSAGES.EXPORT_MESSAGE
    const confirmRef: BsModalRef = this.modalService.show(ConfirmDialogComponent, DIALOG_OPEN_OPTIONS)
    const confirmDialog: ConfirmDialogComponent = confirmRef.content
    confirmDialog.title = MESSAGES.EXPORT_MODAL_TITLE
    confirmDialog.message = msg
    confirmDialog.onYesAction = () => {
      this.closeContextMenu()
      confirmRef.hide()
      const ref: BsModalRef = this.modalService.show(ExportOptionsModalComponent, DIALOG_OPEN_OPTIONS)
      const content = ref.content as ExportOptionsModalComponent
      content.onOKAction = (includeURI: boolean, originals: boolean, flatten: boolean) => { 
        let exportRef: BsModalRef = this.modalService.show(ExportModalComponent, DIALOG_OPEN_OPTIONS)
        const exportModal: ExportModalComponent = exportRef.content
        exportModal.selection = selection
        if (selection.length === 1) {
          exportModal.title = `exporting ${resource.name}`
        } else {
          exportModal.title = `exporting ${ellipsis(selection)}`
        }
        exportModal.export(includeURI, originals, flatten)
      }
    }
    confirmDialog.onNoAction = () =>  this.closeContextMenu()
  }

  linkPublicationId(resource: IResource) {
    if (resource.isNotYetProcessedByServer) {
      this.notificationService.error(
        MESSAGES.LINK_ITEM_NOT_YET_PROCESSED,
        MESSAGES.RESOURCE_NOT_YET_PROCESSED_TILE)

      return
    }
    const ref: BsModalRef = this.modalService.show(LinkPublicationModalComponent, DIALOG_OPEN_OPTIONS)
    const linkPublicationModalComponent: LinkPublicationModalComponent = ref.content
    linkPublicationModalComponent.resources = [...this.explorerMultiSelectService.selectedResources]
    this.cleanupService.addSubscription(
      linkPublicationModalComponent.onNext.subscribe((publication_ids_per_uri) => {
        Object.keys(publication_ids_per_uri).forEach(uri => {
          let match = this.explorerDataSourceService.resources.find(r => r.uri === uri)
          const pub_ids = publication_ids_per_uri[uri]
          if (match) {
            match.publication_ids = pub_ids
          }
          match = this.explorerMultiSelectService.selectedResources.find(r => r.uri === uri)
          if (match) {
            match.publication_ids = pub_ids
          }
          match = this.explorerDataSourceService.resources.find(r => r.uri === uri)
          if (match) {
            match.publication_ids = pub_ids
          }
          match = this.explorerDataSourceService.resources.find(r => r.uri === uri)
          if (match) {
            match.publication_ids = pub_ids
          }
        })
      })
    )
  }

  ignoreContextMenu(): boolean {
    return false
  }

  onOpenContextMenu(mouseEvent: any, iResource: IResource) {
    if (mouseEvent.button !== MouseButtons.SecondaryOrRight) {
      return false
    }
    mouseEvent.preventDefault()
    mouseEvent.stopPropagation()
    const {x, y} = mouseEvent
    this.closeContextMenu()
   
    if (!iResource.errorInfo && (!this.canEdit(iResource) && !this.canImport(iResource))) {
      if (!(USER.isTeacher && iResource.name === MY_FILES)) { 
        return false
      }
    }
    this.explorerMultiSelectService.select(iResource)
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([{
        originX : 'start',
        originY : 'center',
        overlayX: 'start',
        overlayY: 'center',
        offsetX: 50
      }])

    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    })
    this.overlayRef.attach(new TemplatePortal(iResource.errorInfo ? this.recoverMenu: this.resourceMenu, this.viewContainerRef, {
      $implicit: iResource
    }))

    this.subscription = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement

          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget)
        }),
        take(1)
      ).subscribe(() => this.closeContextMenu())
    this.cleanupService.addSubscription(this.subscription)

    return false
  }

  closeContextMenu() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    if (this.overlayRef) {
      this.overlayRef.dispose()
      this.overlayRef = null
      this.explorerMultiSelectService.deselectAll()
    }
  }

  onOpenNewFolderContextMenu(mouseEvent: any) {
    if (mouseEvent.button !== MouseButtons.SecondaryOrRight || this.overlayRef) {
      return false
    }
    mouseEvent.preventDefault()
    const {x, y} = mouseEvent
    this.closeContextMenu()

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([{
        originX : 'start',
        originY : 'center',
        overlayX: 'start',
        overlayY: 'center',
        offsetX: 50
      }])

    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    })
    this.overlayRef.attach(new TemplatePortal(this.newFolderMenu, this.viewContainerRef, {
      $implicit: undefined
    }))

    this.subscription = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement

          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget)
        }),
        take(1)
      ).subscribe(() => this.closeContextMenu())
    this.cleanupService.addSubscription(this.subscription)

    return false
  }

  canEdit(resource: IResource): boolean {
    if (resource.isNotYetProcessedByServer || resource.isTeachersRootFolder) { return false }
    if (!USER) { return false }
    if (USER.isAdmin) {
      return true
    }

    if (USER.isTeacher) {

      return (resource.isOwnedByTeacher && resource.user_id === USER.id)
    }

    return false
  }

  canImport(resource: IResource): boolean {
    if (!CONTEXT.isExternal) { return false }
    if (resource.kind !== 'FILE') { return false }
    if (resource.isNotYetProcessedByServer) { return false }
    if (!USER) { return false }
    if (USER.isAdmin) { return true }
    // either the teacher is the owner or has permissions through publication he bought (checked server side)
    if (USER.isTeacher) { return true }

    return false
  }

  get canAddNewContent(): boolean {
    // a teacher can not add content to existing pubs
    if (USER.isTeacher) {
     return this.fileSystemService.path.startsWith(this.teachersMyFolderPath)
    }

    return true
  }

  get teachersMyFolderPath(): string {
    return `${this.fileSystemService.root}/${USER.email}`
  }

  rename(resource: IResource) {
    if (this.checkIsBulkUploadInProgress()) return
    if (resource.isNotYetProcessedByServer) {
      this.notificationService.error(
        MESSAGES.RESOURCE_NOT_YET_PROCESSED,
        MESSAGES.RESOURCE_NOT_YET_PROCESSED_TILE)

      return
    }
    const ref: BsModalRef = this.modalService.show(PromptNameModalComponent, DIALOG_OPEN_OPTIONS)
    const promptNameModalComponent: PromptNameModalComponent = ref.content
    promptNameModalComponent.name = resource.name

    this.cleanupService.addSubscription(
      promptNameModalComponent.onNext.subscribe((name: string) => {
        name = name.split('/').join('')
        if (name.includes(":")) {
          this.notificationService.error(MESSAGES.ITEM_NAME_COLON)
          return
        }
        const dirPath = this.fileSystemService.path + "/" + name
        const pathLength = dirPath.length
        if (pathLength > CONSTRAINTS.MAX_PATH_LENGTH) {
          this.notificationService.error(
            formatMsg(MESSAGES.MAX_FOLDER_DEPTH_EXCEEDED, {MAX_PATH_LENGTH: CONSTRAINTS.MAX_PATH_LENGTH, length: pathLength})
          )    
          return
        }
        const cmd: IRenameFileCommand = {
          uid: uuid(),
          commandType: COMMANDS.RenameFileCommand,
          uri: resource.uri,
          newName: name,
          fullPath: resource.path + "/" + resource.name
        }
        
        this.universalCommandService.handle(cmd)
        resource.name = name // fake it
        resource.isNotYetProcessedByServer = true
        
      })
    )
  }

  import (resource: IResource): void {
    window.parent.postMessage([ `${PARAMS['PRUNUS-IMG-SERVER']}${resource.url}`, location.href ], '*')
  }

  onCreateNewFolder(): void {
    if (this.checkIsBulkUploadInProgress()) return
    if (USER.isTeacher) {
      if (!this.fileSystemService.path.startsWith(this.teachersMyFolderPath)) {
        this.notificationService.error(formatMsg(MESSAGES.CREATE_FOLDER_NOT_ALLOWED, {MY_FILES}))

        return
      }
    }

    const ref: BsModalRef = this.modalService.show(PromptNameModalComponent, DIALOG_OPEN_OPTIONS)
    const promptNameModalComponent: PromptNameModalComponent = ref.content
    promptNameModalComponent.title = MESSAGES.NEW_MAP_MODAL_TITLE
    promptNameModalComponent.name = MESSAGES.NEW_MAP_PROPOSAL
    this.cleanupService.addSubscription(
      promptNameModalComponent.onNext.subscribe((name: string) => {
        name = name.split('/').join('')
        if (this.fileSystemService.path === this.fileSystemService.root && name === USER.email) {
          this.notificationService.error(MESSAGES.FOLDER_NAMED_EMAIL_NOT_ALLOWED)
          return
        }
        if (name.includes(":")) {
          this.notificationService.error(MESSAGES.ITEM_NAME_COLON)
          return
        }
        let dirPath = this.fileSystemService.path + "/" + name
        dirPath = dirPath.replace(MY_FILES, USER.email)
        const pathLength = dirPath.length
        if (pathLength > CONSTRAINTS.MAX_PATH_LENGTH) {
          this.notificationService.error(
            formatMsg(MESSAGES.MAX_FOLDER_DEPTH_EXCEEDED, {MAX_PATH_LENGTH: CONSTRAINTS.MAX_PATH_LENGTH, length: pathLength}))
    
          return
        }
        const cmd: ICreateFolderCommand = {
          uid: uuid(),
          commandType: COMMANDS.CreateFolderCommand,
          name,
          path: this.fileSystemService.path
        }
        this.universalCommandService.handle(cmd)
      })
    )

  }

  onError(e: ErrorInfo): void {
    if(e.uri || e['resource'] ) {
      const uri = e.uri || (e['resource'] ? e['resource'].uri : undefined)
      if (!uri) { return }
      const idx = this.explorerDataSourceService.resources.findIndex(r => r.uri === uri)
      if (idx !== -1) {
        this.explorerDataSourceService.resources[idx].errorInfo = e
        this.changeDetectorRef.markForCheck()
      }
    }
  }

  recover(resource: IResource): void {
    const q: IFindResourceByUriQuery = {
      queryType: QUERIES.FindResourceByUriQuery,
      uri: resource.uri,
    }
    this.universalQueryService.query(q).subscribe(r => {
      if (r) { 
        const idx = this.explorerDataSourceService.resources.findIndex(r => r.uri === resource.uri)
        if (idx !== -1) {
          this.explorerDataSourceService.resources[idx] = r
          Object.assign(resource, r)
          resource.errorInfo = undefined
          resource.isNotYetProcessedByServer = false
          this.notificationService.info(
            formatMsg(MESSAGES.ITEM_RELOAD_SUCCEEDED, {name: resource.name}))
          this.closeContextMenu()
          return
        }
      } 

      this.closeContextMenu()
      this.notificationService.warning(
        formatMsg(MESSAGES.ITEM_RELOAD_FAILED, {name: resource.name}))
      this.changeDetectorRef.markForCheck()
    })

  }

  checkIsBulkUploadInProgress() {
    if (this.uploaderService.isBulkUploadProcessingOnServerSignal()) {
      this.notificationService.warning(
        MESSAGES.BULK_UPLOADING_IN_PROGERESS,
        MESSAGES.WARNING_TOAST_TITLE)

      return true 
    }

    return false
  }

}