import { Inject, Injectable } from '@angular/core';
import params from '../configuration/params.json';

/***
 * Primary responsibility of this class is to check whether we are currently in embedded in maple as an iframe or not.
 */
@Injectable({
  providedIn: 'root'
}) class Context {
  url: string;
  qs:any = {};
  constructor () {
    this.url = location.href.toLowerCase();
    this.ensureIsInitialised();

    let parts = this.url.split('?');
    if (parts.length > 1) {
      parts = parts[1].split('&');
      parts.forEach(element => {
        const splitted = element.split('=');
        if (splitted.length > 1) {
          this.qs[splitted[0].toLowerCase()] = splitted[1]
        } else {
          this.qs[splitted[0].toLowerCase()] = '';
        }
      });
      if (this.qs['filtertype']) {
        this.filtertype = this.qs['filtertype'].split(",");
      }
    }
  }

  filtertype: string[] = [];

  _isExternal: boolean = undefined;

  get isExternal () {
    this.ensureIsInitialised();

    return this._isExternal;
  }

  get filterType (): string[] {
    return this.filtertype;
  }

  get embeddedInMaple(): boolean {
    return  document.referrer?.indexOf("maple") !== -1 || document.referrer?.indexOf("localhost:1111") !== -1
  }

  get embeddedInHemlock(): boolean {
    return document.referrer?.indexOf("hemlock") !== -1
  }

  private checkIsExternalOrigin (location: Location) {

    return this.embeddedInMaple || this.embeddedInHemlock;
  }

  ensureIsInitialised () {
    if (this._isExternal === undefined) {
      this._isExternal = this.checkIsExternalOrigin(location);
    }
  }

}

const CONTEXT = new Context();

export { CONTEXT, Context }
