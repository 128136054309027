import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
  private _message = '';
  title = 'Fout';

  constructor(public bsModalRef: BsModalRef, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  set message (value: string) {
    this._message = value;
    this.changeDetectorRef.markForCheck();
  }

  get message(): string {
    return this._message;
  }


  onClose() {
    this.bsModalRef.hide();
  }

}
