import { Component } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-export-options-modal',
  templateUrl: './export-options-modal.component.html',
  styleUrls: ['./export-options-modal.component.scss']
})
export class ExportOptionsModalComponent {
  result = false
  includeURI = false
  originals = false
  flatten = false
  onOKAction: Function

  constructor (public bsModalRef: BsModalRef) {
  }

  onOK () {
    this.result = true
    if (this.onOKAction) {
      this.onOKAction(this.includeURI, this.originals, this.flatten)
    }    
    this.bsModalRef.hide()
  }

  onClose () {
    this.result = false
    this.bsModalRef.hide()
  }

}
