import { Injectable } from '@angular/core';
import { IQuery } from 'prunus-common/dist';
import { Subject } from 'rxjs';
import { TokenManagerService } from './token-manager.service';
import { PARAMS } from '../../params';
import { CleanupService } from '../CleanupService';
import { ErrorHandlingService } from '../ErrorHandlingService';
import { LoggingsService } from '../LoggingsService';
import { SocketService } from '../SocketService';
import { NotificationService } from 'src/infrastructure/services/notification-service';

@Injectable({
  providedIn: 'root'
})
export class UniversalQueryService {

  constructor(
    private errorHandlingService: ErrorHandlingService,
    private logger: LoggingsService,
    private socketService: SocketService,
    private tokenManagerService: TokenManagerService,
    private notificationService: NotificationService
  ) {
  }
  cleanupService = new CleanupService(UniversalQueryService.name);

  query(q: IQuery): Subject<any> {
    const subject = new Subject<string>();
    // need the socket id so wait for connection
    /*this.cleanupService.addSubscription(
      this.socketService.ready$.subscribe
      (*/
        (async () => {
          try {
            q.socketId = this.socketService.socket_id
            q['token'] = this.tokenManagerService.access_token
            const url = `${PARAMS['PRUNUS-QUERY-SERVER']}`;
            const response = await fetch(url, { 
              method: "POST", 
              body: JSON.stringify(q),
              headers: { "authorization" : `Bearer ${this.tokenManagerService.access_token}`, "id-token": this.tokenManagerService.id_token }});
            const data = await response.json();
            if (data?.error) {
              throw new Error(data.error.message)    
            }
            subject.next(data);
          } catch(e) {
            this.logger.error(e)
            this.notificationService.error(e["message"] || e.toString())
          } finally {
            this.socketService.worker?.postMessage({event: 'cmdOrQExec'});
          }
        })();
      //)
   //);

    return subject;
  }
}
