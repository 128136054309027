import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { Context } from '../../infrastructure/Context'
import { v4 as uuid } from 'uuid'
import { PARAMS } from '../../params'
import { IResource } from '../../resource/interface/IResource'
import { EVENTS, RESOURCE_KIND, INewResourceUploadedEvent, IRenamedEvent, isVideoExtension, isDocumentExtension } from 'prunus-common/dist'
import { Subscription } from 'rxjs/internal/Subscription'
import { MY_FILES } from '../constants/MY_FILES'
import { USER } from '../../user/USER'
import { ServerSocketService } from '../../infrastructure/services/server-socket.service'
import { CleanupService } from '../../infrastructure/CleanupService'
import { LoggingsService } from '../../infrastructure/LoggingsService'
import { ErrorHandlingService } from '../../infrastructure/ErrorHandlingService'
import { PRESIGNER_CACHE, s3urlpresigner } from '../../infrastructure/services/s3urlpresigner'
import { AWSCredentialService } from '../../infrastructure/services/AWSCredentialService'

@Component({
  selector: 'app-asset-thumb',
  templateUrl: './asset-thumb.component.html',
  styleUrls: [ './asset-thumb.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetThumbComponent implements AfterViewInit, OnDestroy, OnInit {
  private _resource: IResource
  @Output()
  openDetail: EventEmitter<Event> = new EventEmitter<Event>()
  @ViewChild("thumbImg") el: ElementRef
  subscription: Subscription
  cleanupService = new CleanupService(AssetThumbComponent.name)
  thumbUrl: string
  videoUrl: string

  constructor (
    public context: Context,
    public router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private serverSocketService: ServerSocketService,
    private log: LoggingsService,
    private errorHandlingService: ErrorHandlingService,
    private awsCredentialService: AWSCredentialService
  ) {
  }

  public get resource(): IResource {
    return this._resource
  }

  cloneResource() {
    this._resource = { ...this._resource }
  }


  @Input()
  public set resource(value: IResource) {
    if (value.name === USER.email /*|| value.oldMail === USER.email*/) {
      value.name = MY_FILES
    }
    this._resource = value

    if (this._resource.processedByServer$) {
      if (this.subscription) {
        this.subscription.unsubscribe()
      }
      this.subscription = this._resource.processedByServer$.subscribe(r => {
        if(r.signature) {
          this.resource = r
          this.cloneResource()
          this.changeDetectorRef.markForCheck()
        }
      })
    }

    this.calculateThumb()
    this.cloneResource()
    this.changeDetectorRef.markForCheck()
  }

  ngOnInit(): void {
    this.cleanupService.addSubscription(this.serverSocketService.subscribe2ServerEvents().subscribe(
      async (evt) => this.onServerEvent(evt)
    ))

    this.cleanupService.addSubscription(
      this.errorHandlingService.errors$.subscribe(e => {
        const uri = e.uri || e["resource"]?.uri
        if (this._resource.uri === uri) {
          this._resource["error"] = e
          this.cloneResource()
          this.changeDetectorRef.markForCheck()
        }
      })
    )
  }

  ngAfterViewInit(){
    if (this.el)
    this.el.nativeElement.addEventListener("onerror", this.retry)
  }

  ngOnDestroy () {
    if (this.el) this.el.nativeElement.removeEventListener("onerror", this.retry)
    this.cleanupService.cleanupSubscriptions()
  }

  get s3Bucket(): string {
    return location.hostname.includes("e-ducate.me") ? "prunus-cache": "prunus-test-cache"
  }

  get uri (): string {
    return this.resource.uri
  }

  isCalculatingThumb = false
  calculateThumb() {
    if (this.isCalculatingThumb) return
    if (this.resource.thumbnail && this.resource.thumbnail.startsWith('data:image/')) {
      this.thumbUrl = this.resource.thumbnail
    }

    if(this.isDocument) {
      this.thumbUrl =  `./assets/${this.resource.extension.toLowerCase()}.svg`
      this.changeDetectorRef.markForCheck()


      setTimeout(async () => {
        try {
          this.isCalculatingThumb = true
          this.thumbUrl =  await s3urlpresigner(`${this.resource.uri}-0-coverThumb`, this.s3Bucket, this.resource, this.awsCredentialService)
          this.log.debug("presignedurl", this.thumbUrl)
        } catch(e) {
          this.log.error(e)
          this.thumbUrl =  `./assets/${this.resource.extension.toLowerCase()}.svg`
        } finally {
          this.isCalculatingThumb = false
        }
        this.log.debug("signedurl", this.thumbUrl)
        this.changeDetectorRef.markForCheck()
      }, 200)

      return
    } else if (this.isVideo) {
      setTimeout(async () => {
        this.videoUrl = await this.calculateVideoUrl()
        this.resource["videoUrl"] = this.videoUrl
        this.changeDetectorRef.markForCheck()
      }, 0)
    }

    if (this.resource.signature) {
      this.thumbUrl = `${PARAMS["PRUNUS-IMG-SERVER"]}/thumb/${this.uri}?signedUri=${encodeURIComponent(this.resource.signature.signedUri)}&signedTimestamp=${this.resource.signature.signedTimestamp}&version=${this.resource ? this.resource.version : 1}`
      this.changeDetectorRef.markForCheck()
    }
  }

  getThumb(): string | Blob {
    return this.thumbUrl
  }

  docStyle(): string {
    return `svg-${this.resource.extension.toLowerCase()}`
  }

  get resourceType(): string {
    const removeTrailingX = str => str.endsWith('x') ? str.slice(0, -1) : str
    if (this.isVideo) {
      return 'video'
    }
    if (this.isPDF) {
      return 'pdf'
    }
    if (this.isSVG) {
      return 'svg'
    }
    if (this.isImage) {
      return 'image'
    }
    if (this.isDocument) {
      return removeTrailingX(this.resource.extension?.toLowerCase())
    }
    return '';
  }


  get isDocument(): boolean {
    return isDocumentExtension(this.resource.extension?.toLowerCase())
  }

  get isSVG(): boolean {

    return this.resource?.extension?.toLowerCase() === 'svg'
  }

  get isImage(): boolean {

    return !this.isSVG && !this.isDocument
  }

  get isPDF(): boolean {

    return this.resource.extension.toLowerCase() === 'pdf'
  }

  get isVideo() : boolean {

    if (this._resource.kind === RESOURCE_KIND.FOLDER) {

      return false
    }

    return isVideoExtension(this._resource.extension)
  }

  async calculateVideoUrl(): Promise<string> {
    try {
      const Bucket =  "prunus-test-orig-master"
      const Key = `${this.resource.uri}.orig`
      const cacheKey = `${Bucket}/${Key}`
      if (PRESIGNER_CACHE[cacheKey]) {
        return PRESIGNER_CACHE[cacheKey]
      }
      return await s3urlpresigner(Key, Bucket, this.resource, this.awsCredentialService)
    } catch(e) {
      this.log.error("getVideoUrl", e)
      return Promise.reject(e)
    }
  }

  get videoType(): string {
    return `video/${this.resource?.extension}`
  }

  import (): void {
    window.parent.postMessage([ `${PARAMS['PRUNUS-IMG-SERVER']}/img/${this.resource.uri}`, location.href ], '*')
  }

  backUrlPart (): string {
    return location.href
  }

  getQS(resource: IResource) {
    let path = resource.path
    if (!path.endsWith("/")) {
      path += "/"
    }

    let name = resource.name
    if (name === MY_FILES) {
      name = USER.email
    }

    path += name

    path = encodeURIComponent(path)
    return { path }
  }

  times = 0
  retry() {
    if (this.times > 5 ) { return }
    setTimeout(async () => {
      try {
        this.el.nativeElement.src = `${this.getThumb()}&force_reload=${uuid()}`
        this.changeDetectorRef.markForCheck()
        console.log(`retry reload img ...`)
      } catch(e) {
        console.log(`retry reload img failed ...`, e)
      }
    }, 200)
  }

  @HostListener('dblclick', ['$event']) onClick(e) {
    if(this.openDetail.observers.length && this.resource.kind === RESOURCE_KIND.FILE) {
     this.openDetail.next(e.target)
    }
  }

  onServerEvent(evt) {
    this.log.debug(`onServerEvent received : ${JSON.stringify(evt)}`)
    if ([EVENTS.ExportProgressEvent, EVENTS.ResourceExportedEvent].includes(evt.eventType)) {
      return
    }
    const didWeTriggerEvent = (USER && (evt['userInfo'] && (evt['userInfo'].email === USER.email)))

    switch (evt.eventType) {
      case EVENTS.NewUploadedEvent: {
        const newUploadedEvent: INewResourceUploadedEvent = evt as INewResourceUploadedEvent
        if (newUploadedEvent.uri === this._resource.uri ||
          (newUploadedEvent.resource?.name === this._resource.name && newUploadedEvent.resource?.path === this._resource.path )
          ){
          this._resource.signature = newUploadedEvent.resource.signature
          if (newUploadedEvent.uri !== this._resource.uri) {
            console.log(`preexisting resource uri changed on client ${this._resource.uri} <= ${newUploadedEvent.uri}`)
            this._resource.uri = newUploadedEvent.uri
          }
          this.calculateThumb()
          this.cloneResource()
          this.changeDetectorRef.markForCheck()
        }
        break
      }
      case EVENTS.RenamedEvent: {
        const renamedEvent: IRenamedEvent = evt as IRenamedEvent
        if (renamedEvent.resource.uri === this._resource.uri ||
          (renamedEvent.resource?.name === this._resource.name && renamedEvent.resource?.path === this._resource.path )
          ){
          this.resource.name = renamedEvent.resource.name
          this.cloneResource()
          this.changeDetectorRef.markForCheck()
        }
        break
      }
    }
  }

  get altText(): string {
   return this.resource.errorInfo ? this.resource.errorInfo.message : this.resource.name
  }

}
