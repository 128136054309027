
<div
  #thumbs
  class="tiles-container selection-area-container"
  (mousedown)="deSelectAll($event)"
  (contextmenu)="onOpenNewFolderContextMenu($event)"
>
<ng-template #resourceMenu let-resource>
  <section class="cxt-menu" id="resourceMenu">
    <div [hidden]="!canEdit(resource)" class="ctx-menu-item" (click)="delete(resource)">verwijder</div>
    <div [hidden]="!canEdit(resource) || explorerMultiSelectService.selectedResources.length > 1" class="ctx-menu-item" (click)="rename(resource)">hernoem ...</div>
    <div [hidden]="!canEdit(resource)" class="ctx-menu-item" (click)="move(resource)">verplaats ...</div>
    <div [hidden]="!canLinkPublication" class="ctx-menu-item" (click)="linkPublicationId(resource)">link publicatie(s) ...</div>
    <div [hidden]="!canImport(resource)  || explorerMultiSelectService.selectedResources.length > 1" class="ctx-menu-item" (click)="import(resource)">import in maple</div>
    <div *ngIf="resource && resource.kind === 'FOLDER' && explorerMultiSelectService.selectedResources.length < 2 " class="ctx-menu-item" (click)="openDetail(resource)">map details</div>
    <div [hidden]="!isAdmin" class="ctx-menu-item" (click)="export(resource)">export ...</div>
    <div [hidden]="!isAdmin" class="ctx-menu-item" (click)="forceDelete(resource)">verwijder geforceerd ...</div>
  </section>
</ng-template>
<ng-template #newFolderMenu >
  <section class="cxt-menu" id="newFolderMenu">
    <div class="ctx-menu-item" (click)="onCreateNewFolder()">nieuwe map...</div>
  </section>
</ng-template>
<ng-template #recoverMenu let-resource>
  <section class="cxt-menu" id="recoverMenu">
    <div class="ctx-menu-item" (click)="recover(resource)">probeer te herstellen</div>
  </section>
</ng-template>
<app-file-folder-drop-zone
  [canUpload]="canUpload"
  [allowedResourcesExtensions]="allowedResourcesExtensions"
  >
</app-file-folder-drop-zone>
<div class="search-results d-flex p-2 flex-row flex-wrap"
    infiniteScroll
    [scrollWindow]="false"
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="explorerDataSourceService.fetchMore($event)">

  <app-asset-thumb *ngFor="let resource of explorerDataSourceService.resources; trackBy: trackByFunction"
                   [canGoToDetailAndUpload]="canGoToDetailAndUpload"
                   [resource]="resource"
                   [token]="tokenManagerService.token"
                   class="thumb"
                   (openDetail)="openDetail(resource)"
                   (contextmenu)="onOpenContextMenu($event, resource)"
                   [attr.data-uri]="resource.uri">
  </app-asset-thumb>

</div>
  <div *ngIf="explorerDataSourceService.isLoadingSignal()" class="spinner-container">
    <edu-icon-sync class="edu-spin-counter"color="black" size="65px" type="filled"></edu-icon-sync>
  </div>
</div>

