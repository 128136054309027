import { Component, HostListener, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CONSTRAINTS } from 'prunus-common/dist/constants/CONSTRAINTS';
import { Subject } from 'rxjs/internal/Subject';
import { KeyCodes } from '../../infrastructure/keyboard/KeyCodes';
import { MY_FILES } from '../constants/MY_FILES';

@Component({
  selector: 'app-prompt-name-modal',
  templateUrl: './prompt-name-modal.component.html',
  styleUrls: ['./prompt-name-modal.component.scss']
})
export class PromptNameModalComponent implements OnInit {

  name = '';
  title = 'nieuwe naam';
  message: string;
  onNext = new Subject<string>();
  onCancel = new Subject<void>();
  MAX_LENGTH = CONSTRAINTS.MAX_RESOURCE_NAME_LENGTH;
  MY_FILES_NAME = MY_FILES;

  constructor(public bsModalRef: BsModalRef,) {
  }

  ngOnInit() {
  }

  onSave(): void {
    this.onNext.next(this.name);
    this.onClose();
  }

  onClose() {
    this.onCancel.next();
    this.bsModalRef.hide();
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: any) {
    if (event.keyCode === 13) {
      this.onSave();
    }
  }

  onKeyPress($event) {
    if ($event.key === '/') {
      return false;
    }

    return true;
  }

}
