import { Injectable } from '@angular/core';
import { QUERIES } from 'prunus-common/dist';
import { Subject } from 'rxjs/internal/Subject';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { CleanupService } from '../../infrastructure/CleanupService';
import { UniversalQueryService } from '../../infrastructure/services/universal-query.service';
import { PARAMS } from '../../params';
import { IResource } from '../../resource/interface/IResource';


export interface IPathTuple {
  newPath: string,
  oldPath: string
}
@Injectable({
  providedIn: 'root'
})
export class FileSystemService {
  parentFolderId: number
  pathChange$ = new Subject<IPathTuple>()
  baseUrlChange$ = new Subject<void>() // when switching between tiles and files
  cleanupService = new CleanupService(FileSystemService.name)
  ROOT = `/${PARAMS['ROOT_FOLDER']}`
  root = this.ROOT
  _path = this.ROOT

  get path() {
    return this._path
  }

  set path(value) {
    const oldPath = this._path
    if (!value) {
      this._path = this.ROOT
    } else {
      this._path = value
    }
    if (oldPath !== this._path) {
      console.log('path changed to ', value)
      this.pathChange$.next({newPath : value, oldPath})
    }

  }
}
