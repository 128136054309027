import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs/internal/Subject';
import {KeyCodes} from './KeyCodes';
import {Shortcut} from './Shortcut';
import {EnvironmentService} from './EnvironmentService';

@Injectable({
  providedIn: 'root'
})
export class ShortcutService {
  // cast it to observable for client usage,
  // the client is not supposed to trigger the subject (emit)
  get shortcutStream$(): Observable<Shortcut> {
    return this._shortcutStream$.asObservable();
  }

  isMac: boolean;
  private _shortcutStream$: Subject<Shortcut> = new Subject<Shortcut>();

  constructor(private environmentService: EnvironmentService) {
    this.isMac = environmentService.isMacintosh();
    document.addEventListener('keydown', (evt: KeyboardEvent) => {
      const target: Element = evt.target as Element;
      // when delete, copy, cut, paste is pressed in an inputfield in properties panel,
      // don't interfere fabric objects on stage
      const metaOrCtrl: boolean = this.isMac ? evt.metaKey : evt.ctrlKey;
      const isPasting: boolean = metaOrCtrl && evt.keyCode === KeyCodes.KEY_V;
      const isCopying: boolean = metaOrCtrl && evt.keyCode === KeyCodes.KEY_C;
      const isCutting: boolean = metaOrCtrl && evt.keyCode === KeyCodes.KEY_X;
      const isUndo: boolean = metaOrCtrl && evt.keyCode === KeyCodes.KEY_Z;
      const isRedo: boolean = metaOrCtrl && evt.shiftKey && evt.keyCode === KeyCodes.KEY_Z;
      if (evt.keyCode === KeyCodes.KEY_DELETE || isPasting || isCopying || isCutting) {
        if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA' || target.className === 'ql-editor') {
          return;
        }
      }

      this.handleKeyDown(evt);
    });
  }

  private handleKeyDown(keyEvent: KeyboardEvent): void {
    const keycode: number = keyEvent.keyCode || keyEvent.which;
    const ctrl: boolean = keyEvent.ctrlKey;
    const meta: boolean = keyEvent.metaKey;
    const alt: boolean = keyEvent.altKey;
    const shift: boolean = keyEvent.shiftKey;

    const simpleKeys: number[] = [
      KeyCodes.KEY_UP,
      KeyCodes.KEY_RIGHT,
      KeyCodes.KEY_DOWN,
      KeyCodes.KEY_LEFT,
      KeyCodes.KEY_DELETE,
      KeyCodes.KEY_PAGE_UP,
      KeyCodes.KEY_PAGE_DOWN,
      KeyCodes.KEY_F5,
      KeyCodes.KEY_ESCAPE,
    ];

    if (ctrl || meta || alt || shift || simpleKeys.indexOf(keycode) !== -1) {
      this._shortcutStream$.next(new Shortcut(keycode, ctrl || meta, this.isMac, alt, shift, keyEvent));
      if (meta || ctrl) {
        switch (keycode) {
          case KeyCodes.KEY_O:
          case KeyCodes.KEY_S:
            keyEvent.preventDefault();
            break;
        }
      }
    }
  }
}
