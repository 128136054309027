<div class="splash container-fluid text-center">
  <div>
    <div class="splash-content text-center">
      <img src="../../../../assets/images/educate-me.png" alt="e-ducate.me" title="e-ducate.me" class="margin-top-large margin-bottom-large"/>
      <h1>Oeps! Deze pagina werd niet gevonden.</h1>
      <p>De pagina die u bereikte werd niet gevonden.</p>
      <a href="/" class="btn btn-primary">Verdergaan</a>
    </div>
    <div class="mt-5 splash-content text-left">
      <iframe src="http://notfound-static.fwebservices.be/404/index.html?&key=0dae91c86ed697243eb1594b7816af93"
              width="100%" height="650"
              frameborder="0"
              class="margin-top-large"
      >
      </iframe>
    </div>
  </div>
</div>
