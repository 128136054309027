import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class TokenManagerService {

  get id_token(): string {
    return global["id_token"]
  }
  
  get access_token(): string {
    return global["access_token"]
  }

}
