const MESSAGES = {
    ENTER_READONLY_MODE: "U bent op meerdere apparaten of tabbladen actief. Slechts in het originele tabblad kan u wijzigen doorvoeren. Sluit het originele tabblad of werk hier verder in read-only modus.",
    EXIT_READONLY_MODE: "Deze tab verlaat de readonly modus, u kan hier opnieuw wijzigen.",
    READONLY_MODE_MSG: "U bent op meerdere aparaten of tabs geconnecteerd, u kan slechts in de originele tab wijzigingen maken. Of sluit de andere tabs om hier te kunnen wijzigen.",
    ERROR_TITLE: "Fout opgetreden.",
    UNKNOWN_ERROR_TILE: "Onbekende fout.",
    DELETE_ACTION_STARTED: "Verwijder operatie gestart: '${names}' ...",
    RESOURCE_DELETE_REQ_SENT: "Aanvraag item: '${name}' verwijderen succesvol verzonden.",
    RESOURCE_NOT_YET_PROCESSED: "Kan beeld niet verplaatsen, probeer het opniew later als het beeld door server verwerkt is",
    RESOURCE_NOT_YET_PROCESSED_TILE: "Item is nog niet verwerkt door server.",
    PROGRESS_DEL_BULK_TILE: "Verwijderen in bulk progressie.",
    PROGRESS_MOVE_BULK_TILE: "Verplaatsen in bulk progressie.",
    BULK_UPLOAD_SUCCESS: "De server heeft de nieuw geuploaded items successvol verwerkt !",
    UNAUTHORIZED: "U heeft onvoldoende rechten om prunus te starten. U dient leraar of admin te zijn. Testers hebben geen toegang meer.",
    EMAIL_CHANGED: "Uw email adres is veranderd in redwood van '${old_value}' naar '${new_value}', u wordt automatisch uitgelogd ...",
    STRUCTURE_2_DEEP: "Gelieve uw folder structuur minder diep te maken. De nieuwe naam ${value} zou de maximale pad lengte (${MAX_PATH_LENGTH}) overschrijden.",
    TAGS_ALFA_NUM: "Tags mogen enkel letters en cijfers bevatten.",
    SRV_SOCKET_CON_LOST: "Verbinding met de server is verbroken. Verifiëer uw internet connectie.",
    MOVE_SUCCESS: "Item(s): '${name}' succesvol verplaatst naar de locatie: '${destination}'.",
    SRV_MSG_TOAST_TITLE: "Server verwerking succesvol.",
    RENAME_ITEM_SUCCESS: "Bestandsnaam is veranderd naar: '${name}'.",
    NEW_FOLDER_CREATED: "Nieuwe map: '${name}' aangemaakt.",
    TEACHER_MYFILES_CREATED: "Er is een map voor u gemaakt, waar u uw eigen bestanden in kan bewaren, de map : '${MY_FILES}'.",
    ON_SRV_CREATED_TITLE: "Op de server gecreëerd.",
    ITEM_UPDATE_SUCCESS: "Item succesvol aangepast.",
    DELETE_ITEM_NOT_YET_PROCESSED: "Kan beeld niet verwijderen, probeer het opniew later als het beeld door server verwerkt is.",
    ITEM_NOT_YET_PROCESSED_TOAST_TITLE: "Item is nog niet verwerkt door server.",
    LINK_ITEM_NOT_YET_PROCESSED: "Kan item nog niet aan publicatie linken, probeer het opniew later als het beeld door server verwerkt is.",
    EXPORT_ITEM_NOT_YET_PROCESSED: "Kan beeld niet exporteren, probeer het opniew later als het beeld door server verwerkt is.",
    START_CREATE_FOLDERS_ON_UPLOAD: "Start met aanmaken map structuur.",
    FOLDER_ALREADY_EXISTS: "De map: '${name}' bestond reeds.",
    DELETE_ITEM_SUCCESS:  "Verwijderen item(s): '${name}' is gelukt.",
    MAX_SELECTED_ITEMS_EXCEEDED: "Maximum aantal geselecteerde items: ${MAX_RESOURCES_SELECTED overschreden.",
    TAG_MAX_LENGTH_EXCEEDED: "Tag zoekterm karakter lengte : ${MAX_RESOURCE_NAME_LENGTH}, huidige lengte ${length}.",
    MAX_FOLDER_DEPTH_EXCEEDED: "De maximum folder diepte is overschreden. Het pad mag maximum ${MAX_PATH_LENGTH} karakters lang zijn. Het huidige pad is ${length} karakters lang. Gelieve uw folder structuur minder diep te maken.",
    ITEM_UNMOVEABLE: "Kan item niet verplaatsen naar de huidige folder.",
    WARNING_TOAST_TITLE: "Waarschuwing.",
    WARNING_INVALID_MOVE_TARGET: "Kan item(s) niet verplaatsen naar een folder die zelf verplaatst gaat worden",
    MAX_PATH_DEPTH_VIOLATION: "De item: '${name}' overschrijdt maximum pad diepte. Het pad mag maximum ${MAX_PATH_LENGTH} karakters lang zijn. Het huidige pad is ${pathLength} karakters lang. Gelieve uw folder structuur minder diep te maken.",
    SRV_RECEIVED_DEL_REQ: "De server heeft uw aanvraag tot verwijderen goed ontvangen.",
    CLIPBOARD_CPY: "Tekst : ${value} naar klipbord gekopieerd.",
    ITEM_NAME_2_LONG : "De nieuwe naam: '${value}' zou de maximale naam lengte (${MAX_RESOURCE_NAME_LENGTH}) overschrijden.",
    ITEM_RELOAD_FAILED: "Item: '${name}' kon niet herladen worden.",
    ITEM_RELOAD_SUCCEEDED: "Item: '${name}' succesvol heringeladen.",
    ITEM_IN_USE_TOAST_TITLE: "Item is in gebruik.",
    MAPLE_USAGE: "Kan item niet verwijderen, het is in gebruik in Maple. Scènes : ${scenes}.",
    CREATE_FOLDER_NOT_ALLOWED: "Leerkrachten mogen enkel in hun eigen map: '${MY_FILES}' nieuwe mappen aanmaken.",
    FOLDER_NAMED_EMAIL_NOT_ALLOWED: "Actie niet toegestaan, u mag geen folder met als naam uw email aanmaken in de root.",
    NEW_MAP_MODAL_TITLE: "Nieuwe map",
    NEW_MAP_PROPOSAL: "naamloze map",
    EXPORT_MODAL_TITLE: "Export van bestanden",
    EXPORT_MESSAGE: "We maken een zip file voor u klaar. Vanzodra die gereed is, wordt uw download automatisch gestart. We hebben u eveneens de link gemaild, voor in het geval deze download zou mislukken. Hou er rekening mee dat de link slechts 1 uur geldig is. Even geduld a.u.b., deze operatie kan wel enige tijd (rechtevenredig met de geselecteerde hoeveelheid) in beslag nemen.",
    MAX_LENGTH_ITEM_NAME: "Maximum lengte bestandsnaam is ${MAX_RESOURCE_NAME_LENGTH} karakters. De huidige lengte is ${length}.",
    USER_CHANGED: "U bent nu aangelogd als '${USER_NAME}'.",
    NON_ACTIVITY: "De app heeft lange tijd geen activiteit meer geregistreerd en gaat nu in slaap modus. Sluit deze popup om ze te herladen.",
    SAFARI: "Safari wordt niet ondersteund, wij bevelen u graag Google chrome aan.",
    SOCKET_CLOSE_BY_FORCE: "Socket geforceerd moeten sluiten",
    NETWORK_DRIVE_NOT_SUPPORTED: "Netwerk drive drag & drop niet ondersteund, drop van locale locatie.",
    UPLOAD_SHOULD_BE_UNIQUE: "Je kan hetzelfde item: '${name}' geen meerdere malen opladen.",
    ITEM_NAME_COLON: "Een item mag geen : in de naam hebben.",
    BULK_UPLOADING_IN_PROGERESS: "Uw bulk upload is nog niet klaar met de verwerking. U kan momenteel geen nieuwe actie(s) ondernemen. U dient even te wachten tot wanneer de verwerking klaar is.",
    ERR_MAX_SOCKET_CONNECT_RETRIES: "De server websocket connectie kan niet verbonden worden, probeert u de pagina eens te herladen. Of probeer het later eens opnieuw.",
    WARNING_CURRENT_PATH_NO_CHANGE: "Kan pad niet wijzigen naar het huidige pad.",
}

const formatMsg = (templateString, templateVars) => {
    var func = new Function(...Object.keys(templateVars),  "return `"+templateString +"`;")
    return func(...Object.values(templateVars))
}

export {MESSAGES, formatMsg}