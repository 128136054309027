import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'

export interface INotification {
  date: Date,
  text: string,
  level: string
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notifications: INotification[] = []

  constructor(protected toastrService: ToastrService,) { 

  }

  show(message?: string, title?: string, override?: any, type?: string) {
    this.notifications.push({text: message, level: "", date: new Date()})
    this.toastrService.show(message, title, override, type)
  }
  
  success(message?: string, title?: string, override?: any) {
    this.notifications.push({text: message, level: "success", date: new Date()})
    this.toastrService.success(message, title, override)
  }
  
  error(message?: string, title?: string, override?: any) {
    this.notifications.push({text: message, level: "error", date: new Date()})
    this.toastrService.error(message, title, override)
  }
  
  info(message?: string, title?: string, override?: any) {
    this.notifications.push({text: message, level: "info", date: new Date()})
    this.toastrService.info(message, title, override)
  }
  
  warning(message?: string, title?: string, override?: any) {
    this.toastrService.warning(message, title, override)
  }
}
