import { ChangeDetectorRef, Component } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { Subject, Subscription } from 'rxjs'
import { ErrorHandlingService } from 'src/infrastructure/ErrorHandlingService'
import { ErrorInfo } from 'src/infrastructure/ErrorInfo'
import { INotification, NotificationService } from '../../infrastructure/services/notification-service'

@Component({
  selector: 'app-notifications-modal.component',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.scss']
})
export class NotificationsModalComponent {
  title = "Notificaties info"

  get errors(): ErrorInfo[]  {
    return this.errorHandlingService?.errors;
  }

  get notifications(): INotification[] {
    return this.notificationService.notifications
  }
  
  subscription : Subscription
  closed$ = new Subject<void>()
 
  constructor(
    private errorHandlingService: ErrorHandlingService, 
    private changeDetectorRef: ChangeDetectorRef, 
    private notificationService: NotificationService,
    public bsModalRef: BsModalRef,) {}
   

  ngOnInit() {
    this.bsModalRef.onHidden.subscribe(() => {
      this.subscription?.unsubscribe()
      this.closed$.next()
    })
  }
  
  onClose() {
    this.bsModalRef.hide()
    this.subscription?.unsubscribe()
    this.closed$.next()
  }

}
