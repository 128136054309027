import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router'
import Autocomplete from '@trevoreyre/autocomplete-js'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { CONSTRAINTS, DEFAULT_PAGE_SIZE, ISuggestTagsQuery, OPERATORS, QUERIES } from 'prunus-common/dist'
import { Subject } from 'rxjs/internal/Subject'
import { filter } from 'rxjs/operators'
import { MESSAGES, formatMsg } from '../../MESSAGES'
import { CleanupService } from '../../infrastructure/CleanupService'
import { CONTEXT, Context } from '../../infrastructure/Context'
import { DIALOG_OPEN_OPTIONS } from '../../infrastructure/DialogOptions'
import { LoggingsService } from '../../infrastructure/LoggingsService'
import { SocketService } from '../../infrastructure/SocketService'
import { FileSystemService } from '../../infrastructure/services/FileSystemService'
import { UniversalQueryService } from '../../infrastructure/services/universal-query.service'
import { USER } from '../../user/USER'
import { User } from '../../user/model/User'
import { AdvancedSearchModalComponent } from '../advanced-search-modal/advanced-search-modal.component'
import { TAG_REGEX } from '../constants/TAG_REGEX'
import { RecentTagsService } from '../../infrastructure/services/recent-tags.service'
import { TilesDataSourceService } from '../tiles-view/tiles-data-source.service'
import { TokenManagerService } from '../../infrastructure/services/token-manager.service'
import { UploaderService } from '../upload-queue/UploaderService'
import { AssetOverviewPageGuide } from './main.page-guide'
import { UserDetailComponent } from '../user-detail/user-detail.component'
import { NotificationsModalComponent } from '../notifications-modal.component/notifications-modal.component'
import { Subscription } from 'rxjs'
import { NotificationService } from '../../infrastructure/services/notification-service'

declare function getSSOUrl()
declare function logoutFromSSO(overrule_url?: string)
declare function hideSSO()


let autocomplete, orig_handleResultClick
const handleResultClick = (event) => {
    event.preventDefault()
    orig_handleResultClick(event)
}

const boldSubstring = (term: string, result: string): string =>  {
  // If the term is empty or not found in the item, return the original item
  if (!term || result.indexOf(term) === -1) {
      return result;
  }

  // Replace the term with the bolded version
  const boldedResult = `<b>${term}</b>`
  const boldedItem = result.replace(new RegExp(term, 'gi'), boldedResult)
  
  return boldedItem
}
 
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: [ './main.component.scss' ],
})
export class MainComponent implements OnInit, OnDestroy {
  private _searchTerms = new Set<string>()

  get searchTerms(): string[] {
    return Array.from(this._searchTerms)
  }

  set searchTerms(values: string[]) {
    this._searchTerms.clear()
    values.forEach(v => {
      this._searchTerms.add(v)
    })
  }

  or = true
  suggestions$ = new Subject<string[]>()
  suggestionsLoading = false
  suggestionsPublicationsLoading = false
  isFileExplorer = false
  isTiles = false
  suggestionsInput$ = new Subject<string>()
  suggestionsInputPublications$ = new Subject<string>()
  pageGuideData: any[] = new AssetOverviewPageGuide().data
  filtertype: string[] = []
  users: string[] = []
  user: User
  private cleanupService = new CleanupService(MainComponent.name)
  @ViewChild('filter', {static: false})
  private filter: any
  @ViewChild('body', {static: false})
  private body: ElementRef
  @ViewChild(RouterOutlet, {static: false}) outlet: RouterOutlet
  currentSuggestions: string [] = []
  public MAX_SELECTED_ITEMS = CONSTRAINTS.MAX_NR_OF_USER_TAGS
  autocomplete_input: any
  uploadErrorsModalComponent: NotificationsModalComponent
  s: Subscription

  constructor (
    private loggingsService: LoggingsService,
    public context: Context,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    public fileSystemService: FileSystemService,
    private universalQueryService: UniversalQueryService,
    private modalService: BsModalService,
    public uploaderService: UploaderService,
    public tilesDataSourceService: TilesDataSourceService,
    private changeDetectorRef: ChangeDetectorRef,
    private recentTagsService: RecentTagsService,
    private tokenManagerService: TokenManagerService,
    private socketService: SocketService,
  ) {    
    this.user = USER 
  }

  get publication_ids(): number[] {
    return this.tilesDataSourceService.publication_ids
  }

  set publication_ids(value: number[]) {
    this.tilesDataSourceService.publication_ids = value
  }

  toggleTriangle() {
    if (!this.uploadErrorsModalComponent) {
      const ref = this.modalService.show(NotificationsModalComponent, {})
      this.uploadErrorsModalComponent = ref.content
      this.s = this.uploadErrorsModalComponent.closed$.subscribe(() => {
        this.uploadErrorsModalComponent = undefined
      })
    } else {
      this.uploadErrorsModalComponent?.onClose()
      this.s?.unsubscribe()
      this.uploadErrorsModalComponent = undefined
 
    }
  }

  handleEnterKeySearch() {
    const value = this.autocomplete_input.value
    if (value) {
      value.split(" ").forEach(part => {
        if (part) {
          if(this.validateTag(part)) {
            this._searchTerms.add(part)
          }
        }  
      })
      
    }
    this.doSearch(this.searchTerms)
    this.autocomplete_input.value = ""
  }

  ngOnInit (): void {
    this.isFileExplorer = location.pathname.indexOf("/explorer") !== -1
    this.isTiles = location.pathname.indexOf("/tile") !== -1
    this.cleanupService.addSubscription(
      this.activatedRoute.queryParams.subscribe((params: any) => {
        this.searchTerms = []
        if (params.tags) {
          this.searchTerms = decodeURIComponent(params.tags).split(',')
        }
        this.tilesDataSourceService.or = (params[OPERATORS.OR] === 'true' || params[OPERATORS.OR] === undefined)
        if (params.path) {
          this.fileSystemService.path = decodeURIComponent(params.path)
        }
        if (params.filtertype) {
          this.filtertype = (params.filtertype ? decodeURIComponent(params.filtertype).split(',') : [])
        } else {
          this.filtertype = []
        }
        if (params.users !== undefined) {
          this.users = (params.users ? decodeURIComponent(params.users).split(',') : [])
        }
        this.changeDetectorRef.markForCheck()
      })
    )
    this.cleanupService.addSubscription(
      this.fileSystemService.pathChange$.subscribe(() => this.changeDetectorRef.markForCheck())
    )
    this.cleanupService.addSubscription(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)  
      ).subscribe((event) => {
        this.isFileExplorer = (event['url'].indexOf("/explorer") !== -1)
        this.isTiles = (event['url'].indexOf("/tile") !== -1)
        this.changeDetectorRef.markForCheck()
      })
    )
    this.uploaderService.iQItems$.subscribe(qItems => {
      this.changeDetectorRef.markForCheck()
    })

    let term = ""
    this.autocomplete_input = document.querySelector(".autocomplete-input") 
    autocomplete = new Autocomplete('#autocomplete', {
      baseClass: 'search',
      submitOnEnter: true, // doesn't work ?
      search: (input: string) => {
        return new Promise(resolve => {
          if (input.length < 2) { // don't fire a backend query for less than 2 chars
            return resolve([])
          }

          term = input
          const q: ISuggestTagsQuery = {
            queryType: QUERIES.SuggestTagsQuery,
            from: 0,
            size: DEFAULT_PAGE_SIZE,
            term,
          }
          const subscription = this.universalQueryService.query(q).subscribe((results: string[]) => {
              const recents = this.recentTagsService.findRecent(input) // tags you used recently but that are not used as a tag on resources
              for(const r of results) {
                if (!this.currentSuggestions.includes(r)) {
                  this.currentSuggestions.push(r)
                }
              }

              resolve([...recents, ...results].sort()) // combine recents with actual searchresults
              const element: any = document.querySelector(".autocomplete-result-list")
              if (element) {
                  element.style.zIndex = "999"
                  element.style.setProperty("z-index", "999", "important")
              }
              subscription.unsubscribe()
          })
        })
      },
      
      onSubmit: result => {
        result.split(" ").forEach(part => {
          if (this.validateTag(part)) {
            this._searchTerms.add(part)
            this.doSearch(this.searchTerms)
          }
        })
        this.autocomplete_input.value = ""
      }, 
      // custom render to highlight searchterm by <b>searchterm</b>  in results
      renderResult: (result, props) => {
        return `
          <li> 
            <div data-result-index="${props['data-result-index']}" id="${props.id}" class="search-result">
              ${boldSubstring(term, result)}
            </div>
          </li>
        `
      }
    })
    orig_handleResultClick = autocomplete.core.handleResultClick
   // autocomplete.resultList.removeEventListener('click', autocomplete.core.handleResultClick)
   // autocomplete.resultList.addEventListener('click', handleResultClick)
  }

  removeTerm(searchTerm) {
    if (this._searchTerms.has(searchTerm)) {
      this._searchTerms.delete(searchTerm)
    }
    this.doSearch(this.searchTerms)
  }

  onOpenPublications() {
    if (!this.suggestionsInput$) {
      return
    }
    this.suggestionsInput$.next('')
  }
  /**
   *  Angular life-cycle event handlers
   */
  ngOnDestroy (): void {
    this.cleanupService.cleanupSubscriptions()
    this.currentSuggestions = []
  }

  isTagValid(tag: string): boolean {
    if(!tag.length || tag.length > CONSTRAINTS.MAX_RESOURCE_NAME_LENGTH || !TAG_REGEX.test(tag)) {
      return false
    }

    return true
  }
  /***
   * when the switch for 'and' or 'or' has been changed
   * @param $event
   */
  doSearch ($event) {
    if (Array.isArray($event)) { 
      for(let tag of $event) {
        if(!this.isTagValid(tag)) {
          return
        }
      }
    } else if (typeof $event === "string") { // todo: not sure whether this is possible
      if(!this.isTagValid($event)) {
        return
      }
    }
    this.tilesDataSourceService.clearData()
    this.router.navigateByUrl(this.buildUrl())
    this.changeDetectorRef.markForCheck()
  }

  onPublicationsChanged ($event) {
    this.tilesDataSourceService.clearData()
    this.router.navigateByUrl(this.buildUrl())
    this.changeDetectorRef.markForCheck()
  }

  buildUrl(): string {
    let url = '/main/tile?'
    url += 'tags=' +  encodeURIComponent(this.searchTerms.join(','))
    url += '&or=' + this.or
    if (this.fileSystemService.path) {
      url += '&path=' +  encodeURIComponent(this.fileSystemService.path)
    }
    if (this.publication_ids.length && !this.isFileExplorer) {
      url += '&pub_ids=' + encodeURIComponent(this.publication_ids.join(',')) 
    } else {
      url += '&pub_ids='
    }

    if (this.filtertype.length) {
      url += "&filtertype=" + encodeURIComponent(this.filtertype.join(',')) 
    }
    return url
  }

  get showHeader(): boolean {
    return location.href.toLowerCase().indexOf('/main/tile/detail/') !== -1
  }

  logout() {
    logoutFromSSO()
  }

  get sizedAvatar(): string {
    return this.user?.avatar
  }

  openAdvancedSearch(): void {
    const ref: BsModalRef = this.modalService.show(AdvancedSearchModalComponent, DIALOG_OPEN_OPTIONS)
    const advancedSearchModalComponent: AdvancedSearchModalComponent = ref.content
    advancedSearchModalComponent.path = this.fileSystemService.path
    advancedSearchModalComponent.or = this.or
    advancedSearchModalComponent.searchTerms = this.searchTerms
    advancedSearchModalComponent.filtertype = this.filtertype
  }

  get showAdvancedSearch(): boolean {
    return !CONTEXT.isExternal && USER.isAdmin
  }

  openSSO(): void {
    window.open(`${getSSOUrl()}`)
  }

  openUserDetail(): void {
    if (USER.isAdmin) {
      const ref: BsModalRef = this.modalService.show(UserDetailComponent, DIALOG_OPEN_OPTIONS)
    }
  }

  get hideUploadQueue() : boolean {
    return this.uploaderService.iQItems.length === 0
  }

  validateTag($event) {
    if ($event && $event.length > CONSTRAINTS.MAX_RESOURCE_NAME_LENGTH) {
      this.notificationService.error(formatMsg(MESSAGES.TAG_MAX_LENGTH_EXCEEDED, {MAX_RESOURCE_NAME_LENGTH: CONSTRAINTS.MAX_RESOURCE_NAME_LENGTH, length: $event.length}))
      this.changeDetectorRef.markForCheck()
      if (this._searchTerms.has($event)) {
        this._searchTerms.delete($event)
        const newItems = [... this.searchTerms]
        this.changeDetectorRef.markForCheck()
      }
      
      return false
    }
    if(!/^[a-zA-ZÀ-ÿ0-9_]*$/.test($event)) {
      this.changeDetectorRef.markForCheck()
      
      if (this._searchTerms.has($event)) {
        this._searchTerms.delete($event)
        const newItems = [... this.searchTerms]
        this.changeDetectorRef.markForCheck()
      }

      this.notificationService.error(MESSAGES.TAGS_ALFA_NUM)
      
      return false
    }
    if (!this.currentSuggestions.includes($event)) {
      this.recentTagsService.add($event)
    }
    
    return true
  }

}
