import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EXTERNAL_APPS, IQuery, IFindResourcesQuery, QUERIES, RESOURCE_KIND, OPERATORS } from 'prunus-common/dist';
import { CleanupService } from '../../infrastructure/CleanupService';
import { CONTEXT, Context } from '../../infrastructure/Context';
import { LoggingsService } from '../../infrastructure/LoggingsService';
import { AbstractDataSourceService } from '../../infrastructure/services/AbstractDataSourceService';
import { ServerSocketService } from '../../infrastructure/services/server-socket.service';
import { UniversalQueryService } from '../../infrastructure/services/universal-query.service';
import { IResource } from '../../resource/interface/IResource';
import { PathDepth } from 'prunus-common/dist';
import { TokenManagerService } from '../../infrastructure/services/token-manager.service';
import { FileSystemService } from '../../infrastructure/services/FileSystemService';
import { intersect } from '../../infrastructure/intersect';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from 'src/infrastructure/ErrorHandlingService';
import { UploaderService } from '../upload-queue/UploaderService';
import { NotificationService } from '../../infrastructure/services/notification-service';

@Injectable({
  providedIn: 'root'
})
export class TilesDataSourceService extends AbstractDataSourceService {
  private _publication_ids: number[] = [];

  users: string[] = [];
  resource_kinds = [RESOURCE_KIND.FILE];
  pathDepth: PathDepth = PathDepth.Deep;
  cleanupService = new CleanupService(TilesDataSourceService.name);

  constructor(        
    protected log: LoggingsService,
    protected fileSystemService: FileSystemService,
    protected serverSocketService: ServerSocketService,
    protected route: ActivatedRoute,
    protected universalQueryService: UniversalQueryService,
    protected noticationService: NotificationService,
    protected tokenManagerService: TokenManagerService,
    protected errorHandlingService: ErrorHandlingService,
    protected modalService: BsModalService,
    protected uploaderService: UploaderService,
  ) { 
    super(log, fileSystemService, serverSocketService, route, universalQueryService, noticationService, tokenManagerService, errorHandlingService, modalService, uploaderService)              
  }

  get publication_ids(): number[] {
    return this._publication_ids;
  }

  public set publication_ids(value: number[]) {
    this._publication_ids = value;
    super.clearData();
  }

  hideDetailIfPresent(): void {}

  onQueryStringChanged(params) {
    super.onQueryStringChanged(params);
    this._publication_ids = params.pub_ids ? decodeURIComponent(params.pub_ids).split(',').map(i => Number(i)) : (this.publication_ids || []);
  }

  initFilters() {
    super.initFilters();
    this.publication_ids = [];
  }

  isResourceInOurPath(resource: IResource): boolean {
    return intersect(resource.publication_ids, this._publication_ids).length > 0;
  }

  get isAddingNewFolder(): boolean {
    return false;
  }

  set isAddingNewFolder(value: boolean) {
  }

  get parentPublicationIds(): number [] {
    return [];
  }

  onQueryResults(results: IResource[], resolve: Function, reject: Function) {
    try {
      if (!Array.isArray(results)) {
        resolve([]);

        return;
      }
      const newResources = results;

      for(const resource of results) {
        resource.url += '?token=' + encodeURIComponent(this.tokenManagerService.access_token);
      }
      resolve(newResources);
    } catch(e) {
      reject(e);
    }
  }

  buildQuery(): IQuery {
    const q: IFindResourcesQuery = {
      queryType: QUERIES.FindResourcesQuery,
      tags: this.searchTerms,
      from: this.from,
      size: this.pageSize,
      operator: this.or ? OPERATORS.OR : OPERATORS.AND,
      filtertype: CONTEXT.filtertype,
      resource_kinds: this.resource_kinds,
      pathDepth: Number(this.pathDepth).toString(),
      users: this.users,
      publication_ids: this.publication_ids
    };

    if (CONTEXT.embeddedInHemlock) {
      q.context = EXTERNAL_APPS.HEMLOCK;
    }

    return q; 
  }

  clearData(): void {
    super.clearData();
  }

  snapshotCurrentFilters(): string {

    return `${this.or}|${this.searchTerms.join(',')}|${this._publication_ids.join(',')}`;
  }

}
