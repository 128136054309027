import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ROLES } from 'redwood-model/dist';
import { USER } from './user/USER';
import { MESSAGES } from './MESSAGES';
import { NotificationService } from './infrastructure/services/notification-service';

@Injectable({
  providedIn: 'root'
})
export class PrunusCanActivate  {

  toastAlreadyShown = false;

  constructor(private notificationService: NotificationService,) {

  }

  async canActivate (route: ActivatedRouteSnapshot): Promise<boolean> {
      const allowed = USER.hasRoles([  ROLES.SCHOOL_TEACHER , ROLES.USER_TEACHER, ...  ROLES.ALL_INTERNAL_USERS]);
      if (!allowed) {
        if (!this.toastAlreadyShown) {
          this.notificationService.show(MESSAGES.UNAUTHORIZED);
          this.toastAlreadyShown = true;
        }
      }
      return allowed;
  }
}
