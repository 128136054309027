import { effect, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IFolderQuery, IQuery, PathDepth, QUERIES, RESOURCE_KIND } from 'prunus-common/dist';
import { CleanupService } from '../../infrastructure/CleanupService';
import { CONTEXT, Context } from '../../infrastructure/Context';
import { LoggingsService } from '../../infrastructure/LoggingsService';
import { naturalCompare } from '../../infrastructure/NaturalCompare';
import { AbstractDataSourceService } from '../../infrastructure/services/AbstractDataSourceService';
import { ServerSocketService } from '../../infrastructure/services/server-socket.service';
import { UniversalQueryService } from '../../infrastructure/services/universal-query.service';
import { IResource } from '../../resource/interface/IResource';
import { TokenManagerService } from '../../infrastructure/services/token-manager.service';
import { EXTERNAL_APPS } from 'prunus-common/dist';
import { FileSystemService } from '../../infrastructure/services/FileSystemService';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from 'src/infrastructure/ErrorHandlingService';
import { UploaderService } from '../upload-queue/UploaderService';
import { NotificationService } from '../../infrastructure/services/notification-service';

const sortFoldersFirstAlphabeticalFn = (r1, r2) => {
  if (r1.kind === r2.kind) {
    return naturalCompare(r1.name, r2.name);
  }

  return r1.kind.toLowerCase() === 'folder' ?  -1 : 1;
}

@Injectable({
  providedIn: 'root'
})
export class ExplorerDataSourceService extends AbstractDataSourceService {
  prefix = `${ExplorerDataSourceService.name}`
  parentPublicationIds: number[] = []
  cleanupService = new CleanupService(ExplorerDataSourceService.name)
  baseUrl = '/main/file?path='
  isAddingNewFolder = false
  uploadRootPath: string

  constructor(
    protected log: LoggingsService,
    protected fileSystemService: FileSystemService,
    protected serverSocketService: ServerSocketService,
    protected route: ActivatedRoute,
    protected universalQueryService: UniversalQueryService,
    protected noticationService: NotificationService,
    protected tokenManagerService: TokenManagerService,
    protected router: Router,
    protected errorHandlingService: ErrorHandlingService,
    protected modalService: BsModalService,
    protected uploaderService: UploaderService,
  ) {
    super(log, fileSystemService, serverSocketService, route, universalQueryService, noticationService, tokenManagerService, errorHandlingService, modalService, uploaderService)

    effect(() => {
      const newUploadRootPath = uploaderService.uploadRootPathSignal()
      if (this.uploadRootPath != newUploadRootPath?.newPath) {
        super.clearData()
        super.fetchMore()
        this.uploadRootPath = newUploadRootPath?.newPath
      }
    },
    { allowSignalWrites: true }) // fetchMore writes to isLoadingSignal, without this option => error
  }

  onQueryStringChanged(params: any): void {
    super.onQueryStringChanged(params);
    this.path = decodeURIComponent(params.path);
  }

  initFilters() {
    super.initFilters();
  }

  /*
  * hides the detail (by navigating away from detail child route) on the rightside,
  * if a current resource is selected
  * */
  hideDetailIfPresent() {
    let url = location.href.toLowerCase();
    const start = url.indexOf('/detail/');
    if (start !== -1) {
      url = this.baseUrl + this.path;
      this.router.navigateByUrl(url);
    }
  }

  onQueryResults(results: IResource[], resolve: Function, reject: Function) {
    try {
      if (!Array.isArray(results)) {
        resolve([]);

        return;
      }

      for(const resource of results) {
        resource.url += '?token=' + encodeURIComponent(this.tokenManagerService.access_token);
      }

      resolve(results.sort(sortFoldersFirstAlphabeticalFn));
    } catch(e) {
      this.log.error(e);
      reject(e);
    }

  }

  buildQuery(): IQuery {
    const q: IFolderQuery = {
      queryType: QUERIES.FolderQuery,
      path: this.path,
      from: this.from,
      size: this.pageSize,
      filtertype: CONTEXT.filtertype,
      childrenKind: [RESOURCE_KIND.FILE, RESOURCE_KIND.FOLDER],
      pathDepth: PathDepth.OneLevel,
    };
    //q["source"] = "mongo"
    if (CONTEXT.embeddedInHemlock) {
      q.context = EXTERNAL_APPS.HEMLOCK;
    }
    this.log.debug(`${this.prefix} getFolderOneLevel`, this.from, this.pageSize, this.path);

    return q;
  }

  snapshotCurrentFilters(): string {
    return `${this.path}`;
  }

  get path(): string {
    return this.fileSystemService.path;
  }

  set path(value: string) {
    this.fileSystemService.path = value;
  }


}
