<div id="confirmDialogContainer">
    <div class="modal-header">
        <h4 class="modal-title pull-left">Export opties</h4>
        <button  (click)="onClose()" aria-label="Close" class="close pull-right" type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="messageBody">
            <form>
                <p class="message"></p>
                <div>
                    <div>
                        <label>
                            <input type="checkbox" name="URI" [(ngModel)]="includeURI" >&nbsp;voeg URI toe aan bestandsnaam
                        </label>

                    </div>
                    <div>
                        <label>
                            <input type="checkbox" name="originals" [(ngModel)]="originals">&nbsp;export originele item(s)
                        </label>     
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" name="flatten" [(ngModel)]="flatten">&nbsp;afvlakken map structuur
                        </label>     
                    </div>                    
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="onOK()" 
        class="btn btn-primary mr-2" 
        type="button">Ja
        </button>
        <button (click)="onClose()" class="btn btn-danger" type="button">Nee</button>
    </div>
</div>
