import { v4 as uuid } from 'uuid';

export let SESSION_ID: string =  window.sessionStorage["SESSION_id"] || uuid()
window.sessionStorage["SESSION_id"] = SESSION_ID






