import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RecentTagsService {

  cache = [];

  add(tag: string) {
    if (tag) {
      if (!this.cache.includes(tag)) {
        this.cache.push(tag.toLowerCase());
      }
    }
  }

  findRecent(tag: string): string[] {
    if (!tag) return [];
    const results: string [] = [];
    for(const item of this.cache) {
      if (item.includes(tag.toLowerCase())) {
        results.push(item);
      }
    }

    return results;
  }
}
