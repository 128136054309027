import { Injectable } from "@angular/core"
import { PARAMS } from "../../params"
import { TokenManagerService } from "./token-manager.service"
import { LoggingsService } from "../LoggingsService"
import { ID_TOKEN_HEADER } from "jwt-helper/dist"

const oneHourWithMarge = (1000 * 60 * 60) - (1000 * 60 * 2); // 120 seconds marge to roudntrip to server 

@Injectable()
export class AWSCredentialService {
    assumedRole: any
    expiration = 0
    pendingPromise: Promise<any>

    constructor(private logger: LoggingsService,
                private tokenManagerService: TokenManagerService
        ) {
    }

    async getAssumeRole() {
        this.logger.debug(`NEW getAssumeRole request`);
        try {
            this.pendingPromise = fetch(PARAMS['ASSUME_ROLE_LAMBDA'], {
                headers: { "authorization": `Bearer ${this.tokenManagerService.access_token}`, ID_TOKEN_HEADER: this.tokenManagerService.id_token }
            });
            const response = await this.pendingPromise;
            this.assumedRole = await response.json();
        } catch (error) {
            this.logger.error('Error fetching assume role:', error);
            throw error;
        } finally {
            this.pendingPromise = undefined;
        }
    }

    async getCredentials(): Promise<any> {
        if (this.pendingPromise) {
            await this.pendingPromise;
        }
    
        const deltaInMs = this.expiration - Date.now();
    
        if (!this.assumedRole || deltaInMs < oneHourWithMarge) {
            await this.getAssumeRole();
            this.expiration = new Date(this.assumedRole.Credentials.Expiration).getTime();
        }
    
        return {
            accessKeyId: this.assumedRole.Credentials.AccessKeyId,
            secretAccessKey: this.assumedRole.Credentials.SecretAccessKey,
            sessionToken: this.assumedRole.Credentials.SessionToken,
            expiration: new Date(this.assumedRole.Credentials.Expiration)
        };
    }
    
}