import { Injectable } from '@angular/core'
import { SQSClient, SendMessageBatchCommand, SendMessageCommand } from "@aws-sdk/client-sqs"
import { COMMANDS, ICommand } from 'prunus-common/dist'
import { Subject } from 'rxjs/internal/Subject'
import { v4 as uuid } from 'uuid'
import { MESSAGES } from '../../MESSAGES'
import { SESSION_ID } from '../../SESSION_ID'
import { AWSCredentialService } from '../services/AWSCredentialService'
import { TokenManagerService } from '../services/token-manager.service'
import { PARAMS } from '../../params'
import { USER } from '../../user/USER'
import { User } from '../../user/model/User'
import { CleanupService } from '../CleanupService'
import { ErrorHandlingService } from '../ErrorHandlingService'
import { LoggingsService } from '../LoggingsService'
import { SocketService } from '../SocketService'
import { ServerSocketService } from '../services/server-socket.service'
import { UIOrigin } from './UIOrigin'
import { Subscription } from 'rxjs'
import { NotificationService } from '../services/notification-service'
const METHODS: { [key: string]: string } = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
}
const CONTENT_TYPE = 'Content-Type'

const CONTENT_TYPES = {
  JSON: 'application/json charset=utf-8',
  FORM: 'app/x-www-form-urlencoded charset=UTF-8',
}

@Injectable({
  providedIn: 'root'
})
export class UniversalCommandService {
  cleanupService = new CleanupService(UniversalCommandService.name)
  PRUNUS_SERVER = PARAMS['PRUNUS-CMD-SERVER']
  readonlyMode = false

  constructor(
    private errorHandlingService: ErrorHandlingService,
    private logger: LoggingsService,
    private socketService: SocketService,
    private tokenManagerService: TokenManagerService,
    private awsCredentialService: AWSCredentialService,
    private notificationService: NotificationService,
    private serverSocketService: ServerSocketService
  ) {
    this.serverSocketService.serverEventsSubject$.subscribe((event) => {
      if (event) {
        if (event.eventType === "EnterReadonlyMode") {
          this.readonlyMode = true
          this.notificationService.warning(MESSAGES.ENTER_READONLY_MODE)
        } else if (event.eventType === "EnterWriteMode") {
          this.readonlyMode = false
          this.notificationService.warning(MESSAGES.EXIT_READONLY_MODE)
        }
      } 
    })
  }

  bulkHandle(cmds: ICommand[]): Subject<any> {
    const subject = new Subject<any>()
    if (!this.readonlyMode) {
      try {
        for (const cmd of cmds) {
          if (!cmd.uid) {
            cmd.uid = uuid()
          }
          cmd.originatedFrom = UIOrigin.PrunusUI
          // need the socket id so wait for connection
          cmd.socketId = this.socketService.socket_id
          cmd.session_id = SESSION_ID
        }
       
        switch (PARAMS["SEND_CMD_OVER"]) {
          case "SQS":
            this.sendBySQS(cmds, subject)   
            break
          default:
            this.sendByPost(cmds, subject)
            break
        }
        
      } catch(e) {
        this.errorHandlingService.handleError(e)
      }
    } else {
      this.showReadonlyModeToast()
    }

    return subject
  }

  handle(cmd: ICommand): Subject<any> {
    const subject = new Subject<any>()
    if (!this.readonlyMode) {
      try {
        if (!cmd.uid) {
          cmd.uid = uuid()
        }
        cmd.originatedFrom = UIOrigin.PrunusUI
        // need the socket id so wait for connection
        cmd.socketId = this.socketService.socket_id
        cmd.session_id = SESSION_ID
        switch (PARAMS["SEND_CMD_OVER"]) {
          case "SQS":
            this.sendBySQS(cmd, subject)   
            break
          default:
            this.sendByPost(cmd, subject)
            break
        }    
      } catch(e) {
        this.errorHandlingService.handleError(e)
        subject.error(e)
      }
    } else {
      this.showReadonlyModeToast()
    }  
    return subject
  }

  showReadonlyModeToast() {
    this.notificationService.warning(MESSAGES.READONLY_MODE_MSG)
  }

  sendBySQS(cmdOrCmds: ICommand | ICommand[], subject: Subject<any>) {
    this.awsCredentialService.getCredentials().then(async (credentials) => {
      try {
        const client = new SQSClient({
          region: PARAMS["REGION"],
          credentials
        })

        let result
        if (Array.isArray(cmdOrCmds)) {
          result = await client.send(new SendMessageBatchCommand({
            Entries : cmdOrCmds.map( cmd => { 
              /*cmd['securityContext'] = {
                currentUser: this.shrinkUser(USER),
              }*/
              if (!cmd['socketId']) cmd['socketId'] = this.socketService.socket_id
              cmd.session_id = SESSION_ID
              return { Id: uuid(),MessageBody: JSON.stringify(cmd), MessageGroupId:""}  // oAuthInfo.token} 
            }),
            QueueUrl: PARAMS["Q"]
          }))
        } else {
          /*cmdOrCmds['securityContext'] = {
            currentUser: this.shrinkUser(USER),
           // token: oAuthInfo.token,
           // expires_in: oAuthInfo.expires
          }*/
          if (!cmdOrCmds['socketId']) cmdOrCmds['socketId'] = this.socketService.socket_id
          result = await client.send(new SendMessageCommand({
            MessageBody: JSON.stringify(cmdOrCmds),
            QueueUrl: PARAMS["Q"],
           // MessageGroupId: oAuthInfo.token
          }))
        }
        
        subject.next(result)
        this.logger.debug(`${JSON.stringify(cmdOrCmds)} => SQS => OK`)
        this.socketService.worker?.postMessage({event: 'cmdOrQExec'})
      } catch(e) {
        this.logger.error(e)
        subject.error(e)
      }
    })
  }

  sendByPost(cmd: ICommand | ICommand[], subject: Subject<any>, retries = 0) {
   this.processPost(cmd, this.tokenManagerService.access_token, retries, subject)
  }

  processPost(cmd: ICommand | ICommand[], token: string, retries: number, subject: Subject<any>) {
    try {
      const fillCmd = (singleCmd: ICommand) => {  
        if (!singleCmd.socketId) singleCmd.socketId = this.socketService.socket_id
        singleCmd.session_id = SESSION_ID
      }
      if (Array.isArray(cmd)) {
        for(let item of cmd) {
          fillCmd(item)
        }
      } else {
        fillCmd(cmd)
      }
       

      const onError = async (e) => {
        if (retries < 5) { // try fix network changed
          setTimeout(async () => {
            console.log(`retry post cmd :`, cmd)
            await this.sendByPost(cmd, subject, retries + 1)
          }, 2000)
        } else {
          this.errorHandlingService.handleError(`could not post cmd after 5 retries`)
        }
      }

      let headers: any =  { "authorization": `Bearer ${this.tokenManagerService.access_token}`, "id-token": this.tokenManagerService.id_token }
      if (!Array.isArray(cmd)) {
        if (cmd["BulkUploadUid"] && cmd.commandType === COMMANDS.NewResourceUploadCommand) {
          headers = {}
        }
      } else {
        let allUploadCmds = true
        for(const c of cmd) {
          if (!c["BulkUploadUid"] || cmd["commandType"] !== COMMANDS.NewResourceUploadCommand) {
            allUploadCmds = false
          }
          
        }
        if (allUploadCmds) {
          headers = {}
        }
      }
       
      const response = fetch(this.PRUNUS_SERVER, {
        method: METHODS.POST,
        body: JSON.stringify(Array.isArray(cmd) ? { cmds: cmd } : cmd),
        headers
      }).then(async (response) => {
        const data = await response.json()
        subject.next(data)
        this.logger.debug(`${JSON.stringify(cmd)} => POST => OK`)
      }).catch(e => this.logger.error(`sendByPost caught`, e))
    } catch (e) {
      this.logger.error(`sendByPost caught`, e)
    }

    this.socketService.worker?.postMessage({ event: 'cmdOrQExec' })
  }

  shrinkUser(u: User): any {
    const copy: User = JSON.parse(JSON.stringify(u))
    copy.avatar = undefined
    if (copy['INITIAL_TOKEN']) {
      delete copy['INITIAL_TOKEN']
    }
    
    return copy
  }
/*
  async httpPost(url, method: string = METHODS.POST, options: any = {}) {
    return new Promise<any> ((resolve, reject) => {
      const req = new XMLHttpRequest()

      req.addEventListener('load', async evt => {
        if (req.status === 200) {
          let result = req.response
          if (result && options.headers && options.headers[CONTENT_TYPE] === CONTENT_TYPES.JSON) {
            if (req.response) {
              try {
                result = JSON.parse(req.response)
              } catch (e) {
                console.error(`JSON can't parse ${req.response} response for url ${url}`, e, options)
              }
            } else {
              result = undefined
            }
          }
          resolve(result)
        } else {
          const res = JSON.parse(req.response)
  
          const err = `error: ${req.status} ${req.statusText}: \n${res['error_description']} ${req.status} ${req.status}`
          reject(new Error(err))
        }
      })

      req.addEventListener('error', evt => {
        if (options.errorHandler) {
          options.errorHandler(evt)
        } else {
          console.error(evt, req.status, req.statusText)
          reject(new Error(`${req.status} ${req.statusText}, ${url} ${method} ${JSON.stringify(options)}`))
        }
      })

      req.addEventListener('abort', evt => {
        console.warn(evt, req.status, req.statusText)
        reject(new Error(`${req.status} ${req.statusText}`))
      })

      req.open(METHODS[method.toUpperCase()], url)

      options.headers = { ...options.headers }
      Object.keys(options.headers).forEach(header => {
        req.setRequestHeader(header, options.headers[header])
      })

      if (method === METHODS.POST && options && options.body) {
        req.send(JSON.stringify(options.body))
      } else {
        req.send()
      }
    })
  }
  */
}
