import { Component, OnDestroy, OnInit } from '@angular/core'
import { TooManyEntriesInBatchRequest } from '@aws-sdk/client-sqs'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { COMMANDS, IExportProgressEvent, IExportResourceCommand } from 'prunus-common/dist'
import { EVENTS } from 'prunus-common/dist/events/EVENTS'
import { IResourceExportedEvent } from 'prunus-common/dist/events/IResourceExportedEvent'
import { CleanupService } from '../../infrastructure/CleanupService'
import { ServerSocketService } from '../../infrastructure/services/server-socket.service'
import { v4 as uuid } from 'uuid'
import { ExplorerMultiSelectService } from '../explorer-view/explorer-multi-select.service'
import { UniversalCommandService } from '../../infrastructure/cqrs/universal-command.service'
import { IResource } from '../../resource/interface/IResource'
import { hideSpinner, showSpinner } from '../../spinner'
import { Subscription } from 'rxjs/internal/Subscription'
import { DIALOG_OPEN_OPTIONS } from 'src/infrastructure/DialogOptions'
import { ExportOptionsModalComponent } from '../export-options-modal/export-options-modal.component'

let exportSubscription : Subscription | undefined

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss']
})
export class ExportModalComponent implements OnInit, OnDestroy {

  private cleanupService = new CleanupService(ExportModalComponent.name)
  exportUid = ''
  progressMessage = ''
  title = ''
  selection: IResource[]

  constructor(
    public bsModalRef: BsModalRef,
    private serverSocketService: ServerSocketService,
    private universalCommandService: UniversalCommandService,
    private modalService: BsModalService,
    ) { }


  onClose() {
    this.cleanupService.cleanupSubscriptions()
    this.bsModalRef.hide()
  }

  ngOnInit(): void {
    showSpinner('Export wordt opgestart ...')
  }

  ngOnDestroy(): void {
    this.cleanupService.cleanupSubscriptions()
  }

  export(includeURI: boolean, originals: boolean, flatten: boolean) {
    const cmd: IExportResourceCommand = {
      uid: uuid(),
      uris: this.selection.map(r => r.uri),
      commandType: COMMANDS.ExportResourceCommand,
      originalResources: originals,
      includeURIInNames: includeURI,
      flatten
    }
    this.exportUid = cmd.uid
    this.progressMessage = ""
    this.subscribeToExportEvents()
    this.universalCommandService.handle(cmd)
    this.onClose()
  }

  subscribeToExportEvents() {
    exportSubscription = 
      this.serverSocketService.subscribe2ServerEvents().subscribe(
        (evt) => {
          
          if (evt.eventType === EVENTS.ResourceExportedEvent && evt.uid === this.exportUid) {
            const downloadUrl = (evt as IResourceExportedEvent).zipFileUrl
            const link = document.createElement('a') 
            link.setAttribute('href', downloadUrl) 
            link.setAttribute('download', downloadUrl)
            try {
              link.click() 
            } catch(e) {
              console.log(e)
            }
            hideSpinner()
            this.onClose()
            exportSubscription?.unsubscribe()
          }

          if (evt.eventType === EVENTS.ExportProgressEvent && (evt as IExportProgressEvent).cmdUid === this.exportUid) {
            this.progressMessage = (evt as IExportProgressEvent).progressMessage
            showSpinner(this.progressMessage)
          }
        })
    }

}
