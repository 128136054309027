import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3"
import { IResource } from "prunus-common/dist"
import { AWSCredentialService } from "./AWSCredentialService"
import { PARAMS } from "src/params"
const { getSignedUrl } = require("@aws-sdk/s3-request-presigner")

export const PRESIGNER_CACHE = {}

export const s3urlpresigner = async (Key: string, Bucket: string, resource: IResource, awsCredentialService: AWSCredentialService) => {
    try {
        if (!Key || !Bucket) { return undefined }

        const cacheKey = `${Bucket}/${Key}`
        if (PRESIGNER_CACHE[cacheKey]) {
            return await PRESIGNER_CACHE[cacheKey]
        }
        
        const s3Client = new S3Client({
            region: PARAMS['REGION'],
            credentials: await awsCredentialService.getCredentials()
        })
    
        const MAX_EXPIRY = 604800 // 1 week
        const command = new GetObjectCommand({
            Bucket,
            Key
        })
    
        if (!Key.includes("coverThumb")) {
            /*
            The ResponseContentDisposition parameter allows you to control how the browser or client handles the response when downloading the document. By default, it is set to "attachment", which prompts the browser to download the file as an attachment. If you set it to "inline", the browser may attempt to open the file directly, depending on the user's browser settings and the file type.
            */
            if (resource.extension.toLowerCase() === "pdf") {
                command["ResponseContentDisposition"] = "inline"
                command["ResponseContentType"] = "application/pdf"
            } else if ([ "doc", "docx" ].includes(resource.extension.toLowerCase())) {
                command["ResponseContentDisposition"] = "attachement"
                command["ResponseContentType"] = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            } else if ([ "xls", "xlsx" ].includes(resource.extension.toLowerCase())) {
                command["ResponseContentDisposition"] = "attachement"
                command["ResponseContentType"] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            } else if ([ "ppt", "pptx" ].includes(resource.extension.toLowerCase())) {
                command["ResponseContentDisposition"] = "attachement"
                command["ResponseContentType"] = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
            }
        }
    
        PRESIGNER_CACHE[cacheKey] = getSignedUrl(s3Client, command, { expiresIn: MAX_EXPIRY })

        const result = await PRESIGNER_CACHE[cacheKey]
    
        console.log(`${cacheKey} s3 presigned`, result)

        return result 
    } catch(e) {
        console.error(e)
        return Promise.reject(e)
    }
   
  }  
