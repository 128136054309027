import {KeyCodes} from './KeyCodes';

export class Shortcut {
  constructor(
    private _keyconstant: number,
    private _ctrlOrCmd: boolean = false,
    private _isMac: boolean = false,
    private _alt: boolean = false,
    private _shift: boolean = false,
    private _evt?: Event
  ) {}

  toString(): string {
    let str = '';
    if (this.ctrlOrCmd) {
      str += this.isMac ? '⌘' : 'ctrl+';
    }
    if (this.alt) {
      str += this.isMac ? '⎇' : 'alt+';
    }
    if (this.shift) {
      str += this.isMac ? '⇧' : 'shift+';
    }
    str += KeyCodes['CODE_' + this.keyconstant];

    return str;
  }

  get keyconstant(): number {
    return this._keyconstant;
  }

  get ctrlOrCmd(): boolean {
    return this._ctrlOrCmd;
  }

  get isMac(): boolean {
    return this._isMac;
  }

  get alt(): boolean {
    return this._alt;
  }

  get shift(): boolean {
    return this._shift;
  }

  get evt(): Event {
    return this._evt;
  }

  equals(shortcut: Shortcut): boolean {
    return shortcut.toString() === this.toString();
  }
}
