import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { v4 as uuid } from 'uuid';
import { LoggingsService } from '../../infrastructure/LoggingsService';
import { UploaderService } from '../upload-queue/UploaderService';
import { IResource } from '../../resource/interface/IResource';
import { FileSystemService } from '../../infrastructure/services/FileSystemService';
import { CONSTRAINTS } from 'prunus-common/dist';
import { formatMsg, MESSAGES } from '../../MESSAGES';
import { TOAST_LEVELS, showToast } from 'src/toast';
import { NotificationService } from '../../infrastructure/services/notification-service';

/****
 * I created our own dedicated FileDrop directive, instead the one that ships with ng2-fileupload
 * because the standard does not allow for folder upload and restriction on FileTypes
 */

@Directive({ selector: '[appFileDirDrop]' })
export class FileDirDropDirective {
  @Input() uploader: any;
  @Input() allowedExtensions: string[];
  @Output() fileOver: EventEmitter<any> = new EventEmitter();
  @Output() fileDrop: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() folder: EventEmitter<string> = new EventEmitter<string>();

  protected element: ElementRef;
  private fileList: File[] =  [];
  root: IResource;

  constructor (element: ElementRef,
               private fileSystemService: FileSystemService, 
               private log: LoggingsService, 
               private route: ActivatedRoute,
               private notificationService: NotificationService,
               private uploaderService: UploaderService
    ) {
    this.element = element;
  }

  getFilters (): any {
    return {};
  }

  isAllowedFile (entry): boolean {
    if (! this.allowedExtensions) {
      return true;
    }
    this.allowedExtensions = this.allowedExtensions.map(((ext: string) => ext.toLowerCase()));
    const lastDotIndex = entry.name.lastIndexOf('.');
    if (lastDotIndex === - 1) {
      this.log.debug(`Bestand ${entry.name} niet toegestaan.`)
      return false;
    }
    const extension = entry.name.substring(lastDotIndex + 1).toLowerCase();

    return this.allowedExtensions.indexOf(extension) !== - 1;
  }

  @HostListener('drop', [ '$event' ])
  async onDrop (event: any) {
    if (event?.preventDefault) {
      this._preventAndStop(event)
    }

    const transfer = this._getTransfer(event);
    if (! transfer) {
      return;
    }
    this.fileList = [];
    const items = event.dataTransfer.items;
    for (const item of items) {
      this.traverseFileTree(item.webkitGetAsEntry()); 
    }
    
    this.fileOver.emit(false);
    this.fileDrop.emit(this.fileList);
  }

  isPathOnNetworkDrive(filePath) {
    // Here, you can implement your logic to check if the file path
    // indicates that it is from a network drive. This can involve
    // checking for network path patterns, drive letters, or other indicators.
  
    // Example: Check if the path starts with a network UNC path pattern
    return /^\\\\[^\\]+\\/.test(filePath);
  }

  @HostListener('change', [ '$event' ]) 
  async change(event: any) {
    this._preventAndStop(event);

    this.fileList = [];
    const items = event.target.files;
    for (const item of items) {
      this.traverseFileTree(item); 
    }
    
    this.fileOver.emit(false);
    this.fileDrop.emit(this.fileList);
    event.srcElement.value = "";
  }

  traverseFileTree(item) {
    if (!item) return // Linux drag & drop
    if (this.isPathOnNetworkDrive(item.fullPath)) {
      showToast(MESSAGES.NETWORK_DRIVE_NOT_SUPPORTED, TOAST_LEVELS.ERROR, "ERROR")
      return;
    }
    if (item.isFile) {
      item.file(file => {
       file.fullPath = item.fullPath;
       this.dealWithFile(file)
      });
    } else if (item.constructor.name == 'File') {
      this.dealWithFile(item)
    } else if (item.isDirectory) {
      const directoryReader = item.createReader();
      const dirPath = this.fileSystemService.path + item.fullPath.toLowerCase();
      const pathLength = dirPath.length;
      if (pathLength > CONSTRAINTS.MAX_PATH_LENGTH) {
        this.notificationService.error(
          formatMsg(MESSAGES.MAX_PATH_DEPTH_VIOLATION, {name: item.name, MAX_PATH_LENGTH: CONSTRAINTS.MAX_PATH_LENGTH, pathLength}));
  
        return;
      }
      this.uploaderService.newFolders.push({ uri: uuid(), name: this.fileSystemService.path + item.fullPath.toLowerCase()})
      this.readDirectory(directoryReader, []);
    }
  }

  dealWithFile(file: any) {
    const fullPathContainsName = !!file.fullPath; // only filled in when dropped not when clicked on the dropzone
    file.fullPath = this.fileSystemService.path + (fullPathContainsName ? file.fullPath: "");
    const pathLength = file.fullPath.length;
    if (pathLength > CONSTRAINTS.MAX_PATH_LENGTH) {
      this.notificationService.error(
        formatMsg(MESSAGES.MAX_PATH_DEPTH_VIOLATION, {name: file.name, MAX_PATH_LENGTH: CONSTRAINTS.MAX_PATH_LENGTH, pathLength})
      );
      return;
    }
    let path: string;
    if (fullPathContainsName) {
      path = file.fullPath.substring(0, file.fullPath.length - file.name.length);
    } else {
      path = file.fullPath;
    }
    if (path.endsWith('/')) {
      path = path.substring(0, path.length - 1);
    }
    this.fileList.push(file);
    let uri = uuid()
    const uri_mark = "_uri_"
    let filename = file.name
    if (filename.includes(uri_mark)) {
      const parts = file.name.split(uri_mark)
      uri = parts[1]
      const uriParts = uri.split(".") // syntax uri.extension
      uri = uriParts[0]
      filename = parts[0]
      if (uriParts[1]) {
        filename += "." + uriParts[1] // extension 
      }
    }
    const iQItem = {
      file: file,
      filename,
      fullPath: file.fullPath,
      checked: false,
      isCancelled: false,
      path,
      tags: [],
      thumb: undefined,
      isReady: false,
      isUploading: false,
      isSuccess: false,
      isError: false,
      progress: 0,
      uri
    };
    this.uploaderService.addUpload(iQItem);
  }
  readDirectory(directoryReader, allEntries: any[]) {
    directoryReader.readEntries((entries) => {
        if (entries.length > 0) {
            this.readDirectory(directoryReader, allEntries.concat(entries)); //read the next batch (batch of 100 files)
        } else {
          for (let i = 0; i < allEntries.length; i++) {
            this.traverseFileTree(allEntries[i]);
          }
        }
    });
  }

  @HostListener('dragover', [ '$event' ])
  onDragOver (event: any): void {
    const transfer = this._getTransfer(event);
    if (! this._haveFiles(transfer.types)) {
      return;
    }

    transfer.dropEffect = 'copy';
    this._preventAndStop(event);
    this.fileOver.emit(true);
  }

  @HostListener('dragleave', [ '$event' ])
  onDragLeave (event: any): any {
    if ((this as any).element) {
      if (event.currentTarget === (this as any).element[ 0 ]) {
        return;
      }
    }

    this._preventAndStop(event);
    this.fileOver.emit(false);
  }

  protected _getTransfer (event: any): any {
    return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer; // jQuery fix;
  }

  protected _preventAndStop (event: any): any {
    event.preventDefault();
    event.stopPropagation();
  }

  protected _haveFiles (types: any): any {
    if (! types) {
      return false;
    }

    if (types.indexOf) {
      return types.indexOf('Files') !== - 1;
    } else if (types.contains) {
      return types.contains('Files');
    } else {
      return false;
    }
  }
}
