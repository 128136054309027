
let wakeLock = null

export async function requestWakeLock() {
    try {
        if (!('wakeLock' in navigator)) { return }
        if (wakeLock !== null) {
            // If a wake lock is already active, release it first
            await wakeLock.release();
        }
        wakeLock = await (navigator.wakeLock as any).request('screen')
        console.log('Wake Lock is active')
    } catch (err) {
        console.error(err)
    }
}

export async function releaseWakeLock() {
    if (!('wakeLock' in navigator)) { return }
    if (wakeLock !== null) {
        try {
            await wakeLock.release()
            wakeLock = null
            console.log('Wake Lock released')
        } catch (err) {
            console.error(err)
        }
    }
}

