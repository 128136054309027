<div class="modal-header">
    <h5 class="modal-title pull-left">{{title}}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
      <!--<edu-icon-delete-fat type="filled"></edu-icon-delete-fat>-->
    </button>
  </div>
  <div class="modal-body">
    <div class="d-flex flex-column">
     <app-tree-folders (nodeSelected)="onSelect($event)"></app-tree-folders> 
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex flex-row justify-content-end">
      <button type="button" [disabled]="!selectedTreeNode || isInitialSelect" class="btn btn-primary ml-2" (click)="onApply()"> kiezen</button>
      <button type="button" class="btn btn-danger ml-2" (click)="onClose()"> annuleren</button>
    </div>
  </div>
  
  