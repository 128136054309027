import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: [ './confirm-dialog.component.scss' ],
})
export class ConfirmDialogComponent implements OnInit {
  okOnly = false
  title: string

  constructor (public bsModalRef: BsModalRef, private changeDetectorRef: ChangeDetectorRef,) {
  }

  
  private _message: string

  get message () {
    return this._message
  }

  set message (value: string) {
    this._message = value
    this.changeDetectorRef.markForCheck()
  }

  private _onYesAction: Function

  set onYesAction (value: Function) {
    this._onYesAction = value
  }

  private _onNoAction: Function

  set onNoAction (value: Function) {
    this._onNoAction = value
  }

  ngOnInit () {
    this.title = 'Opgelet, uw bevestiging wordt gevraagd'
  }

  onYes (): void {
    if (this._onYesAction) {
      this._onYesAction()
    }
    this.onClose()
  }

  onNo (): void {
    if (this._onNoAction) {
      this._onNoAction()
    }
    this.onClose()
  }

  onClose () {
    this.bsModalRef.hide()
  }

  @HostListener('document:keydown', [ '$event' ])
  onKeyDown (event: any) {
    if (event.keyCode === 13) {
      this.onYes()
    }
  }

}
