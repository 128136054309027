<div>
  <tree-base
    *ngIf="treeData.length"
    [treeData]="treeData"
    [currentPath]="path"
    (itemSelected)="onSelect($event)"
    (initialized)="onTreeInitialized($event)"
  ></tree-base>

      <div *ngIf="isLoading" class="mt-3" style="display: flex;
    justify-content: center;
    align-items: center;">
        <edu-icon-sync class="edu-spin-counter" color="black" size="35px" type="filled"></edu-icon-sync>
    </div>
<!--    <tree-root #tree [nodes]="treeData" (updateData)="onTreeInitialized($event)" (activate)="onSelect($event)"></tree-root>-->
</div>

