// https://stackoverflow.com/questions/21687907/typescript-sorting-an-array
export const naturalCompare = (string1: string, string2: string) => {
  const array1: any[] = [];
  const array2: any[] = [];

  string1.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
    array1.push([$1 || Infinity, $2 || '']);

    return '';
  });
  string2.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
    array2.push([$1 || Infinity, $2 || '']);

    return '';
  });

  while (array1.length && array2.length) {
    const shifted1 = array1.shift();
    const shifted2 = array2.shift();
    const results = shifted1[0] - shifted2[0] || shifted1[1].localeCompare(shifted2[1]);
    if (results) {
      return results;
    }
  }

  return array1.length - array2.length;
}
