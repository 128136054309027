import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IServerVersionCommand } from 'prunus-common/dist/cmds/interfaces/IServerVersionCommand';
import { UniversalCommandService } from '../../infrastructure/cqrs/universal-command.service';
import { UniversalQueryService } from '../../infrastructure/services/universal-query.service';
import { User } from '../../user/model/User';
import { USER } from '../../user/USER';
import { v4 as uuid } from 'uuid';
import { COMMANDS, EVENTS, IQuotaInfoCommand, IQuotaInfoRetrievedEvent, IServerVersionEvent, IServerVersionQuery, QUERIES } from 'prunus-common/dist';
import { Subject } from 'rxjs/internal/Subject';
import { ServerSocketService } from '../../infrastructure/services/server-socket.service';
import { CleanupService } from '../../infrastructure/CleanupService';
import { PARAMS } from '../../params';
import { version } from 'process';
import { TokenManagerService } from '../../infrastructure/services/token-manager.service';
import { LoggingsService } from '../../infrastructure/LoggingsService';

const packageJson = require('package.json');

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDetailComponent implements OnInit, OnDestroy {
  title = 'Algemene info'
  user: User
  query_server_version = "?"
  cmd_server_version = "?"
  img_server_version = "?"
  quota = 0
  private cleanupService = new CleanupService(UserDetailComponent.name)

  constructor(public bsModalRef: BsModalRef,
    private universalQueryService: UniversalQueryService,
    private universalCommandService: UniversalCommandService,
    private serverSocketService: ServerSocketService,
    private tokenManagerService: TokenManagerService,
    protected log: LoggingsService, 
    ) { }

  ngOnInit() {
    const subject = new Subject<any>();
    this.cleanupService.addSubscription(this.serverSocketService.subscribe2ServerEvents().subscribe(
      (evt) => this.onServerEvent(evt)
    ))
    this.user = { ... USER } as User
    const versionCmd : IServerVersionCommand = {
      uid: uuid(),
      commandType: COMMANDS.ServerVersionCommand
    }
    this.universalCommandService.handle(versionCmd)

    const q : IServerVersionQuery = {
      queryType: QUERIES.ServerVersionQuery
    }
    this.cleanupService.addSubscription(
      this.universalQueryService.query(q).subscribe(r => {
        this.query_server_version = r.version;
      })
    )

    fetch(`${PARAMS['PRUNUS-IMG-SERVER']}/version?token=${encodeURIComponent(this.tokenManagerService.access_token)}`, {
      headers: {
        "id-token": this.tokenManagerService.id_token
      }
    })
    .then(async (r) => this.img_server_version = (await r.json())['version'])

    const quotaInfoCmd : IQuotaInfoCommand = {
      uid: uuid(),
      commandType: COMMANDS.QuotaInfoCommand
    }
    this.universalCommandService.handle(quotaInfoCmd)
    
  }

  ngOnDestroy() {
    this.cleanupService.cleanupSubscriptions()
  }

  onClose(): void {
    this.bsModalRef.hide()
  }

  get version(): string {
    return packageJson.version
  }


  onServerEvent(evt: any)  {
    this.log.debug(`onServerEvent received : ${evt}`)
      
    switch (evt.eventType) {
      case EVENTS.ServerVersionEvent: {
        const serverVersionEvent = evt as IServerVersionEvent
        this.cmd_server_version = evt.version
        break
      }
      case EVENTS.QuotaInfoRetrievedEvent: {
        const quotaInfoRetrievedEvent = evt as IQuotaInfoRetrievedEvent
        this.quota = evt.currentQuota
      }
    }
  }
  

}
