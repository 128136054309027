import { Component, HostListener, OnInit } from '@angular/core';
import { COMMANDS, EVENTS, ICreateFolderCommand, IEmailChangedEvent } from 'prunus-common/dist';
import { v4 as uuid } from 'uuid';
import { MESSAGES, formatMsg } from '../MESSAGES';
import { SocketService } from '../infrastructure/SocketService';
import { UniversalCommandService } from '../infrastructure/cqrs/universal-command.service';
import { FileSystemService } from '../infrastructure/services/FileSystemService';
import { ServerSocketService } from '../infrastructure/services/server-socket.service';
import { USER } from '../user/USER';
import { AppService } from './app.service';
import { TokenManagerService } from '../infrastructure/services/token-manager.service';
import { hideSpinner } from '../spinner';
import { NotificationService } from '../infrastructure/services/notification-service';
// declared globals in https://sso.sharklazers.be/sso-client.js
declare function getSSOUrl()
declare function logoutFromSSO(overrule_url?: string)
declare function hideSSO()

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class PrunusAppComponent implements OnInit {

  constructor(
    private tokenManagerService: TokenManagerService,
    private socketService: SocketService,
    private serverSocketService: ServerSocketService,
    private universalCommandService: UniversalCommandService,
    private fileSystemService: FileSystemService,
    private appService: AppService,
    private notificationService: NotificationService,
  ) {

  }

  ngOnInit() {
    if (USER.isTeacher) {
      const cmd: ICreateFolderCommand = {
        uid: uuid(),
        commandType: COMMANDS.CreateFolderCommand,
        name: USER.email,
        path: this.fileSystemService.root,
        isTeachersRootFolder: true
      }
      this.universalCommandService.handle(cmd);
    }

    this.serverSocketService.subscribe2ServerEvents().subscribe(
      (evt) => this.onServerEvent(evt)
    );

    hideSpinner()
  }

  onServerEvent(evt) {
    switch (evt.eventType) {
      case EVENTS.EmailChangedEvent: {
        const emailChangedEvent = evt as IEmailChangedEvent;
        if (USER.email === emailChangedEvent.old_value) {
          USER.email = emailChangedEvent.new_value;
          this.appService.emailChangedEvent$.next(emailChangedEvent.new_value);
          this.notificationService.info(formatMsg(MESSAGES.EMAIL_CHANGED, { old_value: emailChangedEvent.old_value, new_value: emailChangedEvent.new_value }));
          logoutFromSSO();
        }
        break;
      }
    }
  }

}
