<div
  #thumbs
  class="tiles-container"
>
  <virtual-scroller class="search-results  d-flex p-2 flex-row flex-wrap" #scroll
                     [items]="tilesDataSourceService.resources"
                     (vsEnd)="tilesDataSourceService.fetchMore($event)"

  >
    <app-asset-thumb *ngFor="let resource of scroll.viewPortItems"
                     [canGoToDetailAndUpload]="canGoToDetailAndUpload"
                     [resource]="resource"
                     [token]="tokenManagerService.token"
                     (openDetail)="openDetail(resource)"
                     class="thumb"
    >
    </app-asset-thumb>
  </virtual-scroller>

  <div *ngIf="tilesDataSourceService.isLoadingSignal()" style="position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;">
    <edu-icon-sync class="edu-spin-counter" color="black" size="65px" type="filled"></edu-icon-sync>
  </div>
</div>

