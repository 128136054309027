import { ChangeDetectorRef, Component, Input, OnInit, effect } from '@angular/core';
import { FileDirDropDirective } from '../file-dir-drop/file-dir-drop.directive';
import { FileSystemService } from 'src/infrastructure/services/FileSystemService';
import { ActivatedRoute } from '@angular/router';
import { UploaderService } from '../upload-queue/UploaderService';
import { LoggingsService } from 'src/infrastructure/LoggingsService';
import { v4 as uuid } from 'uuid';
import { NotificationService } from '../../infrastructure/services/notification-service';

@Component({
  selector: 'app-file-folder-drop-zone',
  templateUrl: './file-folder-drop-zone.component.html',
  styleUrls: ['./file-folder-drop-zone.component.scss']
})
export class FileFolderDropZoneComponent implements OnInit {
  async showDirPicker() {
    const directoryHandle = await (window as any).showDirectoryPicker()
    if (!directoryHandle) return
    const directive = new FileDirDropDirective(undefined, this.fileSystemService, this.log, this.route, this.notificationService, this.uploaderService)
    const that = this
    async function* getHandlesRecursively(entry, relative_path) {
      switch(entry.kind) {
      case "file":
        const file = await entry.getFile()
        if (file !== null) {
          file.relativePath = relative_path
          yield file;
        }
        break
      case "directory":
        relative_path += entry.name.toLowerCase() + "/" 
        that.uploaderService.newFolders.push({ uri: uuid(), name: that.fileSystemService.path + "/" + relative_path.slice(0, -1)})
        const handles = await entry.values()
        for await (const handle of entry.values()) {
          yield* getHandlesRecursively(handle, relative_path);
        }
      }
    }
    for await (const fileHandle of getHandlesRecursively(directoryHandle, "")) {
      console.log(fileHandle)
      fileHandle.fullPath = "/" + fileHandle.relativePath  + fileHandle.name
      directive.dealWithFile(fileHandle)
    }
    
    console.log(directoryHandle)
  }
  @Input()
  allowedResourcesExtensions: string[] = [];
  @Input()
  canUpload = true;
  @Input()
  uploader: any;

  hasBaseDropZoneOver = false;

  constructor(private fileSystemService: FileSystemService, 
    private log: LoggingsService, 
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private uploaderService: UploaderService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { 
      effect(() => {
        this.canUpload = !this.uploaderService.isBulkUploadProcessingOnServerSignal()
        this.changeDetectorRef.markForCheck() 
        this.log.debug("changed canUpload", this.canUpload)
      })
    }

  ngOnInit() {
    
  }

  get accept(): string[] {
    try {
      return (this.allowedResourcesExtensions || []).map(r => '.' + r);
    } catch(e) {
      console.error(e + 'allowedResourcesExtensions', this.allowedResourcesExtensions)
    }
    
    return [];
  }

  /***
   * Support for drop zone of images, this hands over the files of files to upload to the uploader
   * @param e (list of files)
   */
  fileOverBase (e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  get isLinux() {
    return navigator.userAgent.indexOf('Linux') > -1
  }
}
