<div class="modal-header">
    <h5 class="modal-title pull-left">{{title}}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Bestemmings map: {{path}}
    </div>
    <div>
        <div>Aantal verzonden deze bulk upload : {{nrOfUploads}}</div>
    </div>
    <div>
      <button (click)="verify()" [hidden]="hideVerify">verifiëer</button>
      <div>{{verifyOutPut}}</div>
      <div *ngIf="missingFiles?.length">
        <div>Ontbrekende bestanden :</div>
        <ol>
          <li *ngFor="let missingFile of missingFiles">{{missingFile}}</li>
        </ol>
      </div>
  </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex flex-row justify-content-end">
      <button type="button" class="btn btn-danger ml-2" (click)="onClose()"> sluiten</button>
    </div>
  </div>
