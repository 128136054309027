<div class="splash container-fluid text-center">
  <div>
    <div class="splash-content text-center">
      <img src="../../../../assets/images/educate-me.png" alt="e-ducate.me" title="e-ducate.me" class="margin-top-large margin-bottom-large"/>
      <div class="text-center">
        <h1>{{message}}</h1>
        <p>Deze foutmelding werd automatisch doorgegeven aan het support team.</p>
        <a href="/" class="btn btn-primary">Verdergaan</a>
      </div>
    </div>
  </div>
</div>
