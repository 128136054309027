import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { BulkHelper, EVENTS, IBulkDeletedResourcesEvent, IBulkFindResourcesByUrisQuery, IBulkMovedResourceEvent, IFolderCreatedEvent, IFolderTreeQuery, IRenamedEvent, IResourceDeletedEvent, IResourceMovedEvent, IResourceUpdatedEvent, QUERIES, RESOURCE_KIND } from 'prunus-common/dist'
import { v4 as uuid } from 'uuid'
import { CleanupService } from '../../infrastructure/CleanupService'
import { ITreeNode } from '../../infrastructure/ITreeNode'
import { LoggingsService } from '../../infrastructure/LoggingsService'
import { SocketService } from '../../infrastructure/SocketService'
import { FileSystemService, IPathTuple } from '../../infrastructure/services/FileSystemService'
import { ServerSocketService } from '../../infrastructure/services/server-socket.service'
import { UniversalQueryService } from '../../infrastructure/services/universal-query.service'
import { IResource } from '../../resource/interface/IResource'
import { USER } from '../../user/USER'
import { AppService } from '../app.service'
import { MY_FILES } from '../constants/MY_FILES'
import { TreeBaseComponent } from './tree-base/tree-base.component'

@Component({
  selector: 'app-tree-folders',
  templateUrl: './tree-folders.component.html',
  styleUrls: ['./tree-folders.component.scss']
})
export class TreeFoldersComponent implements OnInit, OnDestroy {
  cleanupService = new CleanupService(TreeFoldersComponent.name + uuid())
  selectedTreeNode: ITreeNode
  treeData: ITreeNode[] = []
  resources: IResource[] = []
  teacherRootPath: string // teacher can only move to its home folder
  @Output()
  treeInitialized = new EventEmitter<any>()
  @Output()
  nodeSelected = new EventEmitter<ITreeNode>()
  @Input()
  isMove: false
  isLoading = true
  path: string
  @ViewChild(TreeBaseComponent)
  treeBaseComponent : TreeBaseComponent

  constructor(
    private universalQueryService: UniversalQueryService,
    private fileSystemService: FileSystemService,
    private serverSocketService: ServerSocketService,
    private log: LoggingsService,
    private appService: AppService,
    private socketService: SocketService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  get rootNode(): ITreeNode {
    return this.treeData[0]
  }

  ngOnInit(): void {
    const queryString = location.search
    const urlParams = new URLSearchParams(queryString)
    const path = decodeURIComponent(urlParams.get('path'))
    this.path = path || '/prunus'

    this.teacherRootPath = `${this.fileSystemService.root}/${USER.email}`
    const q: IFolderTreeQuery = { queryType: QUERIES.FolderTreeQuery }

    this.cleanupService.addSubscription(
      this.universalQueryService.query(q).subscribe(async (resources: IResource[]) => {
        this.onDataAvailable(resources)
      })
    )

    if (!this.isMove) {
      this.cleanupService.addSubscription(this.serverSocketService.subscribe2ServerEvents().subscribe(
        (evt) => this.onServerEvent(evt)
      ))
    }

    this.cleanupService.addSubscription(
      this.appService.emailChangedEvent$.subscribe((newMail: string) => {

      })
    )
  }

  ngOnDestroy(): void {
    this.cleanupService.cleanupSubscriptions()
  }

  get root(): ITreeNode {
    return this.treeData.find(tn => tn.name === "prunus" && !tn.parent)
  }

  onDataAvailable(resources: IResource[]): void {
    this.treeData = []
    this.resources = resources

    if (this.isMove && USER.isTeacher && !USER.isAdmin) {
      this.resources = this.resources.filter(r => {
        return ((r.name === USER.email /*|| r.oldMail === USER.email*/) && r.path === this.fileSystemService.root) ||
          r.path.startsWith(this.teacherRootPath)
      })
    }

    this.resources.sort((node1, node2) => {
      return (node1.path + (node1.path === '/' ? '' : '/') + node1.name).localeCompare(
        (node2.path + (node2.path === '/' ? '' : '/') + node2.name))
    })

    let index = 0
    let stop = false
    let nodeCount = 0
    do {
      const r = this.resources[index]
      this.resources.splice(index++, 1)
      if (!r) {
        stop = true
        this.log.warn("There is probably a resource with no parent or a reference to a non existent parent...")
        continue
      }

      if (this.isMove && USER.isTeacher) {
        let path = r.path
        if (!path.endsWith('/')) {
          path += '/'
        }
        path += r.name
        if (r.name === USER.email /*|| r.oldMail === USER.email*/) {
          r.name = MY_FILES
        }
      }
      const treeNode = {
        id: r.uri,
        parent: undefined,
        uri: r.uri,
        expandable: true,
        name: r.name,
        level: r.path.split('/').filter(part => part !== '').length,
        path: r.path,
        children: []
      }

      treeNode.children = this.getChildren(treeNode)
      if (!treeNode.parent && treeNode.name !== "prunus" && this.root) {
          treeNode.parent = this.root
          treeNode.parent.children.push(treeNode)
          nodeCount++
      } else {
        this.treeData.push(treeNode)
        nodeCount++
      }
    } while (this.resources.length && !stop)

  }

  getChildren(node: ITreeNode): ITreeNode[] {
    const directChildren: ITreeNode[] = []
    let index = 0
    const idx2Del = []
    const matches = this.resources.filter(r => r.parent_resource_uri === node.uri)
    for (const cr of matches) {
      if (cr.name === USER.email) {
        cr.name = MY_FILES
      }
      const treeNode: ITreeNode = {
        id: cr.uri,
        parent: node,
        level: node.level + 1,
        name: cr.name,
        uri: cr.uri,
        path: cr.path,
        children: []
      }

      this.resources.splice(this.resources.findIndex(r => r.uri === cr.uri), 1)
      treeNode.children = this.getChildren(treeNode)
      directChildren.push(treeNode)
    }

    return directChildren
  }

  nodeToPath(node: ITreeNode): string {
    if (!node) return ''
    return `${node.path}/${node.name}`.replace("//", "/")
  }

  onTreeInitialized($event) {
    this.treeInitialized.emit($event)
    this.isLoading = false
    this.changeDetectorRef.detectChanges()
  }

  onSelect(data) {
    if (data) {
      this.nodeSelected.emit(data);
    }
  }

  select(uri: string, iPathTuple: IPathTuple) {
    this.treeBaseComponent.selectByUri(uri, iPathTuple)
  }

  expandPathInTree(path2Expand: string, setActive: boolean = true) {
    this.path = path2Expand

  }

  findPath(node: ITreeNode, searchPath: string): ITreeNode {
    if (!node) { return undefined }
    if (this.nodeToPath(node) === searchPath) {
      return node
    }

    for (const child of node.children) {
      const childMatch = this.findPath(child, searchPath)
      if (childMatch) {
        return childMatch
      }
    }

    return undefined
  }

  fixPath(node: ITreeNode, parentNode: ITreeNode) {
    if (!node) { return undefined }

    node.path = parentNode.path + "/" + parentNode.name
    for (const child of node.children) {
      this.fixPath(child, node)
    }
  }

  findTreeNode(node: ITreeNode, uri: string): ITreeNode {
    if (node.uri === uri) {
      return node
    }

    for (const child of node.children) {
      const childMatch = this.findTreeNode(child, uri)
      if (childMatch) {
        return childMatch
      }
    }

    return undefined
  }

  onServerEvent(evt: any) {
    this.log.debug(`onServerEvent received : ${JSON.stringify(evt)}`)
    if ([EVENTS.ExportProgressEvent, EVENTS.ResourceExportedEvent].includes(evt.eventType)) {
      return
    }
    const didWeTriggerEvent = (USER && (evt['userInfo'] && (evt['userInfo'].email === USER.email)))
    let node: ITreeNode

    switch (evt.eventType) {
      case EVENTS.BulkDeletedResourcesEvent: {
        const iBulkDeletedResourcesEvent: IBulkDeletedResourcesEvent = evt as IBulkDeletedResourcesEvent
        const names: string[] = []
        for (const uri of iBulkDeletedResourcesEvent.uris) {
          const treeNode = this.findTreeNode(this.rootNode, uri)
          if (treeNode) {
            if (treeNode.parent) {
              const index2Delete = treeNode.parent.children.findIndex(n => n.uri === uri)
              if (index2Delete !== -1) {
                treeNode.parent.children.splice(index2Delete, 1)
                if (didWeTriggerEvent) {
                  this.expandPathInTree(this.nodeToPath(treeNode.parent))
                }
              }
            }
          }
        }

        break
      }
      case EVENTS.DeletedResourceEvent: {
        const iResourceDeletedEvent: IResourceDeletedEvent = evt as IResourceDeletedEvent
        const treeNode = this.findTreeNode(this.rootNode, iResourceDeletedEvent.resource.uri)
        if (treeNode) {
          const parentTreeNode = this.findTreeNode(this.rootNode, iResourceDeletedEvent.resource.parent_resource_uri)
          if (parentTreeNode) {
            const index2Delete = parentTreeNode.children.findIndex(n => n.uri === iResourceDeletedEvent.resource.uri)
            if (index2Delete !== -1) {
              parentTreeNode.children.splice(index2Delete, 1)
              if (didWeTriggerEvent && evt["connectionId"] === this.socketService.socket_id) {
                this.expandPathInTree(this.nodeToPath(parentTreeNode))
              }
            }
          }
        }

        break
      }
      case EVENTS.FolderCreatedEvent: {
        const folderCreatedEvent = (evt as IFolderCreatedEvent)
        const parentNode = this.findTreeNode(this.rootNode, folderCreatedEvent.resource.parent_resource_uri)
        if (parentNode) {
          if (parentNode.children.find(f => f.name === folderCreatedEvent.resource.name)) {
            this.log.debug(`Folder already exists ${folderCreatedEvent.resource.path}/${folderCreatedEvent.resource.name}`)
            break
          }
          const newNode: ITreeNode = {
            id: folderCreatedEvent.resource.uri,
            uri: folderCreatedEvent.resource.uri,
            name: folderCreatedEvent.resource.name,
            path: folderCreatedEvent.resource.path,
            children: [],
            parent: parentNode,
            level: parentNode.level + 1
          }
          parentNode.children.push(newNode)
          if (didWeTriggerEvent) {
            this.expandPathInTree(this.nodeToPath(parentNode), false)
          }
        }
        break
      }
      case EVENTS.RenamedEvent: {
        const iRenamedEvent: IRenamedEvent = evt as IRenamedEvent
        if (iRenamedEvent.resource.kind === RESOURCE_KIND.FOLDER) {
          const treeNode = this.findTreeNode(this.rootNode, iRenamedEvent.resource.uri)
          if (treeNode) {
            treeNode.name = iRenamedEvent.name
            this.fixPath(treeNode, treeNode.parent)
          }
        }
        break
      }
      case EVENTS.ResourceMovedEvent: {
        const iResourceMovedEvent: IResourceMovedEvent = evt as IResourceMovedEvent
        if (iResourceMovedEvent.resource.kind === RESOURCE_KIND.FOLDER) {
          this.moveIt(iResourceMovedEvent)
        }
        break
      }
      case EVENTS.BulkMovedResourceEvent: {
        const iBulkResourceMovedEvent: IBulkMovedResourceEvent = evt as IBulkMovedResourceEvent
        const bh = new BulkHelper()
        const batches = bh.splitIntoBatches(iBulkResourceMovedEvent.sourceUris)

        for(const batch of batches) {
          const q: IBulkFindResourcesByUrisQuery = {
            queryType: QUERIES.BulkFindResourcesByUrisQuery,
            uris: batch
          }
          this.cleanupService.addSubscription(
            this.universalQueryService.query(q).subscribe((results: IResource[]) => {
              const folders: IResource[] = []
              for(const r of results) {
                if (r.kind === RESOURCE_KIND.FOLDER) {
                  folders.unshift(r)
                }
              }
              if (folders.length) {
                for(const uri of iBulkResourceMovedEvent.sourceUris) {
                  const resource = folders.find(f => f.uri === uri)
                  if (resource) {
                    const iResourceMovedEvent: IResourceMovedEvent = {
                      eventType: EVENTS.ResourceMovedEvent,
                      uid: uuid(),
                      sourceUri: uri,
                      targetUri: iBulkResourceMovedEvent.targetUri,
                      resource
                    }

                    this.moveIt(iResourceMovedEvent)
                  }
                }
              }
            }, (e) => { this.log.error(e)})
          )
        }
        break
      }
      case EVENTS.UpdatedResourceEvent: {
        const iResourceUpdatedEvent: IResourceUpdatedEvent = evt as IResourceUpdatedEvent
        if (iResourceUpdatedEvent.resource.kind === RESOURCE_KIND.FOLDER) {
          const treeNode = this.findTreeNode(this.rootNode, iResourceUpdatedEvent.resource.uri)
          if (treeNode) {
            treeNode.name = iResourceUpdatedEvent.name
          }
        }
        break
      }
      default: {

      }
    }
  }

  moveIt(iResourceMovedEvent: IResourceMovedEvent) {
    const treeNode = this.findTreeNode(this.rootNode, iResourceMovedEvent.sourceUri)
    const newParent = this.findTreeNode(this.rootNode, iResourceMovedEvent.targetUri)

    if (treeNode) {
      const parentTreeNode = this.findPath(this.rootNode, treeNode.path)
      if (parentTreeNode) {
        const index2Delete = parentTreeNode.children.findIndex(n => n.uri === iResourceMovedEvent.resource.uri)
        if (index2Delete !== -1) {
          parentTreeNode.children.splice(index2Delete, 1)
        } else {
          console.warn("Parentree node could not be located")
        }
      }
    }

    if (newParent) {
      const existingIdx = newParent.children.findIndex(n => n.uri === treeNode.uri)
      if (existingIdx == -1) {
        newParent.children.push(treeNode)
      }
      this.fixPath(treeNode, newParent)
    }
  }
}
