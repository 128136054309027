import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { QUERIES } from 'prunus-common/dist';
import { CleanupService } from '../../infrastructure/CleanupService';
import { UniversalQueryService } from '../../infrastructure/services/universal-query.service';

import { Subject } from 'rxjs/internal/Subject';
import { CONTEXT } from '../../infrastructure/Context';
import { AbstractDataSourceService } from '../../infrastructure/services/AbstractDataSourceService';

@Component({
  selector: 'app-advanced-search-modal',
  templateUrl: './advanced-search-modal.component.html',
  styleUrls: ['./advanced-search-modal.component.scss']
})
export class AdvancedSearchModalComponent implements OnInit, OnDestroy {
  suggestions$ = new Subject<string>();
  suggestionsInput$ = new Subject<string>();
  suggestionsLoading = false;
  filtertype: string[] = [];
  users: string[] = [];
  //selectedUsers: string[] = [];
  path: string;
  or: boolean;
  searchTerms: string[] = [];
  allowedResourcesExtensions: string[] = [];
  cleanupService = new CleanupService(AdvancedSearchModalComponent.name);
  suggestionsUsers$ = new Subject<string>();
  suggestionsUsersInput$ = new Subject<string>();
  suggestionsUsersLoading = false;
  queryParams: any;

  constructor(
    public router: Router,
    private universalQueryService: UniversalQueryService,
    public bsModalRef: BsModalRef,
    private route: ActivatedRoute,
    private abstractDataSourceService: AbstractDataSourceService,
  ) { }

  ngOnInit() {
    /*this.cleanupService.addSubscription(
      
      this.universalQueryService.query({
        queryType: QUERIES.DistinctUsersQuery
      }).subscribe(result => {
        this.users = result;
        if (this.queryParams) {
          if (this.queryParams.users) {
            this.selectedUsers = this.queryParams.users.toString().split(',');
          } else {
            this.selectedUsers = [];
          }
        }
      })
    );*/
    this.cleanupService.addSubscription(
      this.universalQueryService.query({
        queryType: QUERIES.AllowedResourceExtensionsQuery
      }).subscribe(result => {
        this.allowedResourcesExtensions = result.sort();
        this.allowedResourcesExtensions.unshift('');

        if (this.queryParams) {
          if (this.queryParams.filtertype) {
            CONTEXT.filtertype = this.filtertype = this.queryParams.filtertype.toString().split(',');
          } else {
            CONTEXT.filtertype = this.filtertype = [];
          }
        }
        
      })
    );
    this.cleanupService.addSubscription(
      this.route.queryParams.subscribe((params: any) => {
        this.queryParams = params;
      })
    );
  }

  ngOnDestroy(): void {
    this.cleanupService.cleanupSubscriptions();
  }

  onSearch(): void {
    let url = '/main/tile?';
    url += 'tags=' + this.searchTerms.join(',');
    url += '&or=' + this.or;
    if (this.path) {
      url += '&path=' + this.path;
    }
    if (this.filtertype && this.filtertype.length) {
      CONTEXT.filtertype = this.filtertype;
      url += '&filtertype=' + this.filtertype.join(',');
    } else {
      url += "&filtertype=";
      CONTEXT.filtertype = [];
    }
    if (this.users && this.users.length) {
      url += '&users=' + this.users.map(u => encodeURIComponent(u)).join(',');
    }
    // filters changed datasource should be reset !!!
    this.abstractDataSourceService.clearData();
    this.router.navigateByUrl(url);

    this.onClose();
  }

  onSelectExtention(extensions: string[]) {
    this.filtertype = extensions;
  }

  onSelectUser(users: string[]) {
    this.users = users;
  }

  onClose() {
    this.bsModalRef.hide();
  }

  onOpen() {
    if (!this.suggestionsInput$) {
      return;
    }
  }

}
