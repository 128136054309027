import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { COMMANDS, CONSTRAINTS, EVENTS, IBulkLinkedPublicationsEvent, ICommand } from 'prunus-common/dist';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { CleanupService } from '../../infrastructure/CleanupService';
import { UniversalCommandService } from '../../infrastructure/cqrs/universal-command.service';
import { LoggingsService } from '../../infrastructure/LoggingsService';
import { ServerSocketService } from '../../infrastructure/services/server-socket.service';
import { UniversalQueryService } from '../../infrastructure/services/universal-query.service';
import { PARAMS } from '../../params';
import { IResource } from '../../resource/interface/IResource';
import { IPublication } from 'redwood-model/dist';
import { User } from '../../user/model/User';
import { USER } from '../../user/USER';
import { ExplorerMultiSelectService } from '../explorer-view/explorer-multi-select.service';
import { MESSAGES } from '../../MESSAGES';
import { NotificationService } from '../../infrastructure/services/notification-service';

@Component({
  selector: 'app-link-publication-modal',
  templateUrl: './link-publication-modal.component.html',
  styleUrls: ['./link-publication-modal.component.scss']
})
export class LinkPublicationModalComponent implements OnInit, OnDestroy  {
  title = 'Link resource aan publicatie(s)';
  suggestions$ = new Subject<IPublication[]>();
  suggestionsInput$ = new Subject<IPublication>();
  suggestionsLoading = false;
  selectedPublications: IPublication[] = [];
  _resources: IResource[];
  cleanupService = new CleanupService(LinkPublicationModalComponent.name);
  onNext = new Subject<any>();
  user: User;
  publications: IPublication[] = [];
  numberOfLinkedResources = 0;
  public MAX_SELECTED_ITEMS = CONSTRAINTS.MAX_NR_OF_USER_TAGS;
  
  constructor(public bsModalRef: BsModalRef,
              private universalCommandService: UniversalCommandService,
              private universalQueryService: UniversalQueryService,
              private notificationService: NotificationService,
              private log: LoggingsService,
              private serverSocketService: ServerSocketService,
              private explorerMultiSelectService: ExplorerMultiSelectService,
  ) {
  }

  ngOnInit(): void {
    this.cleanupService.addSubscription(
      this.serverSocketService.subscribe2ServerEvents().subscribe(
        (evt) => this.onServerEvent(evt)
      )
    );

    this.user = USER;
    this.publications = [ ... USER.publications ];

    this.suggestionsInput$.pipe(
      distinctUntilChanged(),
      debounceTime(500)
    ).subscribe((term) => {
      this.suggestions$.next(this.publications);
    });

  }

  ngOnDestroy(): void {
    this.cleanupService.cleanupSubscriptions();
  }

  get resources(): IResource[] {
    return this._resources;
  }

  set resources(value: IResource[]) {
    this._resources = value;
    const sliced = this.explorerMultiSelectService.selectedResources.slice(0, 10).map(r => r.name);
    sliced.push('...');
    this.title = `Link ${value.length} resource(s) aan '${sliced.join(',')}' aan publicatie(s)`;
    const uniquePubIds = new Set<number>();
    this._resources.forEach(r => {
      if (!r.publication_ids) {
        return;
      }
      r.publication_ids.forEach(p => uniquePubIds.add(p));
    } );
    this.selectedPublications =
    this.publications.filter(p => uniquePubIds.has(p.id));
  }

  onClose() {
    this.bsModalRef.hide();
  }

  onSave() {
    this.numberOfLinkedResources = 0;
    const cmd: ICommand = {
      uid: uuid(),
      commandType: ''
    };
    const resourceNames: string[] = [];
    const publicationIds = this.selectedPublications.map(p => p.id);
    if (this.resources.length > 1) {
      cmd.commandType = COMMANDS.BulkLinkPublicationsCommand;
      cmd['publication_ids_per_uri'] = {};
    } else {
      cmd.commandType = COMMANDS.LinkPublicationCommand;
      cmd['uri'] = this.resources[0].uri;
      cmd['publication_ids'] = publicationIds;
      resourceNames.push(this.resources[0].name);
    }

    if (this.resources.length >  1) {
      this.resources.forEach(resource => {
        cmd['publication_ids_per_uri'][resource.uri] = publicationIds;
        resourceNames.push(resource.name);
      });
    }

    this.cleanupService.addSubscription(
      this.universalCommandService.handle(cmd).subscribe(
        () => {
          let msg = `Item: ${resourceNames.join(', ')} wordt `;
          if (!publicationIds.length) {
            msg += 'ontkoppeld aan publicaties.';
          } else {
            msg += `gekoppeld aan publicaties ${this.selectedPublications.map(p => p.slug).join(', ')}`;
          }
          this.notificationService.success(msg);
        }
      )
    );
  }

  onServerEvent(evt: any)  {
    switch (evt.eventType) {
      case EVENTS.PublicationLinkedEvent: {
        this.numberOfLinkedResources++;
        let msg = `Resource is `;
        if (!evt.publication_ids || !evt.publication_ids.length) {
          msg += 'ontkoppeld van alle publicaties.';
        } else {
          msg += `gekoppeld aan publicaties ${this.selectedPublications.map(p => p.slug).join(', ')}`;
        }
        this.notificationService.success(msg, MESSAGES.SRV_MSG_TOAST_TITLE);
        const next = {};
        this.resources.forEach(r => {
          next[r.uri] = evt.publication_ids;
          r.publication_ids = evt.publication_ids; 
        });
        if (this.numberOfLinkedResources === this.resources.length) {
          this.onClose();
        }
        this.onNext.next(next);
        break;
      }
      case EVENTS.BulkLinkedPublicationsEvent: {
        const iBulkLinkedPublicationsEvent: IBulkLinkedPublicationsEvent = evt as IBulkLinkedPublicationsEvent;
        let msg = `Resources `;
        for (const uri of Object.keys(iBulkLinkedPublicationsEvent.publication_ids_per_uri)) {
          this.numberOfLinkedResources++;
          const resource = this.resources.find(r => r.uri === uri);
          if (resource) {
            if (msg !== `Resources `) {
              msg += ',';
            }
            msg += resource.name + ' ';
            if (!iBulkLinkedPublicationsEvent.publication_ids_per_uri[uri] ||
                !iBulkLinkedPublicationsEvent.publication_ids_per_uri[uri].length) {
              msg += 'ontkoppeld van alle publicaties.';
            } else {
              msg += `gekoppeld aan publicaties ${this.selectedPublications.map(p => p.slug).join(', ')}`;
            }
            resource.publication_ids = iBulkLinkedPublicationsEvent.publication_ids_per_uri[uri];
          }
        }
        this.notificationService.success(msg, MESSAGES.SRV_MSG_TOAST_TITLE);
        if (this.numberOfLinkedResources === this.resources.length) {
          this.onClose();
        }
        this.onNext.next(iBulkLinkedPublicationsEvent.publication_ids_per_uri);
        break;
      }
      default: {
        this.log.debug(`unhandled event type ${evt.eventType}, ignore ....`);
      }
    }
  }

  onOpen() {
    if (!this.suggestionsInput$) {
      return;
    }
  }

  changeSelectedPublications($event: IPublication[]) {
    this.selectedPublications = $event;
  }

}
