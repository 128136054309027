import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ITreeNode } from 'src/infrastructure/ITreeNode';
import { NotificationService } from 'src/infrastructure/services/notification-service';
import { TreeFoldersComponent } from '../tree-folders/tree-folders.component';
import { FileSystemService } from 'src/infrastructure/services/FileSystemService';
import { MESSAGES } from 'src/MESSAGES';
import { UploaderService } from '../upload-queue/UploaderService';
import { USER } from 'src/user/USER';

@Component({
  selector: 'app-select-upload-folder',
  templateUrl: './select-upload-folder.component.html',
  styleUrl: './select-upload-folder.component.scss'
})
export class SelectUploadFolderComponent {
  title = "Kies bestemmings map"
  message: string
  path: string
  selectedTreeNode: ITreeNode
  treeData: ITreeNode[] = []
  isInitialSelect: boolean | undefined = undefined

  constructor(public bsModalRef: BsModalRef,
    private notificationService: NotificationService,
    private modalService: BsModalService,
    private fileSystemService: FileSystemService,
    private uploaderService: UploaderService,

  ) {
  }

  ngOnInit() { 
  }

  ngOnDestroy(): void {

  }

  onSelect(iTreeNode: ITreeNode): void {
    if (USER.isTeacher) {
      if (iTreeNode.name === "prunus" && !iTreeNode.parent) {
        this.selectedTreeNode = undefined
        this.notificationService.warning("U kan enkel de mijn bestanden map of submappen daarvan als bestemming kiezen.")
        return
      }
    }
    this.selectedTreeNode = iTreeNode
  }

  onApply() {
    const path = this.selectedTreeNode?.path + "/" + this.selectedTreeNode.name
    if (this.fileSystemService.path === path) {
      this.notificationService.warning(MESSAGES.WARNING_CURRENT_PATH_NO_CHANGE, MESSAGES.WARNING_TOAST_TITLE)
      return 
    }
    const oldPath = this.fileSystemService.path
    this.fileSystemService.path = path
    const tuple = { newPath: path, oldPath }
    this.uploaderService.onUploadRootPathChange(tuple)
    this.onClose()
  }

  onClose() {
    this.bsModalRef.hide()
  }

}
