<div class="modal-header">
  <h5 class="modal-title pull-left">Geavanceerd zoeken</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
    <edu-icon-delete-fat type="filled"></edu-icon-delete-fat>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex flex-column">
    <label for="selectExtensions">op bestandstypes</label>
    <ng-select
      id="selectExtensions"
      [items]="allowedResourcesExtensions"
      [multiple]="true"
      placeholder="zoek een extensie"
      [loading]="suggestionsLoading"
      [ngModel]="filtertype"
      [ngModelOptions]="{standalone: true}"
      [typeahead]="suggestionsInput$"
      (open)="onOpen()"
      (change)="onSelectExtention($event)"
      class="margin-small"
      loadingText="bezig met laden ..."
      [closeOnSelect]="true"
    >
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div [ngOptionHighlight]="search">{{ item }}</div>
      </ng-template>
    </ng-select>
  </div>
  <!--
  <div class="d-flex flex-column">
    <label for="selectUsers">op gebruikers</label>
    <ng-select
      id="selectUsers"
      [items]="users"
      [multiple]="true"
      placeholder="zoek een gebruiker"
      [loading]="suggestionsUsersLoading"
      [ngModel]="selectedUsers"
      [ngModelOptions]="{standalone: true}"
      [typeahead]="suggestionsUsersInput$"
      (open)="onOpen()"
      (change)="onSelectUser($event)"
      class="margin-small"
      loadingText="bezig met laden ..."
      [closeOnSelect]="true"
    >
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div [ngOptionHighlight]="search">{{ item }}</div>
      </ng-template>
    </ng-select>
  </div>-->
</div>
<div class="modal-footer">
  <div class="d-flex flex-row justify-content-end">
    <button type="button"
            class="btn btn-primary ml-2" (click)="onSearch()">
      <edu-icon-magnifier size="1rem" color="white" type="filled"></edu-icon-magnifier> &nbsp;zoek
    </button>
  </div>
</div>

