<div class="modal-header">
    <h5 class="modal-title pull-left">{{title}}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="mt-3 mb-3" style="display: flex; justify-content: center;">
      <edu-icon-sync class="edu-spin-counter" color="black" size="35px" type="filled"></edu-icon-sync>
        <div [hidden]="progressMessage" class="ml-3">export aan het het voorbereiden</div>
        <br />
        <div class="ml-3">{{progressMessage}}</div>
        <br />
    </div>
  </div>
