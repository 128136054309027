import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core'
import { SlTree } from '@shoelace-style/shoelace'
import { ITreeNode } from "../../../infrastructure/ITreeNode"
import { IPathTuple } from 'src/infrastructure/services/FileSystemService'

@Component({
  selector: 'tree-base',
  templateUrl: './tree-base.component.html',
  styleUrls: ['./tree-base.component.scss']
})
export class TreeBaseComponent implements AfterViewInit, OnChanges {
  @Input() treeData: ITreeNode[]
  @Input() currentPath: string
  @Output()
  itemSelected = new EventEmitter<ITreeNode>()
  @Output()
  initialized = new EventEmitter<any>()
  @ViewChild('slTree')
  slTree: ElementRef<SlTree>
  treeElement: SlTree

  ngAfterViewInit() {
    setTimeout(() => {
      let preventEmit = false
      this.treeElement = this.slTree.nativeElement

      const path = this.currentPath
      let first: any = this.treeElement.children[0]
      if (path) {
        const pathParts = path.split('/').filter(part => part !== '') //.map(part => decodeURIComponent(part));
        if (pathParts?.length !== 1  && this.treeElement.children.length) {
          this.openTree(pathParts, Array.from(this.treeElement.children) as HTMLElement[])
          preventEmit = true
        } else {
          first['selected'] = true
          first['expanded'] = true
        }
      }
      else {
        first['selected'] = true
        first['expanded'] = true
      }

      if (!preventEmit) {
        const selectedUri = first.dataset.uri
        const found = this.findTreeNodeByUri(selectedUri)
        if (found) {
          this.itemSelected.emit(found)
        }
      }

      this.treeElement.addEventListener('sl-selection-change', this.onSelectionChange.bind(this))
      this.initialized.emit(true)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.treeElement) {
      if (changes['currentPath']) {
        const path = encodeURIComponent(changes['currentPath'].currentValue)
        const pathParts = path.split('%2F').filter(part => part !== '').map(part => decodeURIComponent(part))

        if (pathParts?.length !== 1  && this.treeElement.children.length) {
          this.openTree(pathParts, Array.from(this.treeElement.children) as HTMLElement[])
        }
      }
    }
  }

  onSelectionChange(event: any): void {
    const selected = event.detail.selection[0]
    selected['expanded'] = true
    const selectedUri = selected.dataset.uri
    const found = this.findTreeNodeByUri(selectedUri)
    if (found) {
      this.itemSelected.emit(found)
    }
  }

  openTree(pathParts: string[], elements: HTMLElement[]): void {
    let currentElements = elements

    for (let index = 0; index < pathParts.length; index++) {
      const part = pathParts[index].trim()

      const foundElement = currentElements.find(element => {
        const trimmedText = element.firstChild?.textContent?.trim()
        return trimmedText === part
      })

      if (!foundElement) {
        console.warn(`Folder not found: ${part}`)
        return
      }

      foundElement['expanded'] = true
      foundElement['selected'] = false

      if (index === pathParts.length - 1) {
        foundElement['selected'] = true
        const selectedUri = foundElement.dataset.uri
        const found = this.findTreeNodeByUri(selectedUri)
        this.itemSelected.emit(found)
      }

      currentElements = Array.from(foundElement.children) as HTMLElement[]
    }
  }

  deselectNode(pathParts: string[], elements: HTMLElement[]): void {
    let currentElements = elements

    let foundElement: HTMLElement
    for (let index = 0; index < pathParts.length; index++) {
      const part = pathParts[index].trim()
      const foundElement = currentElements.find(element => {
        const trimmedText = element.firstChild?.textContent?.trim()
        return trimmedText === part
      })

      if (!foundElement) {
        console.warn(`Folder part to deselect not found: ${part}`)
        return
      }

      currentElements = Array.from(foundElement.children) as HTMLElement[]

      if (index === pathParts.length - 1) {
        foundElement["selected"] = false
      }
    }
  }

  findTreeNodeByUri(uri: string): ITreeNode | undefined {
    const recursiveFind = (dataSet: ITreeNode[]): ITreeNode | undefined => {
      for (const data of dataSet) {
        if (data.uri === uri) {
          return data
        }
        if (data.children.length) {
          const found = recursiveFind(data.children)
          if (found) {
            return found
          }
        }
      }
      return undefined
    }

    return recursiveFind(this.treeData)
  }

  selectByUri(uri: string, iPathTuple: IPathTuple) {
    // close current expanded tree
    this.deselectNode(iPathTuple.oldPath.split("/").filter(part => part !== ''), Array.from(this.treeElement.children) as HTMLElement[])
    if (this.treeElement.dataset?.uri === uri) {
      this.onMatch(this.treeElement, uri, iPathTuple)
      return
    }

    this.traverseHTMLCollection(this.treeElement.children, uri, iPathTuple)
  }

  onMatch(element: HTMLElement, uri: string, iPathTuple: IPathTuple) {
    element["expanded"] = element["selected"] = true
    const found = this.findTreeNodeByUri(uri)
    this.itemSelected.emit(found)
    this.openTree(iPathTuple.newPath.split("/").filter(part => part !== ''), Array.from(element.children) as HTMLElement[])
  }

  traverseHTMLCollection(collection, uri: string, iPathTuple: IPathTuple) {
    for (let i = 0; i < collection.length; i++) {
      const element = collection[i]
      if (element.dataset?.uri === uri) {
        this.onMatch(element, uri, iPathTuple)
        break
      }
      if (element.children.length > 0) {
        this.traverseHTMLCollection(element.children, uri, iPathTuple)
      }
    }
  }
}




