import { ChangeDetectorRef, Component } from '@angular/core';
import { IBulkFindResourcesByUrisQuery, IFolderInfoQuery, IFolderInfoQueryResult, IResource, QUERIES } from 'prunus-common/dist';
import { UniversalQueryService } from 'src/infrastructure/services/universal-query.service';
import { UploaderService } from '../upload-queue/UploaderService';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FileSystemService } from 'src/infrastructure/services/FileSystemService';

export class BulkHelper<T> {
  batchSize = 25

  splitIntoBatches(items: T[]): T[][] {
    const batches: T[][] = [];
    if (items.length) {
      if (items.length > this.batchSize) {
        let counter = 1
        for (let i = 0; i < items.length; i += this.batchSize) {
          batches.push(items.slice(i, counter * this.batchSize))
          counter++
        }
      } else {
        batches.push(items)
      }
    }

    return batches
  }
}


@Component({
  selector: 'app-batch-upload-complete-modal',
  templateUrl: './batch-upload-complete-modal.component.html',
  styleUrls: ['./batch-upload-complete-modal.component.scss']
})
export class BatchUploadCompleteModalComponent {
  folderInfo: any
  nrOfUploads: number
  title = "Opladen eind rapport"
  numberOfPersistedResources = 0
  persistedResources: IResource[] = []
  notPersistedUris = []
  missingFiles: string[] = []
  verifyOutPut = ""
  hideVerify = false
  constructor(
    private universalQueryService: UniversalQueryService,
    private changeDetectorRef: ChangeDetectorRef,
    private uploaderService: UploaderService,
    private bsModalRef: BsModalRef,
    private fileSystemService: FileSystemService,
  ) {
    
  }

  ngOnInit(): void { 
    this.nrOfUploads = this.uploaderService.copyIQItems.length
  }

  get path(): string {
    return this.fileSystemService.path
  }

  verify() {
    this.hideVerify = true
    this.changeDetectorRef.detectChanges()
    const bh = new BulkHelper<string>()
    const batches = bh.splitIntoBatches(this.uploaderService.copyIQItems.map(cqi => cqi.uri))

    this.numberOfPersistedResources = 0
    this.persistedResources = []
    this.missingFiles = []
    this.verifyOutPut = ""
    let receivedBatches = 0
    for(let batch of batches) {
      const q : IBulkFindResourcesByUrisQuery = {
        uris: batch,
        queryType: QUERIES.BulkFindResourcesByUrisQuery
      }
      const processResults = () => {
        if (receivedBatches === batches.length) {
          if (this.numberOfPersistedResources !== this.uploaderService.copyIQItems.length) {
            let counter = 0
            for (const iQItem of this.uploaderService.copyIQItems) {
              this.verifyOutPut = `${counter++} verifieer uri ${iQItem.uri} ${iQItem.path} ${iQItem.filename}  </br>`
              this.changeDetectorRef.markForCheck()
              if (!this.persistedResources.find(pr => pr.uri === iQItem.uri)) {
                this.missingFiles.push(iQItem.fullPath + iQItem.filename)
                this.missingFiles = [...this.missingFiles]
                this.changeDetectorRef.markForCheck()
              }
              this.missingFiles = [...this.missingFiles]
            }
          } else {
            this.verifyOutPut += "Geen missing files op de server, telling klopt !"
          }
        }
        this.hideVerify = false
        this.changeDetectorRef.detectChanges()
      }
      this.universalQueryService.query(q).subscribe((resources: IResource[]) => {
        this.numberOfPersistedResources += resources.length
        this.persistedResources = this.persistedResources.concat(resources)
        receivedBatches++
        processResults()
      })

      processResults()
    }
  }

  onClose() {
    this.bsModalRef.hide();
  }

}
