<div class="modal-header">
    <h5 class="modal-title pull-left">{{title}}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
      <edu-icon-delete-fat type="filled"></edu-icon-delete-fat>
    </button>
</div>
<div class="modal-body">
  <div class="scrollable-div">
    <ul style="list-style: none;margin-left: -25px;">
      <li *ngFor="let notification of notifications" [ngClass]="{
        'bg-error': notification.level === 'error',
        'bg-success': notification.level === 'success',
        'bg-warning': notification.level === 'warning',
        'bg-other': notification.level !== 'error' && notification.level !== 'success' && notification.level !== 'warning'
      }">
       {{notification.date.toLocaleDateString()}}-{{notification.date.toLocaleTimeString()}} {{ notification.text }}
      </li>
    </ul>
  </div>
</div>
