<div class="modal-header">
  <h5 class="modal-title pull-left">{{title}}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
    <edu-icon-delete-fat type="filled"></edu-icon-delete-fat>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex flex-column">
    <input type="text" (keypress)="onKeyPress($event)" placeholder="nieuwe naam" onfocus="this.select();" [(ngModel)]="name" [maxlength]="MAX_LENGTH">
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex flex-row justify-content-end">
    <button type="button" [disabled]="!name || name === MY_FILES_NAME" class="btn btn-primary" (click)="onSave()">bewaren</button>
    <button type="button" class="btn btn-danger ml-2" (click)="onClose()"> annuleren</button>
  </div>
</div>
