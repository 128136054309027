import { Injectable, signal } from "@angular/core"
import { CONSTRAINTS, DOCUMENT_EXTENSIONS, IBulkUploadStartCommand, QUERIES } from "prunus-common/dist"
import { Subject } from "rxjs"
import { LoggingsService } from "../../infrastructure/LoggingsService"
import { UniversalQueryService } from "../../infrastructure/services/universal-query.service"
import { formatMsg, MESSAGES } from "../../MESSAGES"
import { IQItem } from "./IQItem"
import { NotificationService } from "../../infrastructure/services/notification-service"
import { IPathTuple } from "src/infrastructure/services/FileSystemService"

export interface INewFolderWithUri {
  name: string
  uri: string
}

@Injectable({
  providedIn: 'root'
})
export class UploaderService {
  newFolders: INewFolderWithUri[] = []
  iQItems: IQItem[] = []
  copyIQItems: IQItem[] = []
  isUploading = false
  allowedResourcesExtensions: string[] = []
  MAX_UPLOAD_SIZE = 209780489 // 200mb in de volksmond
  iQItems$ = new Subject<IQItem[]>()
  isBatchUploading: boolean
  errorQItems = []
  bulkUid = ""
  isBulkUploadProcessingOnServerSignal = signal(false)
  uploadRootPathSignal = signal<IPathTuple>(undefined)

  constructor(
    private notificationService: NotificationService,
    private log: LoggingsService,
    private universalQueryService: UniversalQueryService,
  ) {
    this.universalQueryService.query({
      queryType: QUERIES.AllowedResourceExtensionsQuery
    }).subscribe(result => {
      this.allowedResourcesExtensions = result
    })
  }

  onUploadRootPathChange(pathTuple: IPathTuple) {
    const changePath = (iQItems: IQItem[]) => {
      let index = 0
      for(const iQItem of iQItems) {
        const partWithoutOldPath = iQItem.fullPath.slice(pathTuple.oldPath.length)
        iQItem.fullPath = pathTuple.newPath + partWithoutOldPath
        const fileNameIdx = partWithoutOldPath.lastIndexOf(iQItem.file.name) - 1 // !! iQItem.filename you can't use here in case an uri (for import) is embedded in the name the parsing logic here will be wrong
        iQItem.path = pathTuple.newPath + partWithoutOldPath.substring(0, fileNameIdx)
        iQItems[index++] = { ... iQItem }
      }
    }

    changePath(this.iQItems)
    this.iQItems = [ ... this.iQItems ]
    changePath(this.copyIQItems)
    this.copyIQItems = [ ... this.copyIQItems ]
    for(const newFolder of this.newFolders) {
      const part = newFolder.name.slice(pathTuple.oldPath.length)
      newFolder.name = pathTuple.newPath + part
    }
    this.uploadRootPathSignal.set(pathTuple)
  }
  
  copyItems() {
    this.copyIQItems = [ ...this.iQItems ]
  }

  addUpload(iQItem: IQItem): boolean {
    if (iQItem.filename.length > CONSTRAINTS.MAX_RESOURCE_NAME_LENGTH) {
      this.notificationService.error(
        formatMsg(MESSAGES.MAX_LENGTH_ITEM_NAME, {MAX_RESOURCE_NAME_LENGTH: CONSTRAINTS.MAX_RESOURCE_NAME_LENGTH, length: iQItem.filename.length})
      )
      this.iQItems$.next(undefined)

      return false
    } 
    for(let item of this.iQItems) {
      if (item.file?.name === iQItem.file?.name && item.fullPath === iQItem.fullPath) {
        this.notificationService.error(
          formatMsg(MESSAGES.UPLOAD_SHOULD_BE_UNIQUE, {name: iQItem.filename})
        ) 

        return false
      }
    }
    this.iQItems.push(iQItem)
    this.iQItems = [ ...this.iQItems ]
    this.onAfterAddingFile(iQItem)
    this.iQItems$.next(this.iQItems)

    return true
  }

  removeUpload(index) {
    this.iQItems.splice(index, 1)
    this.iQItems = [ ...this.iQItems ]
    this.iQItems$.next(this.iQItems)
  }

  getActive2UploadItems(): IQItem[] {
    return this.iQItems.filter(item => !(item.isError) && item.checked)
  }

  onAfterAddingFile(iQItem: IQItem) {
    const file = (iQItem.file as any) instanceof Array ? iQItem.file[0] : iQItem.file
    if (file.size > this.MAX_UPLOAD_SIZE) {
      this.notificationService.error(`Bestand '${file.name}' heeft maximum bestandsgrootte (200MB) overschreden en kan niet opgeladen worden.`)
      this.iQItems.splice(this.iQItems.length - 1, 1)

      return
    }
    let extension: string
    const parts = file.name.split('.')
    extension = parts[parts.length - 1]
    if (extension) {
      extension = extension.toLocaleLowerCase()
    }
    if (this.allowedResourcesExtensions.indexOf(extension) === -1) {
      this.notificationService.error(`Bestand '${file.name}' niet ondersteunde extensie.`)
      this.iQItems.splice(this.iQItems.length - 1, 1)

      return
    }

    iQItem.checked = true
    iQItem.tags = []

    if (extension === 'tif' || extension === 'tiff') {
      iQItem.thumb = null
    } else if (DOCUMENT_EXTENSIONS.includes(extension)) {
      iQItem.thumb = `./assets/${extension}.svg `
    } else {
      this.generateThumb(file, iQItem)
    }

  }

  /**
* generates a thumbnail of the image to upload for preview
* @param {Blob} blob
* @param {number} index
*/
  generateThumb(blob: Blob, iQItem: IQItem): void {
    const reader = new FileReader()
    reader.onload = (e: any) => {
      iQItem.thumb = e.target.result
      this.iQItems$.next(this.iQItems)
    }
    reader.readAsDataURL(blob)
  }

  totalQSize(): number {
    const t = (this.iQItems.map(iQItem => iQItem.file).reduce(
      (previousValue, currentValue) => previousValue + currentValue.size,
     0) / 1024 / 1024)

    return t 
  }

}