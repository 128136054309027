<sl-tree #slTree class="tree-selectable tree-with-lines ">
  <ng-template #recursiveTree let-data>
    <sl-tree-item  [attr.data-uri]="data.uri" >
      {{ data.name }}
      <ng-container *ngIf="data.children?.length">
        <ng-container *ngFor="let child of data.children">
          <ng-container *ngTemplateOutlet="recursiveTree; context: { $implicit: child }"></ng-container>
        </ng-container>
      </ng-container>
    </sl-tree-item>
  </ng-template>

  <ng-container *ngFor="let data of treeData">
    <ng-container *ngTemplateOutlet="recursiveTree; context: { $implicit: data }"></ng-container>
  </ng-container>
</sl-tree>
