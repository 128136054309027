import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { MESSAGES } from './MESSAGES'
import { isTrustedMessageSource } from './SSO/isTrustedMessageSource'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { CONTEXT } from './infrastructure/Context'
import { showSpinner } from './spinner'
import { TOAST_LEVELS, showToast } from './toast'
import { USER, setUser } from './user/USER'
import { fullMoonIcon, checkFatIcon, videoIconIcon, folderIcon, cloudUploadAltIcon, syncIcon, magnifierIcon, listIcon, binIcon, explorerIcon, fileImageIcon, filePdfIcon, filePowerpointIcon, fileExcelIcon, fileWordIcon, pencilIcon
} from 'style-library-2.0/dist/icons/iconComponents';
import customElementDefiner from 'style-library-2.0/dist/utils/customElementDefiner';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';
import '@shoelace-style/shoelace/dist/shoelace.js';
import { CognitoJwtVerifier } from "aws-jwt-verify"
import { User } from './user/model/User'
import { fetchPublications } from './PUBLICATIONS'
import { claimsToUser, ensureValidJWT, getClaimsFromIdToken, ID_TOKEN_HEADER, parseJwt, userFromIdToken } from 'jwt-helper/dist'
const packageJson = require('package.json')
// declared globals in https://sso.sharklazers.be/sso-client.js
declare function getSSOUrl()
declare function hideSSO()
declare function makeSSOVisible(noRedirect?: boolean)

showSpinner('Prunus wordt opgestart ...')
setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.16.0/cdn/');


customElementDefiner.defineBulk([fullMoonIcon, checkFatIcon, listIcon, videoIconIcon, folderIcon, cloudUploadAltIcon, syncIcon, magnifierIcon, listIcon, binIcon, explorerIcon, fileImageIcon, filePdfIcon, filePowerpointIcon, fileExcelIcon, fileWordIcon, pencilIcon]);

console.log(`version : ${packageJson.version}`)
CONTEXT.ensureIsInitialised()

window.addEventListener("message", async (event) => {
  if (event.origin === location.origin) {
      return
  }
  if (event.origin && !isTrustedMessageSource(event.origin)) {
      return
  }

  const data = typeof event.data === "string" ? JSON.parse(event.data) : event.data
  switch(data?.event) {
      case "tokenChange": {
        let user: User
        if (data.access_token && data.user) {
          user = data.user
        } else if (data.access_token && data.id_token) {
          const userdata = await userFromIdToken(data.id_token)
          user = new User()
          Object.assign(user, userdata) 
          console.debug("currentUser from claims", JSON.stringify(USER))
        }
        if (user) {
          const hasUserPermission = setUser(data.user)
          if (hasUserPermission) {
            global["access_token"] = data.access_token
            global["id_token"] = data.id_token
            if (USER.isAdmin) {
              USER.publications = await fetchPublications(data.access_token)
            }
            await ensureNgStarted(data.access_token)

            const userChanged = USER && USER["username"] !== data.user.username
            if (userChanged) {
                location.reload()
            }

            hideSSO()
            return
          } else {
            showSpinner("U beschikt over onvoldoende rechten om prunus te starten.", true)
            break
          }          
        } 
        makeSSOVisible()
        break
      }
      case "onCreateLoginModal": {
        window.open(data.url, data.name)
        break
      }
      case "LogoutResponse": {
          break
      }
  }

}, false)

export const ensureNgStarted = async (token: string): Promise<any> => {
  if (ngStarted) { return }
  // tslint:disable-next-line:no-console
  ngStarted = true
  global["access_token"] = token
  hideSSO()
  return platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err))
}

let JWT
let ngStarted: boolean

if ((window as any).safari) {
  showToast(MESSAGES.SAFARI, TOAST_LEVELS.ERROR, "Fout")
} else {
  (async (): Promise<void> => {
    if (ngStarted) { return }

    if (environment.production) {
      enableProdMode()
    }
  })()
}

document.addEventListener('visibilitychange', async () => {
  if (document.visibilityState === 'visible') { // 
      try {
        await ensureValidJWT(global["access_token"])
      } catch(e) {
        location.reload()
      }
  }
})










