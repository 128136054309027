import {Injectable, NgZone} from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import {ROLES} from 'redwood-model/dist';
import { USER } from '../user/USER';

@Injectable({
  providedIn: 'root'
})
export class AdminCanActivate  {
  constructor(private router: Router,
              private zone: NgZone
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve: Function, reject: Function) => {
      try {
        this.zone.runOutsideAngular(() => {
            if (USER.hasRoles(ROLES.ALL_INTERNAL_USERS)) {
              resolve(true)
            } else {
              reject()
            }
        });
      } catch (e) {
        reject(e)
      }
    });
  }

}
