import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IResource } from 'prunus-common/dist';
import { Subject } from 'rxjs/internal/Subject';
import { DetailViewComponent } from '../detail-view/detail-view.component';

@Component({
  selector: 'app-detail-modal',
  templateUrl: './detail-modal.component.html',
  styleUrls: ['./detail-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailModalComponent implements OnInit {
  title = "detail";

  @ViewChild(DetailViewComponent) 
  detailView: DetailViewComponent;
  onNext: Subject<any> = new Subject<any>();

  set resource(value: IResource) {
    this.detailView.resource = value;
    if (value) {
      this.title = `detail : ${value.name}`;
    }
  }

  constructor(public bsModalRef: BsModalRef,) { }

  ngOnInit(): void {
  }

  onClose() {
    this.bsModalRef.hide();
  }
}
