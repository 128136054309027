import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller'
import { QUERIES } from 'prunus-common/dist'
import { IUser, ROLES } from 'redwood-model/dist'
import { CleanupService } from '../../infrastructure/CleanupService'
import { Context } from '../../infrastructure/Context'
import { LoggingsService } from '../../infrastructure/LoggingsService'
import { UniversalQueryService } from '../../infrastructure/services/universal-query.service'
import { IResource } from '../../resource/interface/IResource'
import { User } from '../../user/model/User'
import { USER } from '../../user/USER'
import { TokenManagerService } from '../../infrastructure/services/token-manager.service'
import { TilesDataSourceService } from './tiles-data-source.service'
import { s3urlpresigner } from 'src/infrastructure/services/s3urlpresigner'
import { AWSCredentialService } from '../../infrastructure/services/AWSCredentialService'
import { DIALOG_OPEN_OPTIONS } from 'src/infrastructure/DialogOptions'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ErrorModalComponent } from '../error-modal/error-modal.component'
import { DetailViewComponent } from '../detail-view/detail-view.component'

@Component({
  selector: 'app-tiles-view-component',
  templateUrl: './tiles-view.component.html',
  styleUrls: ['./tiles-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TilesViewComponent implements OnInit, OnDestroy {
  canGoToDetailAndUpload: boolean;
  hasBaseDropZoneOver = false;
  user: User;
  @ViewChild('thumbs', {static: false})
  thumbs: ElementRef;
  private cleanupService = new CleanupService(TilesViewComponent.name);
  allowedResourcesExtensions: string[] = [];
  @ViewChild(VirtualScrollerComponent)
  private virtualScroller: VirtualScrollerComponent;

  constructor(
    private log: LoggingsService,
    private universalQueryService: UniversalQueryService,
    private changeDetectorRef: ChangeDetectorRef,
    private awsCredentialService: AWSCredentialService,
    public tokenManagerService: TokenManagerService,
    public context: Context,
    public tilesDataSourceService: TilesDataSourceService,
    private modalService: BsModalService,
    ) {

  }

  ngOnInit() {
    this.tilesDataSourceService.changeDetectorRef = this.changeDetectorRef
    this.tilesDataSourceService.init()

    if (this.context.filterType && this.context.filterType.indexOf('svg') !== -1) {
      this.allowedResourcesExtensions = ['svg']
    } else {
      this.cleanupService.addSubscription(
        this.universalQueryService.query({
          queryType: QUERIES.AllowedResourceExtensionsQuery
        }).subscribe(result => {
          this.allowedResourcesExtensions = [...result]
        })
      )
    }
    this.user = {...USER} as User
    this.canGoToDetailAndUpload = USER.hasRoles([ROLES.SCHOOL_TEACHER, ROLES.USER_TEACHER, ... ROLES.ALL_INTERNAL_USERS]);

    this.cleanupService.addSubscription(
      this.tilesDataSourceService.newTmpNotYetProcessedResource$.asObservable().subscribe(async (r: IResource) => {
        r.isNew = true
        await this.onNewImageUploaded({...r})
      }));
  }

  ngAfterViewInit() {
    this.cleanupService.addSubscription(
      this.tilesDataSourceService.dataCleared$.subscribe(() => {
        this.virtualScroller.refresh()
        this.changeDetectorRef.markForCheck()
       // this.tilesDataSourceService.fetchMore()
      })
    )
  }

  ngOnDestroy() {
    this.tilesDataSourceService.destroy();
    this.cleanupService.cleanupSubscriptions();
  }

  /***
   * fires each time the uploader complete a new upload
   * @param {Resource} newUpload
   */
  async onNewImageUploaded (newUpload: IResource) {
    newUpload.isNew = true
    
    switch(newUpload.extension) {
      case 'pdf':
        try {
          newUpload.thumbnail =  await s3urlpresigner(`${newUpload.uri}-0-coverThumb`, "prunus-test-cache", newUpload, this.awsCredentialService)
        } catch(e) {
          this.log.error(e)
          newUpload.thumbnail =  './assets/pdf.svg'
        }
        break;
      case 'docx':
      case 'doc': // INTNETIONAL FALLTHROUGH      
      case 'pptx':
      case 'ppt':
      case 'xls':
      case 'xlsx':
        try {
          newUpload.thumbnail =  await s3urlpresigner(`${newUpload.uri}-0-coverThumb`, "prunus-test-cache", newUpload, this.awsCredentialService)
        } catch(e) {
          this.log.error(e)
          newUpload.thumbnail = `./assets/${newUpload.extension}.svg`
        }
        break;
    }

    const existingIndex = this.tilesDataSourceService.resources.findIndex(r => r.name === newUpload.name && r.path === newUpload.path)
    if (existingIndex !== -1) {
      this.tilesDataSourceService.resources.splice(existingIndex, 1)
    }
    this.tilesDataSourceService.resources.splice(0, 0, newUpload) // insert as first item on screen
    this.tilesDataSourceService.resources = [ ...this.tilesDataSourceService.resources ]
    this.changeDetectorRef.markForCheck()
  }

  openDetail(resource: IResource) {
    if (resource.isNotYetProcessedByServer == true) { return }
    if (resource.errorInfo) { 
      const refErr: BsModalRef = this.modalService.show(ErrorModalComponent, {
        ... DIALOG_OPEN_OPTIONS
      });

      const errorModal: ErrorModalComponent = refErr.content;
      errorModal.message = resource.errorInfo.message;
      return; 
    }
    const detailViewModal = this.modalService.show(DetailViewComponent, {
      ... DIALOG_OPEN_OPTIONS, 
      ... { class: "xlModal" }
    });
    const detailViewModalComponent: DetailViewComponent = detailViewModal.content
    detailViewModalComponent.resource = resource
  }

}
