import { Injectable, Injector } from '@angular/core'
import { Subject } from 'rxjs/internal/Subject'
import { Observable } from 'rxjs/internal/Observable'
import { PARAMS } from '../params'
import { ErrorInfo } from './ErrorInfo'
import { LoggingsService } from './LoggingsService'
import { MESSAGES } from '../MESSAGES'
import { ERROR_CODES } from 'prunus-common/dist'
import { NotificationService } from 'src/infrastructure/services/notification-service'
import { ErrorModalComponent } from 'src/app/error-modal/error-modal.component'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { DIALOG_OPEN_OPTIONS } from './DialogOptions'
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component'

const handleLockingError = (e, injector) => {
  if (e?.code === ERROR_CODES.RESOURCE_LOCKED || e?.code === ERROR_CODES.CONCURRENCY_VIOLATION || e.msg?.toLowerCase()?.includes("lock")) {
    const modalService = injector.get(BsModalService)
    const confirmDialogRef: BsModalRef = modalService.show(ConfirmDialogComponent, {
      ... DIALOG_OPEN_OPTIONS
    });

    const confirmModal: ConfirmDialogComponent = confirmDialogRef.content
    confirmModal.okOnly = true 
    confirmModal.message = "Een andere gebruiker heeft terzelfdertijd item(s) in prunus gewijzigd, we moeten de pagina nu actualiseren met die wijzigingen."
    confirmModal.onYesAction = () => {
      window.location.reload()
    }

    return true  
  }

  return false
}

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  private _errors$ : Subject<ErrorInfo> = new Subject<ErrorInfo>()
  errors: ErrorInfo[] = []
  beforeHandleErrorHooks: Function[] = []

  get errors$(): Observable<ErrorInfo> {
    return this._errors$.asObservable()
  }

  constructor (
    private notificationService: NotificationService,
    private loggingsService: LoggingsService,
    private injector: Injector
  ) {
    this.beforeHandleErrorHooks.push(handleLockingError)
  }

  handleError (error: any): void {
    let isHandled = false
    for(const fn of this.beforeHandleErrorHooks) {
      if (fn(error, this.injector)) {
        isHandled = true;
      }
    }
    if (isHandled) {
      return
    }
    
    let errorInfo: ErrorInfo;
    if (error instanceof ErrorInfo) {
      errorInfo = error as ErrorInfo;
    } else {
      if (error.constructor.name === 'Error') {
        errorInfo = new ErrorInfo(error)
      } else if(typeof error === 'string') {
        errorInfo = new ErrorInfo(error)
      } else if(error.message) {
        errorInfo = new ErrorInfo(error.message)
        errorInfo.code = error.errorCode
        errorInfo.uri = error.uri
      }
    }

    
    this.errors.push(errorInfo)
    
    
    this._errors$.next(errorInfo)
    
    this.processError(errorInfo)
  }

  private processError (errorInfo: ErrorInfo) {
    this.notificationService.error(errorInfo ? errorInfo.message : MESSAGES.UNKNOWN_ERROR_TILE, MESSAGES.ERROR_TITLE)
    this.loggingsService.error(`${errorInfo?.date} ${errorInfo?.toString()}`)
  }

}
