import { ArrayDataSource } from '@angular/cdk/collections'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { COMMANDS, CONSTRAINTS, IBulkMoveResourceCommand, IMoveResourceCommand, RESOURCE_KIND } from 'prunus-common/dist'
import { Subject } from 'rxjs/internal/Subject'
import { v4 as uuid } from 'uuid'
import { MESSAGES, formatMsg } from '../../MESSAGES'
import { CleanupService } from '../../infrastructure/CleanupService'
import { DIALOG_OPEN_OPTIONS } from '../../infrastructure/DialogOptions'
import { ITreeNode } from '../../infrastructure/ITreeNode'
import { UniversalCommandService } from '../../infrastructure/cqrs/universal-command.service'
import { ellipsis } from "../../infrastructure/ellipsis"
import { IResource } from '../../resource/interface/IResource'
import { USER } from '../../user/USER'
import { email2MyFilesPath, MY_FILES, myFiles2EmailPath } from '../constants/MY_FILES'
import { ExplorerMultiSelectService } from '../explorer-view/explorer-multi-select.service'
import { ProgressModalComponent } from '../progress-modal/progress-modal.component'
import { NotificationService } from '../../infrastructure/services/notification-service'

@Component({
  selector: 'app-move-modal-component',
  templateUrl: './move-modal.component.html',
  styleUrls: ['./move-modal.component.scss']
})
export class MoveModalComponent implements OnInit, OnDestroy {
  name = ''
  title: string
  message: string
  path: string
  _sourceResources: IResource[] = []
  cleanupService = new CleanupService(MoveModalComponent.name)
  selectedTreeNode: ITreeNode
  treeData: ITreeNode[] = []
  dataSource: ArrayDataSource<any>
  moveStarted$ = new Subject<void>()
  hasChild =  (_: number, node: ITreeNode) => !!node.children && node.children.length > 0
  teacherRootPath: string // teacher can only move to its home folder
  resources: IResource[] = []

  constructor(public bsModalRef: BsModalRef,
              private universalCommandService: UniversalCommandService,
              private notificationService: NotificationService,
              private explorerMultiSelectService: ExplorerMultiSelectService,
              private modalService: BsModalService,
  ) {
  }

  ngOnInit() { 
  }

  ngOnDestroy(): void {
    this.cleanupService.cleanupSubscriptions()
  }

  set sourceResources(value: IResource[]) {
    this._sourceResources = [...value]
   
    this.title = `Verplaats ${this.sourceResources.length} item(s) : ${ellipsis(value)}`
  }

  get sourceResources(): IResource[] {
    return this._sourceResources
  }

  onMove(): void {
    this.moveStarted$.next()
    let iCommand: any
    let isBulkMove = this.sourceResources.length > 1
    if (!isBulkMove) {
      let targetPath = `${this.selectedTreeNode.path}/${this.selectedTreeNode.name}/${this.sourceResources[0].name}`
      targetPath = myFiles2EmailPath(targetPath)
      if (targetPath.length > CONSTRAINTS.MAX_PATH_LENGTH) {
        this.notificationService.error(
          formatMsg(MESSAGES.MAX_FOLDER_DEPTH_EXCEEDED, {MAX_PATH_LENGTH: CONSTRAINTS.MAX_PATH_LENGTH, length: targetPath.length})
        )

        return
      }
      iCommand = {
        uid: uuid(),
        sourcePath: this.sourceResources[0].path,
        commandType: COMMANDS.MoveResourceCommand,
        sourceUri: this.sourceResources[0].uri,
        targetUri: this.selectedTreeNode.uri,
        fullPath: this.sourceResources[0].path + "/" + this.sourceResources[0].name,
      } as IMoveResourceCommand
    } else {
      iCommand = {
        uid: uuid(),
        commandType: COMMANDS.BulkMoveResourceCommand,
        sourcePaths: [],
        sourceUris: [],
        targetUris: [],
        fullPaths: []
      } as IBulkMoveResourceCommand
      this.sourceResources.forEach(r => {
        iCommand.sourceUris.push(r.uri)
        iCommand.targetUris.push(this.selectedTreeNode.uri)
        iCommand.sourcePaths.push(r.path)
        iCommand.fullPaths.push(r.path + "/" + r.name)
      })
    }

    if (isBulkMove || this.sourceResources[0].kind === RESOURCE_KIND.FOLDER) {
      const ref: BsModalRef = this.modalService.show(ProgressModalComponent, DIALOG_OPEN_OPTIONS)
      const moveModalComponent: ProgressModalComponent = ref.content
      moveModalComponent.title = MESSAGES.PROGRESS_MOVE_BULK_TILE
      moveModalComponent.cmd = iCommand
    }
    

    this.cleanupService.addSubscription(
      this.universalCommandService.handle(iCommand).subscribe(() => {
        this.explorerMultiSelectService.deselectAll()
        this.onClose()
      }, e => { this.notificationService.error(e)})
    )
  }

  onSelect(iTreeNode: ITreeNode): void {
    const nodePath = email2MyFilesPath(`${iTreeNode.path}${iTreeNode.path.endsWith('/') ? '' : '/'}${iTreeNode.name}`)
    if (this.path === nodePath || nodePath === email2MyFilesPath(this.sourceResources[0].path)) {
      this.notificationService.warning(MESSAGES.ITEM_UNMOVEABLE, MESSAGES.WARNING_TOAST_TITLE)
      this.selectedTreeNode = undefined
      return
    }
    if (this.sourceResources.map(r => `${r.path}/${r.name}`).indexOf(nodePath) !== -1) {
      this.notificationService.warning(MESSAGES.WARNING_INVALID_MOVE_TARGET, MESSAGES.WARNING_TOAST_TITLE)
      this.selectedTreeNode = undefined

      return
    }
    this.selectedTreeNode = iTreeNode
  }

  onClose() {
    this.bsModalRef.hide()
  }

}


